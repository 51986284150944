//#region IMPORT

import { Component } from "@angular/core";
import { Router } from "@angular/router";
import { BaseAuthourizeComponent } from "src/app/components/bases/baseauthourize.component";
import { NumberConstant } from "src/app/constants/number.constant";
import { StringConstant } from "src/app/constants/string.constant";
import { SessionService } from "src/app/services/session.service";

//#endregion


//#region COMPONENT

@Component
(
	{
		selector: "app-productlist",
		templateUrl: "./productlist.component.html",
		styleUrls: ["./productlist.component.sass"],
	}
)

//#endregion


//#region  CLASS

export class ProductListComponent extends BaseAuthourizeComponent
{
	//#region DECLARATION

	public _stringConstant = StringConstant;
	public _stringImageMainHeader: string;
	public _stringImageMainHeaderMobile: string;

	//#endregion


	//#region CONSTRUCTUR

	constructor(serviceSession: SessionService, router: Router)
	{
		super(serviceSession, router);
		this._stringImageMainHeader = "../../../assets/photos/sakina/photo_header_product.jpg";
		this._stringImageMainHeaderMobile = "../../../assets/photos/sakina/mobileheader/photo_produk.jpg";
	}

	//#endregion


	//#region ONINIT

	ngOnInit(): void
	{
	}

	//#endregion


	//#region AFTERVIEWINIT

	ngAfterViewInit(): void
	{
		this.setViewFromTop();
	}

	//#endregion


	//#region FUNCTION

	getImageMain(): any
	{
		if(this._numberGeneralPixelWidth <= NumberConstant.NUMBER_VALUE_MINIMIZE_NAVIGATION_PIXEL)
		{
			if(this._stringImageMainHeaderMobile === null && this._stringImageMainHeaderMobile === undefined && this._stringImageMainHeaderMobile === "")
			{
				return {};
			}
			else
			{
				return {"background-image": "url(" + this._stringImageMainHeaderMobile + ")"};
			}
		}
		else
		{
			if(this._stringImageMainHeader === null && this._stringImageMainHeader === undefined && this._stringImageMainHeader === "")
			{
				return {};
			}
			else
			{
				return {"background-image": "url(" + this._stringImageMainHeader + ")"};
			}
		}
	}


	//#endregion


	//#region WEB SERVICES


	//#endregion


	//#region NAVIGATION

	goToProductOtoYearly()
	{
		this._router.navigate(["home", "product", "oto", "yearly"]);
	}

	goToProductAsriYearly()
	{
		this._router.navigate(["home", "product", "asri", "yearly"]);
	}

	goToProductPersonalAccidentYearly()
	{
		this._router.navigate(["home", "product", "personalaccident", "yearly"]);
	}

	goToProductBicycleYearly()
	{
		this._router.navigate(["home", "product", "bicycle", "yearly"]);
	}



	goToProductOtoMonthly()
	{
		this._router.navigate(["home", "product", "oto", "monthly"]);
	}

	goToProductAsriMonthly()
	{
		this._router.navigate(["home", "product", "asri", "monthly"]);
	}

	goToProductPersonalAccidentMonthly()
	{
		this._router.navigate(["home", "product", "personalaccident", "monthly"]);
	}


	goToProductOtoDaily()
	{
		this._router.navigate(["home", "product", "oto", "daily"]);
	}

	goToProductPersonalAccidentDaily()
	{
		this._router.navigate(["home", "product", "personalaccident", "daily"]);
	}


	goToProductASMIKMyHouse()
	{
		this._router.navigate(["home", "productasmik", "myhouse", "yearly"]);
	}

	goToProductASMIKDamageOfBusinessPlace()
	{
		this._router.navigate(["home", "productasmik", "damageofbusinessplace", "yearly"]);
	}

	goToProductASMIKMyMotorcycle()
	{
		this._router.navigate(["home", "productasmik", "mymotorcycle", "yearly"]);
	}

	//#endregion

}
