//#region IMPORT

import { ENUM_VIRTUALACCOUNT_TYPE } from "../constants/enum.constant";
import { GeneralModel } from "./bases/general.model";

//#endregion


//#region CLASS

export class VirtualAccountModel extends GeneralModel
{
	/*
		Attribute - START
		Description : Attribute for virtual account model, used for link to product general for keep track the payment attempt.
		Author: Ibrahim Aziz.
		Created on : Thursday, 12 November 2020.		Updated on : Wednesday, 6 January 2021.
		Created by : Ibrahim Aziz.						Updated by : Ibrahim Aziz.
		Version : 1.0:3.
	*/

	ID?: number;
	Token?: string;
	ProductGeneralID?: number;

	Type?: ENUM_VIRTUALACCOUNT_TYPE;
	ExpiredDate?: Date;
	AccountNumber?: string;
	BRIVANumber?: string;

	PaidStatus?: boolean;

	/* Attribute - END */
}

//#endregion