//#region IMPORT

import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { WebAddressConstant } from "../constants/webaddress.constant";
import { GeneralServiceInterface } from "../interfaces/general.service.interface";
import { ProductAsriModel } from "../models/productasri.model";
import { ProductGeneralModel } from "../models/productgeneral.model";
import { BaseService } from "./bases/base.service";
import { SessionService } from "./session.service";

//#endregion


//#region INJECTABLE

@Injectable
(
	{
		providedIn: "root"
	}
)

//#endregion


//#region CLASS

export class ProductasriService extends BaseService
{
	//#region CONSTRUCTOR

	constructor(clientHTTP: HttpClient, serviceSession: SessionService)
	{
		super(clientHTTP, serviceSession);
	}

	//#endregion


	//#region CALCULATE

	calculatePremiumForCustomer(interfaceGeneralService: GeneralServiceInterface, modelProductGeneral: ProductGeneralModel): void
	{
		return this.callServicePrivate(interfaceGeneralService, JSON.stringify(modelProductGeneral), WebAddressConstant.STRING_URL_PRODUCT_CALCULATEPREMIUMASRIFORCUSTOMER);
	}

	//#endregion


	//#region INSERT

	insertProductForCustomer(interfaceGeneralService: GeneralServiceInterface, modelProductGeneral: ProductGeneralModel): void
	{
		return this.callServicePrivate(interfaceGeneralService, JSON.stringify(modelProductGeneral), WebAddressConstant.STRING_URL_PRODUCT_INSERTPRODUCTFORCUSTOMER);

	}

	//#endregion


	//#region UPDATE

	updateInsuredForCustomer(interfaceGeneralService: GeneralServiceInterface, modelProductGeneral: ProductGeneralModel): void
	{
		return this.callServicePrivate(interfaceGeneralService, JSON.stringify(modelProductGeneral), WebAddressConstant.STRING_URL_PRODUCT_UPDATEINSUREDFORCUSTOMER);

	}

	//#endregion


	//#region UPLOAD

	uploadProductForCustomer(interfaceGeneralService: GeneralServiceInterface, modelProductAsri: ProductAsriModel): void
	{
		return this.callServicePrivate(interfaceGeneralService, JSON.stringify(modelProductAsri), WebAddressConstant.STRING_URL_PRODUCT_UPLOADPRODUCTFORCUSTOMER);

	}

	//#endregion


	//#region SELECT

	selectProductByTokenForCustomer(interfaceGeneralService: GeneralServiceInterface, modelProductAsri: ProductAsriModel): void
	{
		return this.callServicePrivate(interfaceGeneralService, JSON.stringify(modelProductAsri), WebAddressConstant.STRING_URL_PRODUCT_SELECRPRODUCTBYTOKENFORCUSTOMER);

	}

	//#endregion


	//#region SAVE

	saveProductForCustomer(interfaceGeneralService: GeneralServiceInterface, modelProductGeneral: ProductGeneralModel): void
	{
		return this.callServicePrivate(interfaceGeneralService, JSON.stringify(modelProductGeneral), WebAddressConstant.STRING_URL_PRODUCT_SAVEPRODUCTFORCUSTOMER);

	}

	//#endregion


	//#region SEND

	sendSPPAByEmailForCustomer(interfaceGeneralService: GeneralServiceInterface, modelProductGeneral: ProductGeneralModel): void
	{
		return this.callServicePrivate(interfaceGeneralService, JSON.stringify(modelProductGeneral), WebAddressConstant.STRING_URL_PRODUCT_SENDSPPABYEMAILFORCUSTOMER);

	}

	//#endregion


	//#region PAYMENT

	confirmPayment(interfaceGeneralService: GeneralServiceInterface, modelProductAsri: ProductAsriModel): void
	{
		return this.callServicePrivate(interfaceGeneralService, JSON.stringify(modelProductAsri), WebAddressConstant.STRING_URL_PRODUCT_ASRICONFIRMPAYMENT);

	}

	sharePaymentForCustomer(interfaceGeneralService: GeneralServiceInterface, modelProductGeneral: ProductGeneralModel): void
	{
		return this.callServicePrivate(interfaceGeneralService, JSON.stringify(modelProductGeneral), WebAddressConstant.STRING_URL_PRODUCT_ASRISHAREPAYMENTFORCUSTOMER);
	}

	generatePaymentBRIVA(interfaceGeneralService: GeneralServiceInterface, modelProductGeneral: ProductGeneralModel): void
	{
		return this.callServicePrivate(interfaceGeneralService, JSON.stringify(modelProductGeneral), WebAddressConstant.STRING_URL_PRODUCT_ASRIGENERATEPAYMENTBRIVA);
	}

	generatePaymentBNI(interfaceGeneralService: GeneralServiceInterface, modelProductGeneral: ProductGeneralModel): void
	{
		return this.callServicePrivate(interfaceGeneralService, JSON.stringify(modelProductGeneral), WebAddressConstant.STRING_URL_PRODUCT_ASRIGENERATEPAYMENTBNI);
	}

	generatePaymentBCA(interfaceGeneralService: GeneralServiceInterface, modelProductGeneral: ProductGeneralModel): void
	{
		return this.callServicePrivate(interfaceGeneralService, JSON.stringify(modelProductGeneral), WebAddressConstant.STRING_URL_PRODUCT_ASRIGENERATEPAYMENTBCA);
	}

	generatePaymentMandiri(interfaceGeneralService: GeneralServiceInterface, modelProductGeneral: ProductGeneralModel): void
	{
		return this.callServicePrivate(interfaceGeneralService, JSON.stringify(modelProductGeneral), WebAddressConstant.STRING_URL_PRODUCT_ASRIGENERATEPAYMENTMANDIRI);
	}

	generatePaymentPermata(interfaceGeneralService: GeneralServiceInterface, modelProductGeneral: ProductGeneralModel): void
	{
		return this.callServicePrivate(interfaceGeneralService, JSON.stringify(modelProductGeneral), WebAddressConstant.STRING_URL_PRODUCT_ASRIGENERATEPAYMENTPERMATA);
	}

	generatePaymentQRIS(interfaceGeneralService: GeneralServiceInterface, modelProductGeneral: ProductGeneralModel): void
	{
		return this.callServicePrivate(interfaceGeneralService, JSON.stringify(modelProductGeneral), WebAddressConstant.STRING_URL_PRODUCT_ASRIGENERATEPAYMENTQRIS);
	}

	generatePaymentIndomart(interfaceGeneralService: GeneralServiceInterface, modelProductGeneral: ProductGeneralModel): void
	{
		return this.callServicePrivate(interfaceGeneralService, JSON.stringify(modelProductGeneral), WebAddressConstant.STRING_URL_PRODUCTASRI_GENERATEPAYMENTINDOMARET);
	}

	generatePaymentAlfamart(interfaceGeneralService: GeneralServiceInterface, modelProductGeneral: ProductGeneralModel): void
	{
		return this.callServicePrivate(interfaceGeneralService, JSON.stringify(modelProductGeneral), WebAddressConstant.STRING_URL_PRODUCTASRI_GENERATEPAYMENTALFAMART);
	}

	generatePaymentShopeePay(interfaceGeneralService: GeneralServiceInterface, modelProductGeneral: ProductGeneralModel): void
	{
		return this.callServicePrivate(interfaceGeneralService, JSON.stringify(modelProductGeneral), WebAddressConstant.STRING_URL_PRODUCTASRI_GENERATEPAYMENTSHOPEEPAY);
	}

	generatePaymentCreditCard(interfaceGeneralService: GeneralServiceInterface, modelProductGeneral: ProductGeneralModel): void
	{
		return this.callServicePrivate(interfaceGeneralService, JSON.stringify(modelProductGeneral), WebAddressConstant.STRING_URL_PRODUCTASRI_GENERATEPAYMENTCREDITCARD);
	}

	//#endregion

}

//#endregion
