<div id="divMainSectionContainer">
	<section class="SectionSecondary" id="sectionHeaderProduct" [ngStyle]="getImageMain()">
		<img class="ImgShapeMain1" src="../../../assets/gifs/transparent.gif">
		<img class="ImgShapeMain2" src="../../../assets/gifs/transparent.gif">
		<div class="DivFormVerticalContainer DivSiteContainer">
			<div class="DivProduct">
				<h2 class="FontSoftColor"> {{ this._stringConstant.STRING_INFORMATION_TITLE_PARTNER }} </h2>
			</div>
		</div>
	</section>

	<section class="SectionSecondary" id="sectionPartner">
		<div class="DivSiteContainer">
            <h1>{{ this._stringConstant.STRING_INFORMATION_TITLE_PARTNER }}</h1>

			<div class="DivContentImageGallery">
                <div class="DivButtonNavigate">
                    <a class="ButtonPreviousGallery" (click)="showContentPrevious()"><img src="../../assets/icons/sakina/icon_prev.svg"></a>
                </div>
                <section id="sectionImageGallery">
					<label for="inputImageGallery0" id="labelImageGallery0" class="LabelImageNonActive" [ngClass]="{'LabelImageActive': _numberIndexPartner === 0}">
						<img [src]="_arrayModelPartnerCustomer[0].Path" alt="">
						<p>{{ _arrayModelPartnerCustomer[0].Title }}</p>
					</label>

					<label for="inputImageGallery1" id="labelImageGallery1" class="LabelImageNonActive" [ngClass]="{'LabelImageActive': _numberIndexPartner === 1}">
						<img [src]="_arrayModelPartnerCustomer[1].Path" alt="">
						<p>{{ _arrayModelPartnerCustomer[1].Title }}</p>
					</label>

					<label for="inputImageGallery2" id="labelImageGallery2" class="LabelImageNonActive" [ngClass]="{'LabelImageActive': _numberIndexPartner === 2}">
						<img [src]="_arrayModelPartnerCustomer[2].Path" alt="">
						<p>{{ _arrayModelPartnerCustomer[2].Title }}</p>
					</label>

					<label for="inputImageGallery3" id="labelImageGallery3" class="LabelImageNonActive" [ngClass]="{'LabelImageActive': _numberIndexPartner === 3}">
						<img [src]="_arrayModelPartnerCustomer[3].Path" alt="">
						<p>{{ _arrayModelPartnerCustomer[3].Title }}</p>
					</label>

					<label for="inputImageGallery4" id="labelImageGallery4" class="LabelImageNonActive" [ngClass]="{'LabelImageActive': _numberIndexPartner === 4}">
						<img [src]="_arrayModelPartnerCustomer[4].Path" alt="">
						<p>{{ _arrayModelPartnerCustomer[4].Title }}</p>
					</label>
                </section>
                <div class="DivButtonNavigate">
                    <a class="ButtonNextGallery" (click)="showContentNext()"><img src="../../assets/icons/sakina/icon_next.svg"></a>
                </div>
            </div>
		</div>
	</section>
</div>
