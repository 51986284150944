<div id="divMainSectionContainer">
	<section class="SectionSecondary" id="sectionHeaderProduct" [ngStyle]="getImageMain()">
		<img class="ImgShapeMain1" src="../../../assets/gifs/transparent.gif">
		<img class="ImgShapeMain2" src="../../../assets/gifs/transparent.gif">
		<div class="DivFormVerticalContainer DivSiteContainer">
			<div class="DivProduct">
				<h2 class="FontSoftColor"> {{ this._stringConstant.STRING_INFORMATION_TITTLE_MOBILEAPPLICATION }} </h2>
			</div>
		</div>
	</section>

	<section class="SectionSecondary" id="sectionMobileApplication">
        <div class="DivSiteContainer">
			<form>
				<div class="DivForm">
					<fieldset>

						<div class=DivFormHorizontalContainer id="divProductWithDetail">
							<div class="DivForm2Column">
                                <div class="DivFormVerticalContainer">
                                    <img class="ImageFormDetail" src="../../../assets/logos/sakina/mobileapplications/logo_brinsmob.svg">
                                </div>
                            </div>
							<div class="DivForm2Column">
                                <div class="DivTitle2Colour">
                                    <h2>{{ _stringConstant.STRING_APPLICATION_BRINS }}</h2>
                                    <h2>{{ _stringConstant.STRING_APPLICATION_BRINSMOBILE }}</h2>
                                </div>
                                <p>{{ _stringConstant.STRING_INFORMATION_DESCRIPTION_BRINSMOBILE}}</p>
                                <div class="DivFormHorizontalContainer">
                                    <img class="ImageDownload" (click)="goToDownloadBRINSMobileFromGooglePlay()" src="../../../assets/logos/sakina/logo_googleplay.svg">
                                    <img class="ImageDownload" (click)="goToDownloadBRINSMobileFromAppStrore()" src="../../../assets/logos/sakina/logo_appstore.svg">
                                </div>
                            </div>
                        </div>

						<div class=DivFormHorizontalContainer id="divProductWithDetailReverse">
							<div class="DivForm2Column">
                                <div class="DivFormVerticalContainer">
                                    <img class="ImageFormDetail" src="../../../assets/logos/sakina/mobileapplications/logo_brinsagent.png">
                                </div>
                            </div>
							<div class="DivForm2Column">
                                <div class="DivTitle2Colour">
                                    <h2>{{ _stringConstant.STRING_APPLICATION_BRINS }}</h2>
                                    <h2>{{ _stringConstant.STRING_APPLICATION_BRINSAGENT }}</h2>
                                </div>
                                <p>{{ _stringConstant.STRING_INFORMATION_DESCRIPTION_BRINSAGENT}}</p>
                                <div class="DivFormHorizontalContainer">
                                    <img (click)="goToDownloadBRINSAgentFromGooglePlay()" class="ImageDownload" src="../../../assets/logos/sakina/logo_googleplay.svg">
                                    <!-- <img class="ImageDownload" src="../../../assets/logos/sakina/logo_appstore.svg"> -->
                                </div>
                            </div>
                        </div>

						<div class=DivFormHorizontalContainer id="divProductWithDetail">
							<div class="DivForm2Column">
                                <div class="DivFormVerticalContainer">
                                    <img class="ImageFormDetail" src="../../../assets/logos/sakina/mobileapplications/logo_mpm.svg">
                                </div>
                            </div>
							<div class="DivForm2Column">
                                <div class="DivTitle2Colour">
                                    <h2>{{ _stringConstant.STRING_APPLICATION_MPM }}</h2>
                                    <h2>&nbsp;{{ _stringConstant.STRING_APPLICATION_MPMMOBILE }}</h2>
                                </div>
                                <p>{{ _stringConstant.STRING_INFORMATION_DESCRIPTION_MPMMOBILE}}</p>
                                <div class="DivFormHorizontalContainer">
                                    <img class="ImageDownload" (click)="goToDownloadMPMMobileFromGooglePlay()" src="../../../assets/logos/sakina/logo_googleplay.svg">
                                    <img class="ImageDownload" (click)="goToDownloadMPMMobileFromAppStrore()" src="../../../assets/logos/sakina/logo_appstore.svg">
                                </div>
                            </div>
                        </div>
                    </fieldset>
                </div>
            </form>
        </div>
    </section>
</div>
