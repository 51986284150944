<div id="divMainSectionContainer">
	<section class="SectionSecondary" id="sectionHeaderProduct" [ngStyle]="getImageMain()">
		<img class="ImgShapeMain1" src="../../../assets/gifs/transparent.gif">
        <img class="ImgShapeMain2" src="../../../assets/gifs/transparent.gif">
        <div class="DivFormVerticalContainer DivSiteContainer">
            <div class="DivProduct DivTitleBottom">
                <h2 class="FontSoftColor"> {{ _stringConstant.STRING_INFORMATION_CART_TITTLE }} </h2>
            </div>
        </div>
	</section>

	<section class="SectionSecondary" id="sectionMobileApplication">

        <div id="divBodyTabAbout" class="DivBodyContainer">
            <h1> Daftar Keranjang</h1>
            <ul class="UlTabContainer">
                <li class="ListTab" [ngClass]="{ 'ListActive' : _booleanProductDraft } " (click)="callSelectProductDraft()"> {{ _stringConstant.STRING_CONTENT_CART_DRAFT }} </li>
                <li class="ListTab" [ngClass]="{ 'ListActive' : _booleanProductPending }" (click)="callSelectProductPending()"> {{ _stringConstant.STRING_CONTENT_CART_PENDING }} </li>
            </ul>

            <div class="DivTableContainer">
                <app-tablecontrolinclude [_modelTableForm]="_modelTable" (_eventEmitterSelect)="setEventEmitterSelect($event);" #_componentTableControlInclude></app-tablecontrolinclude>
            </div>

            <div id="divContentCart" *ngIf="_booleanProductDraft">

                <div class="DivContentBox DivCartList" *ngFor="let modelProductGeneral of _arrayModelProductGeneral" (click)="goToProduct(modelProductGeneral)">
                    <div class="DivPaymentMethod">
                        <!-- <img class="ImageLogoPayment" [src]=getImagePayment(modelProductGeneral)> -->
                        <!-- <h2>{{ modelProductGeneral.Progress }}</h2> -->
                        <div class="DivProductProgressContainer">
                            <mat-progress-spinner
                                [diameter]="_numberDiameter"
                                [mode]="_modeProgressSpinner"
                                [color]="_colorPrimaryDraft"
                                [value]="_numberPrimaryMaxPercentage">
                            </mat-progress-spinner>
                            <h2>{{ modelProductGeneral.Progress }}%</h2>
                            <mat-progress-spinner
                                [diameter]="_numberDiameter"
                                [mode]="_modeProgressSpinner"
                                [color]="_colorProgressDraft"
                                [value]="modelProductGeneral.Progress">
                            </mat-progress-spinner>
                        </div>
                    </div>
                    <div class="DivCartOrderDetail">
                        <label>{{ modelProductGeneral.CreatedOn | convertToDateShort | convertEmptyToDash }}</label>
                        <h3>{{ modelProductGeneral.getName() | uppercase }}</h3>
                        <p>Premi</p>
                        <h5>{{ modelProductGeneral.PremiumAmount | currencyValue | convertEmptyToDash }}</h5>
                    </div>
                    <div class="DivCartOrderProductList">
                        <img [src]=getImageProduct(modelProductGeneral)>
                        <h3>{{ modelProductGeneral.getProductName() | uppercase| convertEmptyToDash }}</h3>
                        <p>{{ modelProductGeneral.getProductPeriode() | convertEmptyToDash }} </p>
                    </div>
                </div>
            </div>

            <div id="divContentCart" *ngIf="_booleanProductPending">
                <div class="DivContentBox DivCartList" *ngFor="let modelProductGeneral of _arrayModelProductGeneral" (click)="goToDetail(modelProductGeneral)">
                    <div class="DivPaymentMethod">
                        <img *ngIf="checkPaymentExpired(modelProductGeneral)" class="ImageLogoPayment" [src]=getImagePayment(modelProductGeneral)>
                    </div>
                    <div class="DivCartOrderDetail" *ngIf="checkPaymentExpired(modelProductGeneral)">
                        <label *ngIf="modelProductGeneral.modelVirtualAccount">{{ modelProductGeneral.modelVirtualAccount.BRIVANumber }}{{ modelProductGeneral.modelVirtualAccount.AccountNumber }}</label>
                        <label *ngIf="modelProductGeneral.modelThirdPartyPayment">{{ modelProductGeneral.modelThirdPartyPayment.AccountCode }}{{ modelProductGeneral.modelThirdPartyPayment.AccountNumber }}</label>
                        <h3>{{ modelProductGeneral.getName() | uppercase }}</h3>
                        <p>Menunggu Pembayaran</p>
                        <h5>{{ modelProductGeneral.PremiumAmount | currencyValue | convertEmptyToDash }}</h5>
                    </div>
                    <div class="DivCartOrderDetail" *ngIf="!checkPaymentExpired(modelProductGeneral)">
                        <label>{{ this._stringConstant.STRING_CHARACTER_EMPTY }}</label>
                        <h3>{{ modelProductGeneral.getName() | uppercase }}</h3>
                        <p>Expired</p>
                        <h5> {{ this._stringConstant.STRING_CHARACTER_EMPTY }}</h5>
                    </div>
                    <div class="DivCartOrderProductList">
                        <img [src]=getImageProduct(modelProductGeneral)>
                        <h3>{{ modelProductGeneral.getProductName() | uppercase | convertEmptyToDash }}</h3>
                        <p>{{ modelProductGeneral.getProductPeriode() | convertEmptyToDash }} </p>
                    </div>
                </div>
            </div>

            <div class="DivTableContainer">
                <app-tablecontrolincludemini [_modelTableForm]="_modelTable" (_eventEmitterSelect)="setEventEmitterSelect($event);"></app-tablecontrolincludemini>
            </div>
        </div>
    </section>
</div>
