//#region IMPORT

import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { WebAddressConstant } from "../constants/webaddress.constant";
import { GeneralServiceInterface } from "../interfaces/general.service.interface";
import { ProductGeneralModel } from "../models/productgeneral.model";
import { ProductOtoModel } from "../models/productoto.model";
import { BaseService } from "./bases/base.service";
import { SessionService } from "./session.service";

//#endregion


//#region INJECTABLE

@Injectable
(
	{
		providedIn: "root"
	}
)

//#endregion


//#region CLASS

export class ProductotoService extends BaseService
{
	//#region CONSTRUCTOR

	constructor(clientHTTP: HttpClient, serviceSession: SessionService)
	{
		super(clientHTTP, serviceSession);
	}

	//#endregion


	//#region SELECT

	selectCalculatePremiumOto(interfaceGeneralService: GeneralServiceInterface, modelProductGeneral: ProductGeneralModel): void
	{
		return this.callServicePrivate(interfaceGeneralService, JSON.stringify(modelProductGeneral), WebAddressConstant.STRING_URL_PRODUCT_SELECTCALCULATEPREMIUMOTO);
	}

	calculatePremiumForCustomer(interfaceGeneralService: GeneralServiceInterface, modelProductGeneral: ProductGeneralModel): void
	{
		return this.callServicePrivate(interfaceGeneralService, JSON.stringify(modelProductGeneral), WebAddressConstant.STRING_URL_PRODUCTOTO_CALCULATEPREMIUMFORCUSTOMER);
	}

	selectProductByTokenForCustomer(interfaceGeneralService: GeneralServiceInterface, modelProductOto: ProductOtoModel): void
	{
		return this.callServicePrivate(interfaceGeneralService, JSON.stringify(modelProductOto), WebAddressConstant.STRING_URL_PRODUCTOTO_SELECTPRODUCTBYTOKENFORCUSTOMER);
	}

	//#endregion


	//#region SEND

	sendCalculatePremiumOtoForOpertaionAndSupport(interfaceGeneralService: GeneralServiceInterface, modelProductGeneral: ProductGeneralModel): void
	{
		return this.callServicePrivate(interfaceGeneralService, JSON.stringify(modelProductGeneral), WebAddressConstant.STRING_URL_PRODUCT_SENDCALCULATEPREMIUMOTOBYEMAILFOROPERATIONANDSUPPORT);
	}

	sendSPPAByEmailForCustomer(interfaceGeneralService: GeneralServiceInterface, modelProductGeneral: ProductGeneralModel): void
	{
		return this.callServicePrivate(interfaceGeneralService, JSON.stringify(modelProductGeneral), WebAddressConstant.STRING_URL_PRODUCTOTO_SENDSPPABYEMAILFORCUSTOMER);
	}

	//#endregion


	//#region INSERT

	insertProductForCustomer(interfaceGeneralService: GeneralServiceInterface, modelProductGeneral: ProductGeneralModel): void
	{
		return this.callServicePrivate(interfaceGeneralService, JSON.stringify(modelProductGeneral), WebAddressConstant.STRING_URL_PRODUCTOTO_INSERTPRODUCTFORCUSTOMER);
	}

	//#endregion


	//#region UPLOAD

	uploadProductForCustomer(interfaceGeneralService: GeneralServiceInterface, modelProductOto: ProductOtoModel): void
	{
		return this.callServicePrivate(interfaceGeneralService, JSON.stringify(modelProductOto), WebAddressConstant.STRING_URL_PRODUCTOTO_UPLOADPRODUCTFORCUSTOMER);
	}

	uploadProductForWebsiteCustomer(interfaceGeneralService: GeneralServiceInterface, modelProductOto: ProductOtoModel): void
	{
		return this.callServicePrivate(interfaceGeneralService, JSON.stringify(modelProductOto), WebAddressConstant.STRING_URL_PRODUCTOTO_UPLOADPRODUCTFORWEBSITECUSTOMER);
	}

	//#endregion


	//#region UPDATE

	updateInsuredForCustomer(interfaceGeneralService: GeneralServiceInterface, modelProductGeneral: ProductGeneralModel): void
	{
		return this.callServicePrivate(interfaceGeneralService, JSON.stringify(modelProductGeneral), WebAddressConstant.STRING_URL_PRODUCTOTO_UPDATEINSUREDFORCUSTOMER);
	}

	//#endregion


	//#region SAVE

	saveProductForWebsiteCustomer(interfaceGeneralService: GeneralServiceInterface, modelProductGeneral: ProductGeneralModel): void
	{
		return this.callServicePrivate(interfaceGeneralService, JSON.stringify(modelProductGeneral), WebAddressConstant.STRING_URL_PRODUCTOTO_SAVEPRODUCTFORWEBSITECUSTOMER);
	}

	//#endregion


	//#region PAYMENT

	confirmPayment(interfaceGeneralService: GeneralServiceInterface, modelProductOto: ProductOtoModel): void
	{
		return this.callServicePrivate(interfaceGeneralService, JSON.stringify(modelProductOto), WebAddressConstant.STRING_URL_PRODUCT_OTOCONFIRMPAYMENT);

	}

	sharePaymentForCustomer(interfaceGeneralService: GeneralServiceInterface, modelProductGeneral: ProductGeneralModel): void
	{
		return this.callServicePrivate(interfaceGeneralService, JSON.stringify(modelProductGeneral), WebAddressConstant.STRING_URL_PRODUCT_OTOSHAREPAYMENTFORCUSTOMER);

	}

	generatePaymentBRIVA(interfaceGeneralService: GeneralServiceInterface, modelProductGeneral: ProductGeneralModel): void
	{
		return this.callServicePrivate(interfaceGeneralService, JSON.stringify(modelProductGeneral), WebAddressConstant.STRING_URL_PRODUCT_OTOGENERATEPAYMENTBRIVA);
	}

	generatePaymentBNI(interfaceGeneralService: GeneralServiceInterface, modelProductGeneral: ProductGeneralModel): void
	{
		return this.callServicePrivate(interfaceGeneralService, JSON.stringify(modelProductGeneral), WebAddressConstant.STRING_URL_PRODUCT_OTOGENERATEPAYMENTBNI);
	}

	generatePaymentBCA(interfaceGeneralService: GeneralServiceInterface, modelProductGeneral: ProductGeneralModel): void
	{
		return this.callServicePrivate(interfaceGeneralService, JSON.stringify(modelProductGeneral), WebAddressConstant.STRING_URL_PRODUCT_OTOGENERATEPAYMENTBCA);
	}

	generatePaymentMandiri(interfaceGeneralService: GeneralServiceInterface, modelProductGeneral: ProductGeneralModel): void
	{
		return this.callServicePrivate(interfaceGeneralService, JSON.stringify(modelProductGeneral), WebAddressConstant.STRING_URL_PRODUCT_OTOGENERATEPAYMENTMANDIRI);
	}

	generatePaymentPermata(interfaceGeneralService: GeneralServiceInterface, modelProductGeneral: ProductGeneralModel): void
	{
		return this.callServicePrivate(interfaceGeneralService, JSON.stringify(modelProductGeneral), WebAddressConstant.STRING_URL_PRODUCT_OTOGENERATEPAYMENTPERMATA);
	}

	generatePaymentQRIS(interfaceGeneralService: GeneralServiceInterface, modelProductGeneral: ProductGeneralModel): void
	{
		return this.callServicePrivate(interfaceGeneralService, JSON.stringify(modelProductGeneral), WebAddressConstant.STRING_URL_PRODUCT_OTOGENERATEPAYMENTQRIS);
	}

	generatePaymentIndomart(interfaceGeneralService: GeneralServiceInterface, modelProductGeneral: ProductGeneralModel): void
	{
		return this.callServicePrivate(interfaceGeneralService, JSON.stringify(modelProductGeneral), WebAddressConstant.STRING_URL_PRODUCTOTO_GENERATEPAYMENTINDOMARET);
	}

	generatePaymentAlfamart(interfaceGeneralService: GeneralServiceInterface, modelProductGeneral: ProductGeneralModel): void
	{
		return this.callServicePrivate(interfaceGeneralService, JSON.stringify(modelProductGeneral), WebAddressConstant.STRING_URL_PRODUCTOTO_GENERATEPAYMENTALFAMART);
	}

	generatePaymentShopeePay(interfaceGeneralService: GeneralServiceInterface, modelProductGeneral: ProductGeneralModel): void
	{
		return this.callServicePrivate(interfaceGeneralService, JSON.stringify(modelProductGeneral), WebAddressConstant.STRING_URL_PRODUCTOTO_GENERATEPAYMENTSHOPEEPAY);
	}

	generatePaymentCreditCard(interfaceGeneralService: GeneralServiceInterface, modelProductGeneral: ProductGeneralModel): void
	{
		return this.callServicePrivate(interfaceGeneralService, JSON.stringify(modelProductGeneral), WebAddressConstant.STRING_URL_PRODUCTOTO_GENERATEPAYMENTCREDITCARD);
	}

	//#endregion
}

//#endregion
