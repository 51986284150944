<div class="DivTableControl">
	<div class="DivTableSubControl">
		<label> {{ _stringConstant.STRING_CONTENT_TABLECONTROL_PAGE }} {{ _modelTableForm.Page }} {{ _stringConstant.STRING_CONTENT_TABLECONTROL_FROM }} {{ _modelTableForm.PageTotal }} {{ _stringConstant.STRING_CONTENT_TABLECONTROL_WITH }} {{ _modelTableForm.RowPerPage }} {{ _stringConstant.STRING_CONTENT_TABLECONTROL_ENTRIES }}</label>
	</div>

	<div class="DivTableSubControl">
		<label *ngIf="_modelTableForm.Page! > 1" (click)="goToFirst()"> {{ _stringConstant.STRING_CONTENT_TABLECONTROL_FIRST }} </label>
		<label>
			<span *ngIf="_modelTableForm.Page! > 1" (click)="goToPrevious()"> {{ _modelTableForm.Page! - 1 }} </span>
			<strong> {{ _modelTableForm.Page }} </strong>
			<span *ngIf="booleanNextPage()" (click)="goToNext()"> {{ _modelTableForm.Page! + 1 }} </span>
		</label>
		<label *ngIf="booleanNextPage()" (click)="goToLast()"> {{ _stringConstant.STRING_CONTENT_TABLECONTROL_LAST }} </label>
	</div>
</div>