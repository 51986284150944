//#region IMPORT

import { ENUM_BANK_TYPE, ENUM_PAYMENT_METHOD, ENUM_PAYMENT_PROVIDER } from "../constants/enum.constant";
import { BaseModel } from "./bases/base.model";
import { ProductGeneralModel } from "./productgeneral.model";

//#endregion


//#region CLASS

export class ThirdPartyPaymentModel extends BaseModel
{
	/*
		Attribute - START
		Description : Attribute for third party payment model, used for payment in BRINS Digital.
		Author: Billy Johannes.
		Created on : Tuesday, 24 May 2022.		 				Updated on : Wednesday, 08 June 2022.
		Created by : Billy Johannes.							Updated by : Eka Saputra.
		Version : 1.0:0.
	*/

	ID?: number;
	Token?: string;

	ProductGeneralID?: number;
	OrderID?: number;

	TransactionThirdPartyID?: string;
	TransactionStatus?: string;
	MerchantID?: string;
	TotalPaymentAmount?: number;
	Currency?: string;
	PaymentMethod?: ENUM_PAYMENT_METHOD;
	PaymentProvider?: ENUM_PAYMENT_PROVIDER;
	BankType?: ENUM_BANK_TYPE;
	VirtualAccountCode?: string;
	VirtualAccountNumber?: string;
	AccountCode?: string;
	AccountNumber?: string;
	PaymentURL?: string;
	PaymentCode?: string;
	FraudStatus?: string;
	QRCodeURL?: string;
	DeepLinkGojekURL?: string;
	ExpiredDate?: Date;

	PaidStatus?: boolean;
	PaidOn?: Date;


	modelProductGeneral?: ProductGeneralModel;

	constructor()
	{
		super();
		this.modelProductGeneral = new ProductGeneralModel();
	}

	/* Attribute - END */
}

//#endregion