//#region IMPORT

import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { WebAddressConstant } from "../constants/webaddress.constant";
import { GeneralServiceInterface } from "../interfaces/general.service.interface";
import { ProductGeneralModel } from "../models/productgeneral.model";
import { ProductMyMotorcycleModel } from "../models/productmymotorcycle.model";
import { BaseService } from "./bases/base.service";
import { SessionService } from "./session.service";

//#endregion


//#region INJECTABLE

@Injectable
(
	{
		providedIn: "root"
	}
)

//#endregion


//#region CLASS

export class ProductMyMotorcycleService extends BaseService
{
	//#region CONSTRUCTOR

	constructor(clientHTTP: HttpClient, serviceSession: SessionService)
	{
		super(clientHTTP, serviceSession);
	}

	//#endregion


	//#region CALCULATE

	calculatePremiumForCustomer(interfaceGeneralService: GeneralServiceInterface, modelProductGeneral: ProductGeneralModel): void
	{
		return this.callServicePrivate(interfaceGeneralService, JSON.stringify(modelProductGeneral), WebAddressConstant.STRING_URL_PRODUCTMYMOTORCYCLE_CALCULATEPREMIUMFORCUSTOMER);
	}

	//#endregion


	//#region INSERT

	insertProductForCustomer(interfaceGeneralService: GeneralServiceInterface, modelProductGeneral: ProductGeneralModel): void
	{
		return this.callServicePrivate(interfaceGeneralService, JSON.stringify(modelProductGeneral), WebAddressConstant.STRING_URL_PRODUCTMYMOTORCYCLE_INSERTPRODUCTFORCUSTOMER);

	}

	//#endregion


	//#region UPDATE

	updateInsuredForWebsiteCustomer(interfaceGeneralService: GeneralServiceInterface, modelProductGeneral: ProductGeneralModel): void
	{
		return this.callServicePrivate(interfaceGeneralService, JSON.stringify(modelProductGeneral), WebAddressConstant.STRING_URL_PRODUCTMYMOTORCYCLE_UPDATEINSUREDFORWEBSITECUSTOMER);
	}
	updateBeneficiariesForWebsiteCustomer(interfaceGeneralService: GeneralServiceInterface, modelProductGeneral: ProductGeneralModel): void
	{
		return this.callServicePrivate(interfaceGeneralService, JSON.stringify(modelProductGeneral), WebAddressConstant.STRING_URL_PRODUCTMYMOTORCYCLE_UPDATEBENEFICIARIESFORWEBSITECUSTOMER);
	}

	//#endregion


	//#region UPLOAD

	uploadProductForCustomer(interfaceGeneralService: GeneralServiceInterface, modelProductMyMotorcycle: ProductMyMotorcycleModel): void
	{
		return this.callServicePrivate(interfaceGeneralService, JSON.stringify(modelProductMyMotorcycle), WebAddressConstant.STRING_URL_PRODUCTMYMOTORCYCLE_UPLOADPRODUCTFORWEBSITECUSTOMER);
	}

	uploadProductForWebsiteCustomer(interfaceGeneralService: GeneralServiceInterface, modelProductMyMotorcycle: ProductMyMotorcycleModel): void
	{
		return this.callServicePrivate(interfaceGeneralService, JSON.stringify(modelProductMyMotorcycle), WebAddressConstant.STRING_URL_PRODUCTMYMOTORCYCLE_UPLOADPRODUCTFORWEBSITECUSTOMER);
	}

	//#endregion


	//#region SELECT

	selectProductByTokenForCustomer(interfaceGeneralService: GeneralServiceInterface, modelProductMyMotorcycle: ProductMyMotorcycleModel): void
	{
		return this.callServicePrivate(interfaceGeneralService, JSON.stringify(modelProductMyMotorcycle), WebAddressConstant.STRING_URL_PRODUCTMYMOTORCYCLE_SELECTPRODUCTBYTOKENFORCUSTOMER);

	}

	//#endregion


	//#region SAVE

	saveProductForCustomer(interfaceGeneralService: GeneralServiceInterface, modelProductGeneral: ProductGeneralModel): void
	{
		return this.callServicePrivate(interfaceGeneralService, JSON.stringify(modelProductGeneral), WebAddressConstant.STRING_URL_PRODUCTMYMOTORCYCLE_SAVEPRODUCTFORWEBSITECUSTOMER);

	}

	//#endregion


	//#region SEND

	sendSPPAByEmailForCustomer(interfaceGeneralService: GeneralServiceInterface, modelProductGeneral: ProductGeneralModel): void
	{
		return this.callServicePrivate(interfaceGeneralService, JSON.stringify(modelProductGeneral), WebAddressConstant.STRING_URL_PRODUCTMYMOTORCYCLE_SENDSPPABYEMAILFORCUSTOMER);
	}

	//#endregion


	//#region PAYMENT

	// masih controller asri

	confirmPayment(interfaceGeneralService: GeneralServiceInterface, modelProductMyMotorcycle: ProductMyMotorcycleModel): void
	{
		return this.callServicePrivate(interfaceGeneralService, JSON.stringify(modelProductMyMotorcycle), WebAddressConstant.STRING_URL_PRODUCTMYMOTORCYCLE_CONFIRMPAYMENT);

	}

	sharePaymentForCustomer(interfaceGeneralService: GeneralServiceInterface, modelProductGeneral: ProductGeneralModel): void
	{
		return this.callServicePrivate(interfaceGeneralService, JSON.stringify(modelProductGeneral), WebAddressConstant.STRING_URL_PRODUCTMYMOTORCYCLE_SHAREPAYMENTFORCUSTOMER);
	}

	generatePaymentBRIVA(interfaceGeneralService: GeneralServiceInterface, modelProductGeneral: ProductGeneralModel): void
	{
		return this.callServicePrivate(interfaceGeneralService, JSON.stringify(modelProductGeneral), WebAddressConstant.STRING_URL_PRODUCTMYMOTORCYCLE_GENERATEPAYMENTBRIVA);
	}

	generatePaymentBNI(interfaceGeneralService: GeneralServiceInterface, modelProductGeneral: ProductGeneralModel): void
	{
		return this.callServicePrivate(interfaceGeneralService, JSON.stringify(modelProductGeneral), WebAddressConstant.STRING_URL_PRODUCTMYMOTORCYCLE_GENERATEPAYMENTBNI);
	}

	generatePaymentBCA(interfaceGeneralService: GeneralServiceInterface, modelProductGeneral: ProductGeneralModel): void
	{
		return this.callServicePrivate(interfaceGeneralService, JSON.stringify(modelProductGeneral), WebAddressConstant.STRING_URL_PRODUCTMYMOTORCYCLE_GENERATEPAYMENTBCA);
	}

	generatePaymentMandiri(interfaceGeneralService: GeneralServiceInterface, modelProductGeneral: ProductGeneralModel): void
	{
		return this.callServicePrivate(interfaceGeneralService, JSON.stringify(modelProductGeneral), WebAddressConstant.STRING_URL_PRODUCTMYMOTORCYCLE_GENERATEPAYMENTMANDIRI);
	}

	generatePaymentPermata(interfaceGeneralService: GeneralServiceInterface, modelProductGeneral: ProductGeneralModel): void
	{
		return this.callServicePrivate(interfaceGeneralService, JSON.stringify(modelProductGeneral), WebAddressConstant.STRING_URL_PRODUCTMYMOTORCYCLE_GENERATEPAYMENTPERMATA);
	}

	generatePaymentQRIS(interfaceGeneralService: GeneralServiceInterface, modelProductGeneral: ProductGeneralModel): void
	{
		return this.callServicePrivate(interfaceGeneralService, JSON.stringify(modelProductGeneral), WebAddressConstant.STRING_URL_PRODUCTMYMOTORCYCLE_GENERATEPAYMENTQRIS);
	}

	generatePaymentIndomart(interfaceGeneralService: GeneralServiceInterface, modelProductGeneral: ProductGeneralModel): void
	{
		return this.callServicePrivate(interfaceGeneralService, JSON.stringify(modelProductGeneral), WebAddressConstant.STRING_URL_PRODUCTMYMOTORCYCLE_GENERATEPAYMENTINDOMARET);
	}

	generatePaymentAlfamart(interfaceGeneralService: GeneralServiceInterface, modelProductGeneral: ProductGeneralModel): void
	{
		return this.callServicePrivate(interfaceGeneralService, JSON.stringify(modelProductGeneral), WebAddressConstant.STRING_URL_PRODUCTMYMOTORCYCLE_GENERATEPAYMENTALFAMART);
	}

	generatePaymentShopeePay(interfaceGeneralService: GeneralServiceInterface, modelProductGeneral: ProductGeneralModel): void
	{
		return this.callServicePrivate(interfaceGeneralService, JSON.stringify(modelProductGeneral), WebAddressConstant.STRING_URL_PRODUCTMYMOTORCYCLE_GENERATEPAYMENTSHOPEEPAY);
	}

	generatePaymentCreditCard(interfaceGeneralService: GeneralServiceInterface, modelProductGeneral: ProductGeneralModel): void
	{
		return this.callServicePrivate(interfaceGeneralService, JSON.stringify(modelProductGeneral), WebAddressConstant.STRING_URL_PRODUCTMYMOTORCYCLE_GENERATEPAYMENTCREDITCARD);
	}

	//#endregion

}

//#endregion
