//#region IMPORT

import { Component } from "@angular/core";
import { Router } from "@angular/router";
import { BaseAuthourizeComponent } from "src/app/components/bases/baseauthourize.component";
import { StringConstant } from "src/app/constants/string.constant";
import { SessionService } from "src/app/services/session.service";

//#endregion


//#region COMPONENT

@Component
(
	{
		selector: "app-mobileapplication",
		templateUrl: "./mobileapplication.component.html",
		styleUrls: ["./mobileapplication.component.sass"],
	}
)

//#endregion


//#region  CLASS

export class MobileApplicationComponent extends BaseAuthourizeComponent
{
	//#region DECLARATION

	public _stringConstant = StringConstant;
	public _stringImageMainHeader: string;
	public _stringImageMainHeaderMobile: string;

	//#endregion


	//#region CONSTRUCTUR

	constructor(serviceSession: SessionService, router: Router)
	{
		super(serviceSession, router);
		this._stringImageMainHeader = "../../../assets/photos/sakina/photo_mobileapplication.jpg";
		this._stringImageMainHeaderMobile = "../../../assets/photos/sakina/mobileheader/photo_mobile.jpg";
	}

	//#endregion


	//#region ONINIT

	ngOnInit(): void
	{
	}

	//#endregion


	//#region AFTERVIEWINIT

	ngAfterViewInit(): void
	{
		this.setViewFromTop();
	}

	//#endregion


	//#region FUNCTION

	getImageMain(): any
	{
		if(this.checkMinimumPixelForPhotoHeader())
		{
			if(this._stringImageMainHeaderMobile === null && this._stringImageMainHeaderMobile === undefined && this._stringImageMainHeaderMobile === "")
			{
				return {};
			}
			else
			{
				return {"background-image": "url(" + this._stringImageMainHeaderMobile + ")"};
			}
		}
		else
		{
			if(this._stringImageMainHeader === null && this._stringImageMainHeader === undefined && this._stringImageMainHeader === "")
			{
				return {};
			}
			else
			{
				return {"background-image": "url(" + this._stringImageMainHeader + ")"};
			}
		}
	}


	//#endregion


	//#region WEB SERVICES


	//#endregion


	//#region NAVIGATION

	goToDownloadMPMMobileFromGooglePlay()
	{
		window.open(this._stringConstant.STRING_URL_APPLICATION_GOOGLEPLAY_MPMMOBILE, this._stringConstant.STRING_WINDOWOPEN_TARGET_BLANK);
	}

	goToDownloadMPMMobileFromAppStrore()
	{
		window.open(this._stringConstant.STRING_URL_APPLICATION_APPSTORE_MPMMOBILE, this._stringConstant.STRING_WINDOWOPEN_TARGET_BLANK);
	}

	goToDownloadBRINSMobileFromGooglePlay()
	{
		window.open(this._stringConstant.STRING_URL_APPLICATION_GOOGLEPLAY_BRINSMOBILE, this._stringConstant.STRING_WINDOWOPEN_TARGET_BLANK);
	}

	goToDownloadBRINSMobileFromAppStrore()
	{
		window.open(this._stringConstant.STRING_URL_APPLICATION_APPSTORE_BRINSMOBILE, this._stringConstant.STRING_WINDOWOPEN_TARGET_BLANK);
	}

	goToDownloadBRINSAgentFromGooglePlay()
	{
		window.open(this._stringConstant.STRING_URL_APPLICATION_GOOGLEPLAY_BRINSAGENT, this._stringConstant.STRING_WINDOWOPEN_TARGET_BLANK);
	}

	//#endregion

}
