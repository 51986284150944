<div class="DivPageContainer" id="divMainLandingContainer">
    <section class="SectionMain" [ngStyle]="getImageMain()">
        <img class="ImgShapeMain1" src="../../../assets/gifs/transparent.gif">
        <img class="ImgShapeMain2" src="../../../assets/gifs/transparent.gif">
        <div class="DivFormHorizontalContainer" id="divMainLanding">
            <div class="DivForm2Column DivHeaderCenterContainer">
				<div class="DivHeaderContentContainer">
                    <h1 class="FontSoftColor">BRI Insurance</h1>
                    <h1 class="FontSoftColor">Technology</h1>
                    <h5 class="FontSoftColor">Beyond Insurance Services</h5>
                    <p class="FontSoftColor Width100">BRINSurtech adalah layanan asuransi berbasis teknologi untuk kebutuhan asuransi simple risk dan mikro.</p>
                    <button class="ButtonSubmit" *ngIf="_modelUserSignIn === null || _modelUserSignIn === undefined" (click)="goToSignIn();"><label class="FontSoftColor">Login</label></button>
                </div>
                <!-- <div class="DivContentSearchContainer">
                    <div class="DivContentSearch">
                        <img src="../../assets/icons/sakina/icon_search.svg"  alt="Search">
                        <div class="DivContentSearchDetailFilter">
                            <input type="text" placeholder="Cari di disini">
                        </div>
                    </div>
                </div> -->
            </div>
            <div class="DivForm2Column DivSocialMediaCenterContainer">
                <div class="DivSocialMedia">
                    <img [src]="socialMedia.Path" *ngFor="let socialMedia of _arraySocialMedia" (click)="goToSNS(socialMedia.Link!)">
                </div>
            </div>
        </div>
    </section>

    <section class="SectionSecondary">
        <div class="DivFormHorizontalContainer DivSiteContainer" id="divSiteLandingContainer">
            <div class="DivContentBox DivRelativeContainer" [ngStyle]="getImageBrinsDigital()">
                <button class="ButtonSubmit" (click)="setBooleanVideo(true)"><label class="FontSoftColor">Lihat Video</label></button>
            </div>
            <div class="DivForm2Column FlexGrow1 FlexShrink1" id="divParagraphLanding">
                <h3>BRI Insurance</h3>
                <h3>Technology</h3>
                <p>Perkembangan teknologi yang sangat cepat serta tuntutan era disrupsi digital turut mengakselerasi BRI Insurance untuk melakukan digitalisasi dalam proses bisnis. Digitalisasi ini merupakan upaya BRI Insurance dalam beradaptasi dan merespon tantangan di era saat ini secara agile dalam menjalankan bisnis.</p>
            </div>
            <div class="DivForm2Column FlexGrow3 FlexShrink1" id="divProductLanding">
                <ul class="UlTabContainer">
                    <li class="ListTab" [ngClass]="{ 'ListActive' : showProductPeriode(_enumPeriodeProduct.Yearly) }" (click)="setProductPeriode(_enumPeriodeProduct.Yearly)">Tahunan</li>
                    <li class="ListTab" [ngClass]="{ 'ListActive' : showProductPeriode(_enumPeriodeProduct.Monthly) }" (click)="setProductPeriode(_enumPeriodeProduct.Monthly)">Bulanan</li>
                    <li class="ListTab" [ngClass]="{ 'ListActive' : showProductPeriode(_enumPeriodeProduct.Daily) }" (click)="setProductPeriode(_enumPeriodeProduct.Daily)">Harian</li>
                </ul>

                <div class="DivContentBox DivContentImage" id="divProductContainer">
                    <div class="DivFormHorizontalContainer Width100">
                        <div class="DivProduct" *ngFor="let modelProduct of this._arrayModelProduct" (click)="goToPackage(modelProduct.Code!)">
                            <img [src]="modelProduct.Path">
                            <label>{{ modelProduct.Name }}</label>
                        </div>
                    </div>
                    <div class="DivFormHorizontalContainer Width100">
                        <div class="DivProduct" *ngFor="let modelProductAsmik of this._arrayModelProductAsmik" (click)="goToProductAsmik(modelProductAsmik.Code!)">
                            <img [src]="modelProductAsmik.Path">
                            <label>{{ modelProductAsmik.Name }}</label>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</div>

<!-- START MODAL -->

<div class="DivModal" [ngStyle]="_booleanModalVideo ? {'display': 'block'} : {'background-color': 'none'}">
	<div class="DivModalHeader">
		<h2>BRI Insurance Technology</h2>
		<img (click)="setBooleanVideo(false)" src="../../assets/icons/sakina/icon_close.svg">
	</div>
	<div class="DivModalFigureContainer">
		<figure class="DivModal">
			<iframe [src]="_stringURLAboutBRINSEmbed | safe" frameborder="0" allow="autoplay" allowfullscreen></iframe>
		</figure>
	</div>
</div>

<!-- END MODAL -->