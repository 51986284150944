//#region IMPORT

import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { WebAddressConstant } from "../constants/webaddress.constant";
import { GeneralServiceInterface } from "../interfaces/general.service.interface";
import { ProductGeneralModel } from "../models/productgeneral.model";
import { ProductBicycleModel } from "../models/productbicycle.model";
import { BaseService } from "./bases/base.service";
import { SessionService } from "./session.service";

//#endregion


//#region INJECTABLE

@Injectable
(
	{
		providedIn: "root"
	}
)

//#endregion


//#region CLASS

export class ProductBicycleService extends BaseService
{
	//#region CONSTRUCTOR

	constructor(clientHTTP: HttpClient, serviceSession: SessionService)
	{
		super(clientHTTP, serviceSession);
	}

	//#endregion


	//#region SELECT

	selectCalculatePremiumBicycle(interfaceGeneralService: GeneralServiceInterface, modelProductGeneral: ProductGeneralModel): void
	{
		return this.callServicePrivate(interfaceGeneralService, JSON.stringify(modelProductGeneral), WebAddressConstant.STRING_URL_PRODUCT_SELECTCALCULATEPREMIUMBICYCLE);
	}

	calculatePremiumForCustomer(interfaceGeneralService: GeneralServiceInterface, modelProductGeneral: ProductGeneralModel): void
	{
		return this.callServicePrivate(interfaceGeneralService, JSON.stringify(modelProductGeneral), WebAddressConstant.STRING_URL_PRODUCTBICYCLE_CALCULATEPREMIUMFORCUSTOMER);
	}

	selectProductByTokenForCustomer(interfaceGeneralService: GeneralServiceInterface, modelProductBicycle: ProductBicycleModel): void
	{
		return this.callServicePrivate(interfaceGeneralService, JSON.stringify(modelProductBicycle), WebAddressConstant.STRING_URL_PRODUCTBICYCLE_SELECTPRODUCTBYTOKENFORCUSTOMER);
	}

	//#endregion


	//#region SEND

	sendCalculatePremiumBicycleForOperationAndSupport(interfaceGeneralService: GeneralServiceInterface, modelProductGeneral: ProductGeneralModel): void
	{
		return this.callServicePrivate(interfaceGeneralService, JSON.stringify(modelProductGeneral), WebAddressConstant.STRING_URL_PRODUCT_SENDCALCULATEPREMIUMBICYCLEBYEMAILFOROPERATIONANDSUPPORT);
	}

	sendSPPAByEmailForCustomer(interfaceGeneralService: GeneralServiceInterface, modelProductGeneral: ProductGeneralModel): void
	{
		return this.callServicePrivate(interfaceGeneralService, JSON.stringify(modelProductGeneral), WebAddressConstant.STRING_URL_PRODUCTBICYCLE_SENDSPPABYEMAILFORCUSTOMER);
	}

	//#endregion


	//#region INSERT

	insertProductForCustomer(interfaceGeneralService: GeneralServiceInterface, modelProductGeneral: ProductGeneralModel): void
	{
		return this.callServicePrivate(interfaceGeneralService, JSON.stringify(modelProductGeneral), WebAddressConstant.STRING_URL_PRODUCTBICYCLE_INSERTPRODUCTFORCUSTOMER);
	}

	//#endregion


	//#region UPLOAD

	uploadProductForCustomer(interfaceGeneralService: GeneralServiceInterface, modelProductBicycle: ProductBicycleModel): void
	{
		return this.callServicePrivate(interfaceGeneralService, JSON.stringify(modelProductBicycle), WebAddressConstant.STRING_URL_PRODUCTBICYCLE_UPLOADPRODUCTFORCUSTOMER);
	}

	uploadProductForWebsiteCustomer(interfaceGeneralService: GeneralServiceInterface, modelProductBicycle: ProductBicycleModel): void
	{
		return this.callServicePrivate(interfaceGeneralService, JSON.stringify(modelProductBicycle), WebAddressConstant.STRING_URL_PRODUCTBICYCLE_UPLOADPRODUCTFORWEBSITECUSTOMER);
	}

	//#endregion


	//#region UPDATE

	updateInsuredForCustomer(interfaceGeneralService: GeneralServiceInterface, modelProductGeneral: ProductGeneralModel): void
	{
		return this.callServicePrivate(interfaceGeneralService, JSON.stringify(modelProductGeneral), WebAddressConstant.STRING_URL_PRODUCTBICYCLE_UPDATEINSUREDFORCUSTOMER);
	}

	updateBeneficiariesForCustomer(interfaceGeneralService: GeneralServiceInterface, modelProductGeneral: ProductGeneralModel): void
	{
		return this.callServicePrivate(interfaceGeneralService, JSON.stringify(modelProductGeneral), WebAddressConstant.STRING_URL_PRODUCTBICYCLE_UPDATEBENEFICIARIESBICYCLE);
	}

	updateQuestionnaireForCustomer(interfaceGeneralService: GeneralServiceInterface, modelProductGeneral: ProductGeneralModel): void
	{
		return this.callServicePrivate(interfaceGeneralService, JSON.stringify(modelProductGeneral), WebAddressConstant.STRING_URL_PRODUCTBICYCLE_UPDATEQUESTIONNAIREBICYCLE);
	}

	//#endregion


	//#region SAVE

	saveProductForWebsiteCustomer(interfaceGeneralService: GeneralServiceInterface, modelProductGeneral: ProductGeneralModel): void
	{
		return this.callServicePrivate(interfaceGeneralService, JSON.stringify(modelProductGeneral), WebAddressConstant.STRING_URL_PRODUCTBICYCLE_SAVEPRODUCTFORWEBSITECUSTOMER);
	}

	//#endregion


	//#region PAYMENT

	confirmPayment(interfaceGeneralService: GeneralServiceInterface, modelProductBicycle: ProductBicycleModel): void
	{
		return this.callServicePrivate(interfaceGeneralService, JSON.stringify(modelProductBicycle), WebAddressConstant.STRING_URL_PRODUCT_BICYCLECONFIRMPAYMENT);

	}

	sharePaymentForCustomer(interfaceGeneralService: GeneralServiceInterface, modelProductGeneral: ProductGeneralModel): void
	{
		return this.callServicePrivate(interfaceGeneralService, JSON.stringify(modelProductGeneral), WebAddressConstant.STRING_URL_PRODUCT_BICYCLESHAREPAYMENTFORCUSTOMER);

	}

	generatePaymentBRIVA(interfaceGeneralService: GeneralServiceInterface, modelProductGeneral: ProductGeneralModel): void
	{
		return this.callServicePrivate(interfaceGeneralService, JSON.stringify(modelProductGeneral), WebAddressConstant.STRING_URL_PRODUCT_BICYCLEGENERATEPAYMENTBRIVA);
	}

	generatePaymentBNI(interfaceGeneralService: GeneralServiceInterface, modelProductGeneral: ProductGeneralModel): void
	{
		return this.callServicePrivate(interfaceGeneralService, JSON.stringify(modelProductGeneral), WebAddressConstant.STRING_URL_PRODUCT_BICYCLEGENERATEPAYMENTBNI);
	}

	generatePaymentBCA(interfaceGeneralService: GeneralServiceInterface, modelProductGeneral: ProductGeneralModel): void
	{
		return this.callServicePrivate(interfaceGeneralService, JSON.stringify(modelProductGeneral), WebAddressConstant.STRING_URL_PRODUCT_BICYCLEGENERATEPAYMENTBCA);
	}

	generatePaymentMandiri(interfaceGeneralService: GeneralServiceInterface, modelProductGeneral: ProductGeneralModel): void
	{
		return this.callServicePrivate(interfaceGeneralService, JSON.stringify(modelProductGeneral), WebAddressConstant.STRING_URL_PRODUCT_BICYCLEGENERATEPAYMENTMANDIRI);
	}

	generatePaymentPermata(interfaceGeneralService: GeneralServiceInterface, modelProductGeneral: ProductGeneralModel): void
	{
		return this.callServicePrivate(interfaceGeneralService, JSON.stringify(modelProductGeneral), WebAddressConstant.STRING_URL_PRODUCT_BICYCLEGENERATEPAYMENTPERMATA);
	}

	generatePaymentQRIS(interfaceGeneralService: GeneralServiceInterface, modelProductGeneral: ProductGeneralModel): void
	{
		return this.callServicePrivate(interfaceGeneralService, JSON.stringify(modelProductGeneral), WebAddressConstant.STRING_URL_PRODUCT_BICYCLEGENERATEPAYMENTQRIS);
	}

	generatePaymentIndomart(interfaceGeneralService: GeneralServiceInterface, modelProductGeneral: ProductGeneralModel): void
	{
		return this.callServicePrivate(interfaceGeneralService, JSON.stringify(modelProductGeneral), WebAddressConstant.STRING_URL_PRODUCT_BICYCLEGENERATEPAYMENTINDOMARET);
	}

	generatePaymentAlfamart(interfaceGeneralService: GeneralServiceInterface, modelProductGeneral: ProductGeneralModel): void
	{
		return this.callServicePrivate(interfaceGeneralService, JSON.stringify(modelProductGeneral), WebAddressConstant.STRING_URL_PRODUCT_BICYCLEGENERATEPAYMENTALFAMART);
	}

	generatePaymentShopeePay(interfaceGeneralService: GeneralServiceInterface, modelProductGeneral: ProductGeneralModel): void
	{
		return this.callServicePrivate(interfaceGeneralService, JSON.stringify(modelProductGeneral), WebAddressConstant.STRING_URL_PRODUCT_BICYCLEGENERATEPAYMENTSHOPEEPAY);
	}

	generatePaymentCreditCard(interfaceGeneralService: GeneralServiceInterface, modelProductGeneral: ProductGeneralModel): void
	{
		return this.callServicePrivate(interfaceGeneralService, JSON.stringify(modelProductGeneral), WebAddressConstant.STRING_URL_PRODUCTBICYCLE_GENERATEPAYMENTCREDITCARD);
	}

	//#endregion
}

//#endregion
