/* eslint-disable max-len */
//#region CONSTANT

export class StringConstant
{
	//#region REGULUAR_EXPRESSION

	static STRING_REGULAREXPRESSION_PERSONNAME: string = "^[A-Za-z\\s]{1,}[\\.]{0," + "1}[A-Za-z\\s]{0,}$";
	static STRING_REGULAREXPRESSION_ALPHABET: string = "^[a-zA-Z]+$";
	static STRING_REGULAREXPRESSION_ALPHABET_WITHSPACE: string = "^[a-zA-Z ]+$";
	static STRING_REGULAREXPRESSION_NUMERIC: string = "^[0-9]*$";
	static STRING_REGULAREXPRESSION_ALPHABETNUMERIC: string = "^[a-zA-Z0-9]+$";
	static STRING_REGULAREXPRESSION_ALPHABETNUMERICWITHHYPEN: string = "^[a-zA-Z0-9-]+$";
	static STRING_REGULAREXPRESSION_EMAIL: string = "[a-zA-Z0-9\\+\\.\\_\\%\\-\\+]{1,256}\\@[a-zA-Z0-9][a-zA-Z0-9\\-]{0,64}(\\.[a-zA-Z0-9][a-zA-Z0-9\\-]{0,25})+";
	static STRING_REGULAREXPRESSION_MOBILEPHONEWITHPLUS: string = "^[+]?[0-9]{10,15}$";
	static STRING_REGULAREXPRESSION_DATEISO8601: string = "[0-9]{4}-[0-9]{2}-[0-9]{1,2}";
	static STRING_REGULAREXPRESSION_CURRENCY: string = "[^0-9]";
	static STRING_REGULAREXPRESSION_SPACE: RegExp = /\s/g;
	static STRING_REGULAREXPRESSION_MINUS: string = "-";

	//#endregion


	//#region LANGUAGE_CULTURE

	static STRING_CULTURE_INDONESIAN: string = "id-ID";
	static STRING_CULTURE_ENGLISH: string = "id-ID";

	static STRING_LANGUAGE_INDONESIAN: string = "ID";
	static STRING_LANGUAGE_ENGLISH: string = "EN";

	//#endregion


	//#region CHARACTER

	static STRING_CHARACTER_SEPARATOR_DATE_VIEW: string = "/";
	static STRING_CHARACTER_SEPARATOR_DATE_SERVER: string = "-";
	static STRING_CHARACTER_SEPARATOR_DATETIME_SERVER: string = "'T'";
	static STRING_CHARACTER_SEPARATOR_TIME_VIEW: string = ":";
	static STRING_CHARACTER_SEPARATOR_TIMEZONE_VIEW: string = "Z";
	static STRING_CHARACTER_SEPARATOR_DECIMAL: string = ";";
	static STRING_CHARACTER_SEPARATOR_THOUSAND: string = ".";
	static STRING_CHARACTER_DASH: string = " ";
	static STRING_CHARACTER_EMPTY: string = "-";
	static STRING_CHARACTER_NULL: string = "";

	static STRING_CHARACTER_SEPARATOR_FORMAT: string = ".";
	static STRING_CHARACTER_SEPARATOR_DIRECTORY: string = "/";
	static STRING_CHARACTER_SEPARATOR_FILENAME: string = "_";

	//#endregion


	//#region TAG

	static STRING_TAG_VERIFY_IDENTITYCARD: string = "PRODUCTASRI_";
	static STRING_TAG_VERIFY_SELFIEIDENTITYCARD: string = "PRODUCTOTO_";

	//#endregion


	//#region DATE

	static STRING_DATE_SERVER_DEFAULT: string = "0000" + StringConstant.STRING_CHARACTER_SEPARATOR_DATE_SERVER + "00" + StringConstant.STRING_CHARACTER_SEPARATOR_DATE_SERVER + "00";
	static STRING_TIME_SERVER_DEFAULT: string = "00" + StringConstant.STRING_CHARACTER_SEPARATOR_TIME_VIEW + "00" + StringConstant.STRING_CHARACTER_SEPARATOR_TIME_VIEW + "00" + StringConstant.STRING_CHARACTER_SEPARATOR_TIMEZONE_VIEW;
	static STRING_DATETIME_SERVER_DEFAULT: string = StringConstant.STRING_DATE_SERVER_DEFAULT + StringConstant.STRING_CHARACTER_SEPARATOR_DATETIME_SERVER + StringConstant.STRING_TIME_SERVER_DEFAULT;
	static NUMBER_TIME_SERVER_COMPONENT: number = 3;

	static STRING_DATE_SERVER_FORMAT: string = "yyyy-MM-dd";
	static STRING_TIME_SERVER_FORMAT: string = "HH:mm:ss";
	static STRING_DATETIME_SERVER_FORMAT: string = StringConstant.STRING_DATE_SERVER_FORMAT + StringConstant.STRING_CHARACTER_SEPARATOR_DATETIME_SERVER + StringConstant.STRING_TIME_SERVER_FORMAT + StringConstant.STRING_CHARACTER_SEPARATOR_TIMEZONE_VIEW;
	static STRING_DATETIME_SERVER_FORMATWITHOUTTIMEZONE: string = StringConstant.STRING_DATE_SERVER_FORMAT + StringConstant.STRING_CHARACTER_SEPARATOR_DATETIME_SERVER + StringConstant.STRING_TIME_SERVER_FORMAT;

	static STRING_DATE_VIEW_FORMAT: string = "dd/MM/yyyy";
	static STRING_TIME_VIEW_FORMAT: string = "HH:mm:ss";
	static STRING_TIME_VIEW_FORMATSHORT: string = "HH:mm";
	static STRING_DATETIME_VIEW_FORMAT: string = StringConstant.STRING_DATE_VIEW_FORMAT + " " + StringConstant.STRING_TIME_VIEW_FORMAT;
	static STRING_DATETIME_VIEW_FORMATSHORT: string = StringConstant.STRING_DATE_VIEW_FORMAT + " " + StringConstant.STRING_TIME_VIEW_FORMATSHORT;
	static STRING_TIMEZONE_VIEW_FORMAT: string = "GMT+07:00";

	static STRING_CURRENCY_IDR_CODE: string = "IDR";
	static STRING_CURRENCY_IDR_PREFIX: string = "Rp ";
	static STRING_CURRENCY_THOUSANDS: string = "K. ";
	static STRING_CURRENCY_KILO: string = " Km";
	static STRING_CURRENCY_KILOMETERSPERHOUR: string = " Km/h";

	static STRING_DATE_SHORT_FORMAT = "d-M-yyyy";
	static STRING_DATE_MEDIUM_FORMAT = "d MMMM y";
	static STRING_DATE_LONG_FORMAT = "EEEE, d MMMM y";
	static STRING_DATE_TO_TIME_24HOURS = "it-IT";
	static STRING_DATE_TO_TIME_12HOURS = "en-US";

	static STRING_TIME_SHORT_FORMAT = "HH:mm";
	static STRING_TIME_MEDIUM_FORMAT = "HH:mm:ss";
	static STRING_TIME_LONG_FORMAT = "HH:mm:ss z";

	static STRING_DATETIME_COMPLETE_FORMAT = "EEEE, d MMMM y | HH:mm:ss";

	//#endregion


	//#region KEY_VALUE

	static STRING_VALUE_YEAR_MINIMUM: string = "1900";
	static STRING_VALUE_APPLICATION_VERSION: string = "1.0.48";
	static STRING_VALUE_CLIENT_VERSION: string = "1.1:10";
	static STRING_VALUE_PRODUCT_ASRIYEARLY: string = "0117";
	static STRING_VALUE_PRODUCT_ASRIYMONTHLY: string = "0101";
	static STRING_VALUE_PRODUCT_ASRIYMONTHLYCUSTOMTEROBMC: string = "0110";
	static STRING_VALUE_PRODUCT_ASRIYMONTHLYCUSTOMPSAGBI: string = "0111";
	static STRING_VALUE_PRODUCT_OTO: string = "0206";
	static STRING_VALUE_PRODUCT_OTOMONTHLYDAILY: string = "0201";
	static STRING_VALUE_PRODUCT_BICYCLE: string = "1216";
	static STRING_VALUE_PRODUCT_PERSONALACCIDENT: string = "1001";
	static STRING_VALUE_PRODUCT_DAMAGEOFBUSINESSPLACE: string = "0119";
	static STRING_VALUE_PRODUCT_MYMOTORCYCLE: string = "0210";
	static STRING_VALUE_PRODUCT_MYHOUSE: string = "0114";


	/* COVERAGE CODE YEARLY - START */

	// ASRI
	static STRING_VALUE_PACKAGE_YEARLY_ASRISILVER: string = "ASRI-01";
	static STRING_VALUE_PACKAGE_YEARLY_ASRIGOLD: string = "ASRI-02";
	static STRING_VALUE_PACKAGE_YEARLY_ASRIPLATINUM: string = "ASRI-03";

	// OTO
	static STRING_VALUE_PACKAGE_YEARLY_OTOBRONZE: string = "OTO-01";
	static STRING_VALUE_PACKAGE_YEARLY_OTOSILVER: string = "OTO-02";
	static STRING_VALUE_PACKAGE_YEARLY_OTOGOLD: string = "OTO-03";
	static STRING_VALUE_PACKAGE_YEARLY_OTOPLATINUM: string = "OTO-04";

	// DIRI
	static STRING_VALUE_PACKAGE_YEARLY_PERSONALACCIDENT: string = "PA-BM";

	// BICYCLE
	static STRING_VALUE_PACKAGE_YEARLY_BICYCLESILVER: string = "1216-01";
	static STRING_VALUE_PACKAGE_YEARLY_BICYCLEGOLD: string = "1216-02";
	static STRING_VALUE_PACKAGE_YEARLY_BICYCLEPLATINUM: string = "1216-03";

	// ASMIK RUMAHKU
	static STRING_VALUE_PACKAGE_YEARLY_MYHOUSE: string = "BM-RKU-01";

	// ASMIK KERUSAKAN TEMPAT USAHA
	static STRING_VALUE_PACKAGE_YEARLY_DAMAGEOFBUSINESSPLACE: string = "BM-KTU-01";

	// ASMIK MOTORKU
	static STRING_VALUE_PACKAGE_YEARLY_MYMOTORCYCLE: string = "OTO-07";

	/* COVERAGE CODE YEARLY - END */


	/* COVERAGE CODE MONTHLY - START */

	// ASRI
	static STRING_VALUE_PACKAGE_ASRIMONTHLY_REQUEST: string = "ASRI-03";
	static STRING_VALUE_PACKAGE_MONTHLY_ASRICUSTOM_COVERAGECUSTOM: string = "ASRI-04";
	static STRING_VALUE_PACKAGE_MONTHLY_ASRICUSTOM_COVERAGECUSTOMEARTHQUAKE = "ASRI-05";
	static STRING_VALUE_PACKAGE_ASRICUSTOM_COVERAGECUSTOMTERORISMSABOTAGE = "TERO-BMC";

	// OTO
	static STRING_VALUE_PACKAGE_MONTHLY_OTOCUSTOM_COMPREHENSIVE: string = "OTO-05";
	static STRING_VALUE_PACKAGE_MONTHLY_OTOCUSTOM_TLO: string = "OTO-06";
	static STRING_VALUE_PACKAGE_MONTHLY_OTODAILY: string = "PAYD-F";
	static STRING_VALUE_PACKAGE_OTODAILY_125: string = "125";
	static STRING_VALUE_PACKAGE_OTODAILY_200: string = "200";
	static STRING_VALUE_PACKAGE_OTODAILY_400: string = "400";

	// DIRI
	static STRING_VALUE_PACKAGE_MONTHLY_PERSONALACCIDENT: string = "PA-BMC";

	/* COVERAGE CODE MONTHLY - END */


	/* COVERAGE CODE DAILY - START */

	// OTO
	static STRING_VALUE_PACKAGE_DAILY_OTO: string = "PAYD-F";

	// DIRI
	static STRING_VALUE_PACKAGE_PERSONALACCIDENTDAILY: string = "PAYG-F";

	/* COVERAGE CODE DAILY - END */


	static STRING_VALUE_PRODUCT_ASRI_SYARIAH: string = "0164";
	static STRING_VALUE_PRODUCT_OTO_SYARIAH: string = "0256";
	static STRING_VALUE_PRODUCT_PERSONALACCIDENT_SYARIAH: string = "1051";
	static STRING_VALUE_PACKAGE_ASRISILVER_SYARIAH: string = "ASRI-01-S";
	static STRING_VALUE_PACKAGE_ASRIGOLD_SYARIAH: string = "ASRI-02-S";
	static STRING_VALUE_PACKAGE_ASRIPLATINUM_SYARIAH: string = "ASRI-03-S";
	static STRING_VALUE_PACKAGE_OTOBRONZE_SYARIAH: string = "OTO-01-S";
	static STRING_VALUE_PACKAGE_OTOSILVER_SYARIAH: string = "OTO-02-S";
	static STRING_VALUE_PACKAGE_OTOGOLD_SYARIAH: string = "OTO-03-S";
	static STRING_VALUE_PACKAGE_OTOPLATINUM_SYARIAH: string = "OTO-04-S";
	static STRING_VALUE_PACKAGE_PERSONALACCIDENTBM_SYARIAH: string = "PA-BM-S";

	static STRING_VALUE_FILENAME_CALCULATEPREMIUM: string = "calculatepremium";
	static STRING_VALUE_FILENAME_SPPA: string = "sppa";
	static STRING_VALUE_FILENAME_POLICYOUTSTANDING: string = "policyoutstanding";
	static STRING_VALUE_FILENAME_POLICYRENEWAL: string = "policyrenewal";

	static STRING_VALUE_PRINTJOB_PDFPREVIEW: string = "Anjani - PDF Preview";

	static STRING_VALUE_COUNTRYCODE_INDONESIA: string = "+62";
	static STRING_NUMBER_VALUE_COUNTRYCODE_INDONESIA: string = "62";
	static STRING_VALUE_COUNTRYCODE_VIETNAM: string = "+84";
	static STRING_VALUE_COUNTRYCODE_NEWJERSEY: string = "+856";
	static STRING_VALUE_COUNTRYCODE_SINGAPORE: string = "+65";
	static STRING_VALUE_COUNTRYCODE_MALAYSIA: string = "+60";
	static STRING_VALUE_COUNTRYCODE_BRUNEI: string = "+673";
	static STRING_VALUE_COUNTRYCODE_THAILAND: string = "+66";
	static STRING_VALUE_COUNTRYCODE_MYANMAR: string = "+95";
	static STRING_VALUE_COUNTRYCODE_PHILIPPINES: string = "+63";
	static STRING_VALUE_COUNTRYCODE_CAMBODIA: string = "+855";
	static STRING_VALUE_TELEPHONE_BRINS_PRIMARY: string = "(21)79170477";
	static STRING_VALUE_TELEPHONE_BRINS_SECONDARY: string = "(21)79170478";
	static STRING_VALUE_TELEPHONE_CALLCENTER: string = "14081";

	static STRING_VALUE_PAYMENT_BRIVA: string = "BRIVA";
	static STRING_VALUE_PAYMENT_BNI: string = "BNI";
	static STRING_VALUE_PAYMENT_BCA: string = "BCA";
	static STRING_VALUE_PAYMENT_PERMATA: string = "Permata";
	static STRING_VALUE_PAYMENT_MANDIRI: string = "Mandiri";
	static STRING_VALUE_PAYMENT_QRIS: string = "QRIS";
	static STRING_VALUE_PAYMENT_ALFAMART: string = "Alfamart";
	static STRING_VALUE_PAYMENT_INDOMART: string = "Indomart";

	static ARRAY_VALUE_COUNTRYCODE: Array<string> = [
		StringConstant.STRING_VALUE_COUNTRYCODE_INDONESIA, StringConstant.STRING_VALUE_COUNTRYCODE_VIETNAM ,StringConstant.STRING_VALUE_COUNTRYCODE_NEWJERSEY, StringConstant.STRING_VALUE_COUNTRYCODE_SINGAPORE,
		StringConstant.STRING_VALUE_COUNTRYCODE_MALAYSIA, StringConstant.STRING_VALUE_COUNTRYCODE_BRUNEI, StringConstant.STRING_VALUE_COUNTRYCODE_THAILAND, StringConstant.STRING_VALUE_COUNTRYCODE_MYANMAR,
		StringConstant.STRING_VALUE_COUNTRYCODE_PHILIPPINES, StringConstant.STRING_VALUE_COUNTRYCODE_CAMBODIA];

	static ARRAY_VALUE_TOC_FIRE: Array<string> = [ "0101", "0102", "0103", "0104", "0105", "0106", "0107", "0108", "0109", "0110", "0111", "0112", "0113", "0114", "0115", "0116", "0117", "0118", "0119", "0120", "0121", "0122", "0151", "0152", "0153", "0154", "0155", "0156", "0157", "0158", "0159", "0160", "0161", "0162", "0163", "0164", "0165", "0166", "0167" ];
	static ARRAY_VALUE_TOC_ENGINEERING: Array<string> = [ "0801", "0802", "0803", "0804", "0805", "0806", "0807", "0808", "0809", "0851", "0852", "0853", "0854", "0855", "0856", "0857", "0858" ];
	static ARRAY_VALUE_TOC_MV: Array<string> = ["0201", "0202", "0203", "0205", "0206", "0208", "0209", "0251", "0252", "0253", "0255", "0256", "0805", "0855"];
	// eslint-disable-next-line max-len
	static ARRAY_VALUE_TOC_VARIA: Array<string> = [ "0253", "0301", "0302", "0303", "0304", "0305", "0306", "0307", "0309", "0310", "0311", "0312", "0313", "0314", "0315", "0316", "0351", "0352", "0353", "0354", "0355", "0356", "0357", "0358", "0359", "0360", "0361", "0362", "0401", "0402", "0403", "0404", "0451", "0452", "0501", "0502", "0503", "0551", "0601", "0701", "0702", "0711", "0712", "0752", "0901", "0902", "0903", "0904", "0905", "0906", "0907", "0908", "0909", "0910", "0911", "0912", "0913", "0914", "0951", "0952", "0953", "0956", "0957", "0958", "1001", "1002", "1003", "1004", "1005", "1006", "1007", "1008", "1009", "1010", "1011", "1012", "1013", "1014", "1015", "1016", "1017", "1051", "1052", "1053", "1054", "1210", "1211", "1212", "1213", "1214", "1215", "1216", "1217", "1251", "1252", "1253", "1254", "1255", "1256", "1257", "1258", "1260", "1301", "1351"];
	// eslint-disable-next-line max-len
	static ARRAY_VALUE_TOC_FINANSIALLINE: Array<string> = [ "1101", "1102", "1103", "1104", "1105", "1106", "1107", "1108", "1109", "1110", "1111", "1112", "1151", "1152", "1153", "1154", "1155", "1156", "1157", "1158", "1159", "1160", "1202", "1203", "1204", "1208", "1212", "1217", "1251", "1252", "1253", "1254", "1258", "1260", "1401", "1402", "1403", "1404", "1405", "1406", "1407", "1408", "1409", "1410", "1411", "1412", "1413", "1414", "1415", "1416", "1451", "1452"];

	static STRING_CHART_PIE_LEGENDPOSITION: string = "below";
	static STRING_CHART_BAR_LEGENDPOSITION: string = "right";
	static STRING_FIELD_EMAIL_BRINS: string = "@brins.co.id";
	static STRING_FIELD_EMAIL_TSI_BRINS: string = "tsi@brins.co.id";
	static STRING_FIELD_EMAIL_INFO_BRINS: string = "callcenter@brins.co.id";
	static STRING_FIELD_BRANCHCODE_HEADQUARTER: string = "115";
	static STRING_FIELD_BRANCHCODE_HEADOFFICE: string = "100";
	static STRING_FIELD_BRANCHNAME_HEADOFFICE: string = "KANTOR PUSAT";
	static STRING_FIELD_MODELVEHICLEBYVEHICLEBRAND: string = "ADI";
	static ARRAY_STRING_SUMINSURED_FOR_CLAIMTHIRDPARTY: Array<string> = ["D06", "P13", "T02"];
	static STRING_COB_MOTOVEHICLE: string = "02";

	static STRING_SUMINSURED_DRIVER: string = "D06";
	static STRING_SUMINSURED_PASSENGER: string = "P13";

	//#endregion


	//#region IMAGE

	static STRING_FORMAT_IMAGE_JPG: string = "jpg";
	static STRING_FORMAT_COMPRESS_ZIP: string = "zip";
	static STRING_FORMAT_DOCUMENT_PDF: string = "pdf";
	static STRING_FORMAT_COMPRESS_RAR: string = "rar";
	static STRING_FORMAT_IMAGE_PNG: string = "png";
	static STRING_FORMAT_IMAGE_JPEG: string = "jpeg";
	static STRING_FORMAT_DOCUMENT_WORD: string = "doc";
	static STRING_FORMAT_DOCUMENT_WORDX: string = "docx";
	static STRING_FORMAT_DOCUMENT_EXCEL: string = "xls";
	static STRING_FORMAT_DOCUMENT_EXCELX: string = "xlsx";
	static STRING_FORMAT_DOCUMENT_CSV: string = "csv";
	static STRING_FORMAT_DOCUMENT_MP4: string = "mp4";

	static ARRAY_VALUE_FORMAT: Array<string> = [
		StringConstant.STRING_FORMAT_IMAGE_JPG, StringConstant.STRING_FORMAT_DOCUMENT_PDF, StringConstant.STRING_FORMAT_IMAGE_PNG, StringConstant.STRING_FORMAT_IMAGE_JPEG, StringConstant.STRING_FORMAT_DOCUMENT_WORD, StringConstant.STRING_FORMAT_DOCUMENT_WORDX, StringConstant.STRING_FORMAT_DOCUMENT_EXCEL, StringConstant.STRING_FORMAT_DOCUMENT_EXCELX, StringConstant.STRING_FORMAT_DOCUMENT_CSV];

	static ARRAY_VALUE_IMAGE: Array<string> = [
		StringConstant.STRING_FORMAT_IMAGE_JPG, StringConstant.STRING_FORMAT_IMAGE_PNG, StringConstant.STRING_FORMAT_IMAGE_JPEG];

	//#endregion


	//#region CONVERTION

	static STRING_HASH_SHA256: string = "SHA-256";

	//#endregion


	//#region HTTP

	static STRING_HTTP_HEADER_AUTHORIZEDKEY: string = "AuthorizedKey";

	//#endregion


	//#region MODEL

	static STRING_MODULE_CLAIMPROPOSAL: string = "Claim Proposal.";
	static STRING_MODULE_TICKETNUMBER: string = "Ticket.";
	static STRING_MODULE_CLAIMPROPOSAL_ANO_NOTFOUND: string = "Policy general not found.";
	static STRING_MODULE_CLAIMPROPOSAL_INSERTDATA_SUCCESS: string = "Insert data success.";
	static STRING_MODULE_CLAIMPROPOSAL_VERIFYDATA_SUCCESS: string = "Verify data success.";
	static STRING_MODULE_CLAIMPROPOSAL_SELECT_HISTORYPOLICY: string = "History policy cannot be empty.";

	//#endregion


	//#region CLAIM PROPOSAL MESSAGE CONTENT

	static STRING_MESSAGECONTENT_CLAIMPROPOSAL_LOCATIONISBLOCKED = "Location is blocked.";

	//#endregion


	//#region ROUTING

	static STRING_ROUTING_KEY_TOKEN: string = "token";
	static STRING_ROUTING_POLICYNUMBER: string = "policynumber";
	static STRING_ROUTING_CLAIMNUMBER: string = "claimnumber";
	static STRING_ROUTING_PAYMENTANO: string = "ano";
	static STRING_ROUTING_PUBLIC: string = "public";
	static STRING_ROUTING_TICKET: string = "ticket";
	static STRING_ROUTING_SIGNIN: string = "signin";

	//#endregion


	//#region LOCAL STORAGE

	static STRING_LOCALSTORAGE_KEY_HANDSHAKEMODEL: string = "HandshakeModel";
	static STRING_LOCALSTORAGE_KEY_USERSESSION: string = "UserSession";
	static STRING_LOCALSTORAGE_KEY_TABLEMODELFORAPPROVALASRILIST: string = "TableModelForApprovalAsriList";
	static STRING_LOCALSTORAGE_KEY_TABLEMODELFORAPPROVALOTOLIST: string = "TableModelForApprovalOtoList";
	static STRING_LOCALSTORAGE_KEY_TABLEMODELFORPURCHASEASRILIST: string = "TableModelForPurchaseAsriList";
	static STRING_LOCALSTORAGE_KEY_TABLEMODELFORPURCHASEOTOLIST: string = "TableModelForPurchaseOtoList";
	static STRING_LOCALSTORAGE_KEY_TABLEMODELFORPURCHASEPERSONALACCIDENTLIST: string = "TableModelForPurchasePersonalAccidentList";
	// static STRING_LOCALSTORAGE_KEY_TABLEMODELFORPACKAGELIST: string = "TableModelForPackageList";
	// static STRING_LOCALSTORAGE_KEY_TABLEMODELFORPACKAGEOPTIONLIST: string = "TableModelForPackageOptionList";
	// static STRING_LOCALSTORAGE_KEY_TABLEMODELFORCHATREPORTLIST: string = "TableModelForChatReportList";
	// static STRING_LOCALSTORAGE_KEY_TABLEMODELFORBANREPORTLIST: string = "TableModelForBanReportList";
	// static STRING_LOCALSTORAGE_KEY_TABLEMODELFORADVERTISINGDAILYLIST: string = "TableModelForAdvertisingDailyList";
	// static STRING_LOCALSTORAGE_KEY_TABLEMODELFORADVERTISINGCARDLIST: string = "TableModelForAdvertisingCardList";
	// static STRING_LOCALSTORAGE_KEY_TABLEMODELFORACCOUNTLIST: string = "TableModelForAccountList";
	// static STRING_LOCALSTORAGE_KEY_TABLEMODELFORACCOUNTOPTIONLIST: string = "TableModelForAccountOptionList";
	// static STRING_LOCALSTORAGE_KEY_TABLEMODELFORSEEKERAPPROVALLIST: string = "TableModelForSeekerApprovalList";
	// static STRING_LOCALSTORAGE_KEY_TABLEMODELFORSEEKERPROFILEREPORTLIST: string = "TableModelForSeekerProfileReportList";

	//#endregion


	//#region CHECK URL

	static STRING_URL_PATH_INSERT: string = "insert";

	//#endregion

	//#region LENGTH

	static NUMBER_POLICY_ACCEPTANCENUMBER_LENGTHMINIMUM: number = 8;
	static NUMBER_POLICY_ACCEPTANCENUMBER_LENGTHMAXIMUM: number = 9;

	//#endregion


	//#region IFRAME

	static STRING_IFRAME_EMBEDVIDEO_AUTOPLAY: string = "?autoplay=1";
	static STRING_IFRAME_EMBEDVIDEO_PAUSE: string = "?enablejsapi=1";

	//#endregion


	//#region VALIDATION

	static STRING_VALIDATION_TICKET_EMPTY_NAME: string = "Nama tidak boleh kosong!";
	static STRING_VALIDATION_TICKET_EMPTY_EMAIL: string = "Email tidak boleh kosong!";
	static STRING_VALIDATION_TICKET_INCORRECT_EMAIL: string = "Format Email tidak sesuai!";
	static STRING_VALIDATION_TICKET_EMPTY_PHONENUMBER: string = "Nomor telepon boleh kosong!";
	static STRING_VALIDATION_TICKET_EMPTY_TITLE: string = "Judul tidak boleh kosong!";
	static STRING_VALIDATION_TICKET_EMPTY_TOPIC: string = "Topik tidak boleh kosong!";
	static STRING_VALIDATION_TICKET_EMPTY_DESCRIPTION: string = "Deskripsi tidak boleh kosong!";

	static STRING_VALIDATION_TICKET_FULLY_FILLED: string = "Tiket sudah terpenuhi!";

	//#endregion

	//#region Term and Conditions

	static STRING_INFORMATION_TERM_CONDITION_1: string = "Segala informasi yang diberikan benar, berlaku, dan sah.";
	static STRING_INFORMATION_TERM_CONDITION_2: string = "Setuju dengan SPPA (Surat Permohonan Penutupan Asuransi) Elektronik yang terdapat dalam aplikasi ini.";
	static STRING_INFORMATION_TERM_CONDITION_3: string = "Data yang diisi dalam aplikasi ini adalah data yang sebenarnya. Apabila dikemudian hari terdapat keterangan/informasi yang bertentangan dengan keadaan sebenarnya tetapi tidak dinyatakan /dikemukakan dalam Surat Permohonan Penutupan Asuransi ini, dimana dalam hal apabila keterangan/informasi yang sebenarnya diberikan sejak awal maka pertanggungan Asuransi tidak akan ditutup atau dipertanggungkan dengan syarat dan ketentuan yang sama, maka Penanggung berhak untuk membatalkan Polis dan / atau menolak klaim yang diajukan.";
	static STRING_INFORMATION_TERM_CONDITION_4: string = "Menyetujui Pertanggungan ini mulai berlaku sejak masa pertanggungan yang tercantum dalam polis yang diterbitkan oleh Penanggung.";
	static STRING_INFORMATION_TERM_CONDITION_5: string = "Dengan mengisi dan melengkapi data diri yang dipersyaratkan untuk mengikuti program asuransi ini, saya mengajukan permohonan asuransi kepada BRINS (Penanggung) yang mana dalam hal ini saya bertindak sebagai calon pemegang polis dan menyatakan bahwa: Saya telah memenuhi syarat dan ketentuan yang dipersyaratkan oleh Penanggung. Semua data, pernyataan dan jawaban yang saya berikan untuk keikutsertaan saya dalam program asuransi berlaku sebagai Surat Permintaan Asuransi adalah benar dan akan menjadi dasar bagi berlakunya Kontrak Asuransi yang akan disetujui dan dikeluarkan oleh pihak Penanggung. Apabila dikemudian hari terdapat keterangan / informasi yang bertentangan dengan keadaan yang sebenarnya tetapi tidak dinyatakan/dikemukakan dalam Surat Permintaan Asuransi ini, dimana dalam hal apabila keterangan / informasi yang sebenarnya.";
	static STRING_INFORMATION_TERM_CONDITION_6: string = "Saya telah memenuhi syarat dan ketentuan yang yang dipersyaratkan oleh Penanggung. Semua data, pernyataan dan jawaban yang saya berikan untuk keikutsertaan saya dalam program asuransi berlaku sebagai Surat Permintaan Asuransi adalah benar dan akan menjadi dasar bagi berlakunya kontrak Asuransi yang akan disetujui dan dikeluarkan oleh pihak Penanggung. Apabila dikemudian hari terdapat keterangan / informasi yang bertentangan dengan keadaan sebenarnya tetapi tidak dinyatakan/dikemukakan dalam Surat Permintaan Asuransi ini, dimana dalam hal apabila keterangan / informasi sebenarnya diberikan sejak awal maka pertanggungan asuransi tidak akan ditutup atau dipertanggungkan dengan syarat dan ketentuan yang sama, maka Penanggung berhak untuk membatalkan Polis dan / atau menolak klaim yang diajukan. Menyetujui Pertanggungan ini mulai berlaku sejak masa pertanggungan yang tercantum dalam Polis yang diterbitkan oleh penanggung.";
	static STRING_INFORMATION_TERM_CONDITION_7: string = "Dengan ini saya menyatakan bahwa informasi yang saya berikan adalah benar, tepat, dan tidak menyesatkan. Saya memahami bahwa polis saya dapat dibatalkan oleh perusahaan asuransi apabila informasi yang saya sediakan ternyata salah, tidak tepat, atau menyesatkan.";
	static STRING_INFORMATION_TERM_CONDITION_8: string = "Saya dengan ini memastikan bahwa saya telah membaca, memahami, dan menyetujui manfaat manfaat, ketentuan-ketentuan, pengecualian-pengecualian, dan aspek lainnya dari polis asuransi yang saya ajukan, bahwa saya memahami bahwa pembayaran ini bukan merupakan suatu persetujuan atau secara otomatis menghasilkan persetujuan dari perusahaan asuransi untuk menjamin resiko yang diajukan, menyediakan perlindungan, atau menerbitkan polis.";
	static STRING_INFORMATION_TERM_CONDITION_9: string = "Persetujuan atas pengajuan polis asuransi yang Anda lakukan melalui website BRINS, sepenuhnya merupakan wewenang Perusahaan Asuransi. Perusahaan Asuransi merupakan penanggung atas risiko yang diasuransikan dan merupakan pihak yang berwenang memutuskan untuk menerima atau menolak suatu pertanggungan.";
	static STRING_INFORMATION_TERM_CONDITION_10: string = "Pihak BRI Insurance sewaktu-waktu dapat melakukan survey jika diperlukan untuk kepentingan verifikasi data.";
	static STRING_INFORMATION_TERM_CONDITION_11: string = "Pastikan bahwa Anda telah memenuhi kelengkapan dokumen yang disyaratkan oleh Peraturan Otoritas Jasa Keuangan No. 12/POJK.01/2017 Tentang Penerapan Program Anti Pencucian Uang dan Pencegahan.";
	static STRING_INFORMATION_TERM_CONDITION_12: string = "Saya/Kami dengan ini mengizinkan Penanggung untuk menggunakan data atau informasi mengenai Saya/Kami sebagaimana tertera dalam SPPA ini untuk kepentingan BRI Group.";
	static STRING_INFORMATION_TERM_CONDITION_13: string = "Segala informasi yang diberikan benar, berlaku, dan Tertanggung wajib untuk mengunggah dokumen pendukung dalam pengajuan asuransi sesuai dengan ketentuan yang diberlaku. Jika dokumen yang diunggah tidak sesuai, maka BRINS berhak untuk menolak apabila tertanggung mengajukan klaim.";
	static STRING_TICKETTOPICTYPE_INFORMATION: string = "Informasi";
	static STRING_INFORMATION_TITLE: string = "Syarat dan Ketentuan";

	//#endregion


	//#region Product OTO

	static STRING_TITLE_ASRI: string = "Asri";
	static STRING_TITLE_ASRI_CAPITAL: string = "ASRI";
	static STRING_TITLE_ASRIDAILY: string = "ASRI harian";
	static STRING_TITLE_ASRIMONTHLY: string = "ASRI bulanan";
	static STRING_INFORMATION_PARAGRAPH_ASRI: string = "Lindungi properti dan tempat tinggal Anda dari kehilangan dan kerusakan yang disebabkan oleh risiko yang tidak terduga (yaitu: kebakaran, petir, ledakan, dampak pesawat jatuh dan asap).";
	static STRING_INFORMATION_PARAGRAPH_ASRIMONTHLY = "Melindungi rumah Anda serta yang ada didalamnya dari kehilangan dan kerusakan yang disebebkan oleh risiko yang tidak terduga. (yaitu: kebakaran, petir, ledakan, kejatuhan pesawat, asap serta perluasannya) dengan periode perlindungan bulanan.";

	//#endregion


	//#region Product OTO

	static STRING_TITLE_OTO: string = "Oto";
	static STRING_TITLE_OTO_CAPITAL: string = "OTO";
	static STRING_TITLE_OTODAILY: string = "OTO harian";
	static STRING_TITLE_OTOMONTHLY: string = "OTO bulanan";
	static STRING_TITLE_OTOYEARLY: string = "OTO tahunan";
	static STRING_INFORMATION_TITLE_OTO: string = "Kaca depan mobil lebih besar dari kaca spion";
	static STRING_INFORMATION_DESCRIPTION_OTO: string = "Artinya masa depan lebih penting dari pada masa lalu";
	static STRING_INFORMATION_PARAGRAPH_OTO: string = "Melindungi kendaraan bermotor roda 4 (empat) Anda dari kehilangan dan kerusakan yang disebabkan oleh risiko tidak terduga (yaitu: tabrakan, benturan, terbalik, tergilincir atau terperosok, perbuatan jahat, pencurian yang diikuti dengan kekerasan atau ancaman).";
	static STRING_INFORMATION_PARAGRAPH_OTODAILY: string = "Melindungi kendaraan bermotor roda 4 (empat) Anda dari kerusakan yang disebabkan oleh risiko tidak terduga (yaitu: tabrakan, benturan, terbalik, tergilincir atau terperosok dan perbuatan jahat) dengan periode perlindungan harian.";
	static STRING_INFORMATION_PARAGRAPH_OTOMONTHLY: string = "Melindungi kendaraan bermotor roda 4 (empat) Anda dari kerusakan yang disebabkan oleh risiko tidak terduga (yaitu: tabrakan, benturan, terbalik, tergilincir atau terperosok, perbuatan jahat, pencurian yang diikuti dengan kekerasan atau ancaman) dengan periode perlindungan bulanan.";
	static STRING_INFORMATION_PARAGRAPH_OTODAILY125_1: string = "Maksimum nilai ganti rugi sebagian (Komprehensif) dengan nilai pertanggungan Rp 125.000.000";
	static STRING_INFORMATION_PARAGRAPH_OTODAILY125_2: string = "Pengajuan nilai ganti rugi sebagian maksimal 1 kali selama masa asuransi";
	static STRING_INFORMATION_PARAGRAPH_OTODAILY125_3: string = "Pertanggungan Utama: Komprehensif";
	static STRING_INFORMATION_PARAGRAPH_OTODAILY200_1: string = "Maksimum nilai ganti rugi sebagian (Komprehensif) dengan nilai pertanggungan Rp 200.000.000";
	static STRING_INFORMATION_PARAGRAPH_OTODAILY200_2: string = "Pengajuan nilai ganti rugi sebagian maksimal 1 kali selama masa asuransi";
	static STRING_INFORMATION_PARAGRAPH_OTODAILY200_3: string = "Pertanggungan Utama: Komprehensif";
	static STRING_INFORMATION_PARAGRAPH_OTODAILY400_1: string = "Maksimum nilai ganti rugi sebagian (Komprehensif) dengan nilai pertanggungan Rp 400.000.000";
	static STRING_INFORMATION_PARAGRAPH_OTODAILY400_2: string = "Pengajuan nilai ganti rugi sebagian maksimal 1 kali selama masa asuransi";
	static STRING_INFORMATION_PARAGRAPH_OTODAILY400_3: string = "Pertanggungan Utama: Komprehensif";

	static STRING_INFORMATION_OTO_CUSTOM_THIRDPARTYLIABILITY: string = "Tanggung jawab hukum pihak ketiga";
	static STRING_INFORMATION_OTO_CUSTOM_FLOODTYPHOONHURRICANEHAILLANDSLIDE: string = "Banjir, angin topan, badai, hujan es, tanah longsor";
	static STRING_INFORMATION_OTO_CUSTOM_RIOTS: string = "Kerusuhan dan huru-hara";
	static STRING_INFORMATION_OTO_CUSTOM_TERRORISMANDSABOTAGE: string = "Terorisme dan Sabotase";
	static STRING_INFORMATION_OTO_CUSTOM_EARTHQUAKESTSUNAMISVOLCANICERUPTION: string = "Gempa bumi, tsunami dan letusan gunung berapi";
	static STRING_INFORMATION_OTO_CUSTOM_DRIVERPERSONALACCIDENT: string = "Kecelakaan diri pengemudi nilai pertanggungan diri pengemudi sebesar 1 juta";
	static STRING_INFORMATION_OTO_CUSTOM_PASSENGERPERSONALACCIDENT: string = "Kecelakaan diri penumpang nilai pertanggungan diri penumpang (4 orang) sebesar 1 juta per orang";


	//#endregion


	//#region Product PA

	static STRING_TITLE_PERSONALACCIDENT: string = "Diri";
	static STRING_TITLE_PERSONALACCIDENT_CAPITAL: string = "DIRI";
	static STRING_TITLE_PERSONALACCIDENTYEARLY: string = "DIRI tahunan";
	static STRING_TITLE_PERSONALACCIDENTMONTHLY: string = "DIRI bulanan";
	static STRING_TITLE_PERSONALACCIDENTDAILY: string = "DIRI harian";
	static STRING_INFORMATION_TITLE_PERSONALACCIDENT: string = "Jarimu Pelindungmu";
	static STRING_INFORMATION_DESCRIPTION_PERSONALACCIDENT: string = "Orang yang menginginkan impiannya menjadi kenyataan adalah orang yang mampu menjaga diri sendiri dan orang lain";
	static STRING_INFORMATION_PARAGRAPH_PERSONALACCIDENT: string = "Melindungi diri Anda dari risiko tidak terduga yang diakibatkan oleh kecelakaan (yaitu: meninggal dunia, cacat tetap total atau cacat tetap sebagian dan biaya pengobatan).";
	static STRING_INFORMATION_PARAGRAPH_PERSONALACCIDENTMONTHLY: string = "Melindungi diri Anda dari risiko tidak terduga yang diakibatkan oleh kecelakaan (yaitu: meninggal dunia, cacat tetap total atau cacat tetap sebagian dan biaya pengobatan) dengan periode perlindungan bulanan.";
	static STRING_INFORMATION_PARAGRAPH_PERSONALACCIDENTDAILY: string = "Melindungi diri Anda dari risiko tidak terduga yang diakibatkan oleh kecelakaan (yaitu: meninggal dunia, cacat tetap total atau cacat tetap sebagian dan biaya pengobatan) dengan periode perlindungan harian.";
	static STRING_INFORMATION_VALUE_CRASH: string = "Nilai kecelakaan diri sebesar Rp. 100.000.000,-";


	//#endregion


	//#region Product BICYCLE

	static STRING_TITLE_BICYCLE: string = "Sepeda";
	static STRING_TITLE_BICYCLE_CAPITAL: string = "Sepeda";
	static STRING_TITLE_BICYCLEYEARLY: string = "Sepeda Tahunan";
	static STRING_TITLE_BICYCLEMONTHLY: string = "Sepeda Bulanan";
	static STRING_TITLE_BICYCLEDAILY: string = "Sepeda Harian";
	static STRING_INFORMATION_TITLE_BICYCLE: string = "Gowes bebas cemas";
	static STRING_INFORMATION_DESCRIPTION_BICYCLE: string = "Setiap perputaran roda siap melaju tanpa batas";
	static STRING_INFORMATION_PARAGRAPH_BICYCLE: string = "BRINS Sepeda adalah perlindungan yang memberikan ganti rugi kepada tertanggung terhadap kerugian atas dan/atau kerusakan pada sepeda dan/atau kepentingan yang dipertanggungkan.";

	//#endregion


	//#region PAYMENT

	static STRING_TITLE_PAYMENT: string = "Payment";
	static STRING_INFORMATION_DESCRIPTION_PAYMENT: string = "Lorem ipsum dolor sit, amet consectetur adipisicing elit. Amet magnam blanditiis, exercitationem excepturi vel quis aliquam molestiae suscipit. Adipisci exercitationem dolor neque in. Dolorum quam tenetur hic saepe reprehenderit quis?";
	static STRING_INFORMATION_SUBTITLE_PAYMENT: string = "Informasi Pembelian";
	static STRING_INFORMATION_VIRTUALACCOUNT_PAYMENT: string = "Nomor Virtual Akun";
	static STRING_INFORMATION_EXPIRED_PAYMENT: string = "Pembayaran Kedaluwarsa Pada";
	static STRING_INFORMATION_TOTAL_PAYMENT: string = "Total";


	//#endregion


	//#region ASMIK KTU

	static STRING_TITLE_DAMAGEOFBUSINESSPLACE = "ASMIK KTU";
	static STRING_TITLE_DAMAGEOFBUSINESSPLACE_CAPITAL = "Asuransi Mikro Kerusakan Tempat Usaha";
	static STRING_INFORMATION_TITLE_DAMAGEOFBUSINESSPLACE: string = "Dagangan laris senyum manis";
	static STRING_INFORMATION_DESCRIPTION_DAMAGEOFBUSINESSPLACE: string = "Karena perlindungan ekonomis yang ga bikin dompet tipis";
	static STRING_INFORMATION_PARAGRAPH_DAMAGEOFBUSINESSPLACE: string = "Memberikan santunan berupa uang apabila peserta asuransi mengalami kerusakan tempat usaha akibat terjadinya salah satu atau beberapa musibah yang tercantum dalam polis.";

	//#endregion


	//#region ASMIK KTU

	static STRING_TITLE_MYHOUSE = "ASMIK Rumahku";
	static STRING_TITLE_MYHOUSE_CAPITAL = "Asuransi Mikro Rumahku";
	static STRING_INFORMATION_TITLE_MYHOUSE: string = "Sederhana namun penuh makna";
	static STRING_INFORMATION_DESCRIPTION_MYHOUSE: string = "Karena bahagia tercipta dirumah bersama keluarga";
	static STRING_INFORMATION_PARAGRAPH_MYHOUSE: string = "Memberikan proteksi terhadap bangunan tempat tinggal tertanggung atau tempat tinggal merangkap tempat usaha rusak akibat terjadinya salah satu atau beberapa musibah yang tercantum dalam polis.";

	//#endregion


	//#region ASMIK KTU

	static STRING_TITLE_MYMOTORCYCLE = "ASMIK Motorku";
	static STRING_TITLE_MYMOTORCYCLE_CAPITAL = "Asuransi Mikro Motorku";
	static STRING_INFORMATION_TITLE_MYMOTORCYCLE: string = "Ngacir tanpa khawatir";
	static STRING_INFORMATION_DESCRIPTION_MYMOTORCYCLE: string = "Lewati rintangan di setiap perjalanan";
	static STRING_INFORMATION_PARAGRAPH_MYMOTORCYCLE: string = "Memberikan santunan kepada tertanggung dengan manfaat santunan atas kerugian motor, santunan terhadap peserta yang mengendarai motor dan santunan tanggung jawab pihak ketiga, yang menyebabkan pihak lain meninggal dunia/cacat tetap.";

	//#endregion


	//#region WEBSITE

	static STRING_WEBSITE_BRINS_BRINSAMAN = "https://www.brinsaman.com/";

	//#endregion


	//#region CONTENT

	static STRING_CONTENT_TITLE_SEEALLPRODUCT = "Lihat Semua Produk";
	static STRING_CONTENT_TITLE_PROMOLIST = "Promo Berlangsung";
	static STRING_CONTENT_TITLE_MOREPROMO = "Promo Lainnya";
	static STRING_CONTENT_TITLE_PROMOCODE = "Kode Promo";
	static STRING_CONTENT_TITLE_DESCRIPTION = "Deskripsi";
	static STRING_CONTENT_TITLE_TERMSANDCONDITION = "Syarat dan Ketentuan";
	static STRING_CONTENT_TITLE_ACTIVEDATE = "Tanggal Berlaku";

	static STRING_CONTENT_SUBTITTLE_YEARLY = "Tahunan";
	static STRING_CONTENT_SUBTITTLE_MONTHLY = "Bulanan";
	static STRING_CONTENT_SUBTITTLE_DAILY = "Harian";

	static STRING_CONTENT_SUBTITTLE_YEARLY_LOWERCASE = "tahunan";
	static STRING_CONTENT_SUBTITTLE_MONTHLY_LOWERCASE = "bulanan";
	static STRING_CONTENT_SUBTITTLE_DAILY_LOWERCASE = "harian";
	static STRING_CONTENT_SUBTITTLE_DETAILPROMO = "Lihat detail promo";


	static STRING_CONTENT_CART_DRAFT = "Draft";
	static STRING_CONTENT_CART_PENDING = "Menunggu Pembayaran";

	//#endregion


	//#region INFORMATION

	static STRING_INFORMATION_BUTTON_MORE = "Selengkapnya";
	static STRING_INFORMATION_TITTLE_PRODUCT = "Produk";
	static STRING_INFORMATION_TITTLE_PROMOTION = "Promo";
	static STRING_INFORMATION_TITLE_PARTNER = "Mitra";
	static STRING_INFORMATION_TITTLE_MOBILEAPPLICATION = "Aplikasi Mobile";

	static STRING_APPLICATION_BRINS = "BRINS";
	static STRING_APPLICATION_MPM = "MPM";

	static STRING_APPLICATION_BRINSMOBILE = "mobile";
	static STRING_INFORMATION_DESCRIPTION_BRINSMOBILE = "BRINSmobile hadir sebagai layanan one-stop asuransi mulai dari pembelian sampai dengan klaim. Dapatkan berbagai proteksi yang dapat melindungi diri dan Aset Anda dengan mudah dengan sesuai dengan kebutuhan dan periode yang fleksibel.";

	static STRING_APPLICATION_BRINSAGENT = "agent";
	static STRING_INFORMATION_DESCRIPTION_BRINSAGENT = "Aplikasi berbasis mobile untuk pembelian secara langsung produk asuransi BRINS oleh Agen BRILink, menjual produk asuransi BRINS (sebagai agen pemasar), serta mengakses informasi penting seputar BRINS lainnya secara cepat dan mudah.";

	static STRING_APPLICATION_MPMMOBILE = "Mobile";
	static STRING_INFORMATION_DESCRIPTION_MPMMOBILE = "MPM Mobile (Whitelabel PNM) merupakan layanan penjualan produk asuransi BRI Insurance khusus untuk agen MPM melalui aplikasi mobile. MPM Mobile menyediakan perlindungan diri dan aset Anda dengan prinsip Syariah.";

	static STRING_APPLICATION_AMS = "AMS";
	static STRING_INFORMATION_DESCRIPTION_AMS = "Agency Management System digunakan Agen Resmi yang sudah melakukan kerjasama dengan BRINS. Aplikasi memiliki beragam keunggulan antara lain fleksibel dan mudah digunakan, serta kemudahan monitoring proses bisnis secara real time.";

	static STRING_INFORMATION_CART_TITTLE = "Keranjang";
	static STRING_INFORMATION_MYORDER_TITTLE = "Pesanan Saya";
	static STRING_INFORMATION_PENDINGPAYMENT_TITTLE = "Menunggu Pembayaran";

	static STRING_INFORMATION_POLICY_TITLE = "Polis";
	static STRING_INFORMATION_POLICY_STATUS_ACTIVE = "Aktif";
	static STRING_INFORMATION_POLICY_STATUS_EXPIREDSOON = "Segera Berakhir";
	static STRING_INFORMATION_POLICY_STATUS_NONACTIVE = "Tidak Aktif";

	static STRING_INFORMATION_PAYMENT_TITLE = "Pembayaran";

	static STRING_WINDOWOPEN_TARGET_BLANK = "_blank";

	//#endregion


	//#region URL

	static STRING_URL_APPLICATION_GOOGLEPLAY_MPMMOBILE = "https://play.google.com/store/apps/details?id=com.mpm.brinsaman.wl";
	static STRING_URL_APPLICATION_GOOGLEPLAY_BRINSMOBILE = "https://play.google.com/store/apps/details?id=customer.brinsmobile.brins.my.com.infoconnect";
	static STRING_URL_APPLICATION_GOOGLEPLAY_BRINSAGENT = "https://play.google.com/store/apps/details?id=com.asmik.brins";
	static STRING_URL_APPLICATION_APPSTORE_MPMMOBILE = "https://apps.apple.com/id/app/mpm-mobile/id1562620062";
	static STRING_URL_APPLICATION_APPSTORE_BRINSMOBILE = "https://apps.apple.com/id/app/brins-mobile/id1436128678";


	//#endregion


	//#region PAYMENT

	// <!--PAYMENT METHOD-->
	static STRING_PAYMENT_METHOD_TITLE = "Cara Pembayaran";
	static STRING_PAYMENT_METHOD_ATM = "ATM";
	static STRING_PAYMENT_METHOD_INTERNETBANKING = "Internet Banking";
	static STRING_PAYMENT_METHOD_BRIMO = "BRImo";
	static STRING_PAYMENT_METHOD_MOBILEBANKING = "Mobile Banking";
	static STRING_PAYMENT_METHOD_KLIKBCA = "Klik BCA";

	//

	// <!--HOW TO PAYMENT QRIS-->
	static STRING_CONTENT_PAYMENT_STEP1_QRIS = "Buka aplikasi aplikasi dompet elektronik Anda.";
	static STRING_CONTENT_PAYMENT_STEP2_QRIS = "Scan kode QR di layar Anda.";
	static STRING_CONTENT_PAYMENT_STEP3_QRIS = "Konfirmasi pembayaran pada aplikasi.";
	static STRING_CONTENT_PAYMENT_STEP4_QRIS = "Pembayaran selesai.";

	// <!--HOW TO PAYMENT ATM BRI-->
	static STRING_CONTENT_PAYMENT_TITLE_ATMBRI = "Cara pembayaran ATM BRI";
	static STRING_CONTENT_PAYMENT_STEP1_ATMBRI = "Nasabah melakukan pembayaran melalui ATM.";
	static STRING_CONTENT_PAYMENT_STEP2_ATMBRI = "Pilih Menu Transaksi Lain.";
	static STRING_CONTENT_PAYMENT_STEP3_ATMBRI = "Pilih Menu Pembayaran.";
	static STRING_CONTENT_PAYMENT_STEP4_ATMBRI = "Pilih Menu Lainnya.";
	static STRING_CONTENT_PAYMENT_STEP5_ATMBRI = "Pilih Menu BRIVA.";
	static STRING_CONTENT_PAYMENT_STEP6_ATMBRI = "Masukan 15 digit Nomor Virtual Account BRIVA.";
	static STRING_CONTENT_PAYMENT_STEP7_ATMBRI = "Masukan nominal yang ingin Anda transfer.";
	static STRING_CONTENT_PAYMENT_STEP8_ATMBRI = "Proses pembayaran (Ya/Tidak).";

	// <!--HOW TO PAYMENT BRIMO-->
	static STRING_CONTENT_PAYMENT_TITLE_BRIMO = "Cara pembayaran BRIMO";
	static STRING_CONTENT_PAYMENT_STEP1_BRIMO = "Login pada aplikasi BRIMO.";
	static STRING_CONTENT_PAYMENT_STEP2_BRIMO = "Pilih fitur lainnya.";
	static STRING_CONTENT_PAYMENT_STEP3_BRIMO = "Klik Asuransi.";
	static STRING_CONTENT_PAYMENT_STEP4_BRIMO = "Klik pembayaran baru.";
	static STRING_CONTENT_PAYMENT_STEP5_BRIMO = "Pilih BRI Insurance pada jenis asuransi.";
	static STRING_CONTENT_PAYMENT_STEP6_BRIMO = "Masukan nomor virtual account BRIVA.";
	static STRING_CONTENT_PAYMENT_STEP7_BRIMO = "Pilih sumber dana yang akan digunakan untuk pembayaran.";
	static STRING_CONTENT_PAYMENT_STEP8_BRIMO = "Klik bayar untuk memproses pembayaran.";

	// <!--HOW TO PAYMENT ATM BNI-->
	static STRING_CONTENT_PAYMENT_TITLE_ATMBNI = "Cara pembayaran ATM BNI";
	static STRING_CONTENT_PAYMENT_STEP1_ATMBNI = "Pilih Lainnya di menu utama.";
	static STRING_CONTENT_PAYMENT_STEP2_ATMBNI = "Pilih Transfer.";
	static STRING_CONTENT_PAYMENT_STEP3_ATMBNI = "Pilih Dari Rekening Tabungan.";
	static STRING_CONTENT_PAYMENT_STEP4_ATMBNI = "Pilih Ke Rekening BNI.";
	static STRING_CONTENT_PAYMENT_STEP5_ATMBNI = "Masukkan Nomor Rekening Pembayaran.";
	static STRING_CONTENT_PAYMENT_STEP6_ATMBNI = "Masukkan jumlah yang harus dibayar.";
	static STRING_CONTENT_PAYMENT_STEP7_ATMBNI = "Konfirmasi pembayaran.";
	static STRING_CONTENT_PAYMENT_STEP8_ATMBNI = "Pembayaran berhasil.";

	// <!--HOW TO PAYMENT INTERNET BNI-->
	static STRING_CONTENT_PAYMENT_TITLE_INTERNETBNI = "Cara pembayaran Internet BNI";
	static STRING_CONTENT_PAYMENT_STEP1_INTERNETBNI = "Klik Transaksi > Info Administrasi Transfer.";
	static STRING_CONTENT_PAYMENT_STEP2_INTERNETBNI = "Klik Pilih Akun Tujuan.";
	static STRING_CONTENT_PAYMENT_STEP3_INTERNETBNI = "Masukkan Info Akun > Konfirmasi.";
	static STRING_CONTENT_PAYMENT_STEP4_INTERNETBNI = "Klik Transfer > Transfer ke akun BNI.";
	static STRING_CONTENT_PAYMENT_STEP5_INTERNETBNI = "Masukkan detail pembayaran > Konfirmasi.";
	static STRING_CONTENT_PAYMENT_STEP6_INTERNETBNI = "Pembayaran berhasil.";

	// <!--HOW TO PAYMENT MOBILE BNI-->
	static STRING_CONTENT_PAYMENT_TITLE_MOBILEBNI = "Cara pembayaran Mobile BNI";
	static STRING_CONTENT_PAYMENT_STEP1_MOBILEBNI = "Pilih menu Transfer.";
	static STRING_CONTENT_PAYMENT_STEP2_MOBILEBNI = "Pilih menu Virtual Account Billing.";
	static STRING_CONTENT_PAYMENT_STEP3_MOBILEBNI = "Pilih rekening debit.";
	static STRING_CONTENT_PAYMENT_STEP4_MOBILEBNI = "Masukkan nomor Virtual Account Anda.";
	static STRING_CONTENT_PAYMENT_STEP5_MOBILEBNI = "Konfirmasi transaksi.";
	static STRING_CONTENT_PAYMENT_STEP6_MOBILEBNI = "Pembayaran berhasil.";

	// <!--HOW TO PAYMENT ATM BCA-->
	static STRING_CONTENT_PAYMENT_TITLE_ATMBCA = "Cara pembayaran ATM BCA";
	static STRING_CONTENT_PAYMENT_STEP1_ATMBCA = "Pilih Transaksi lainnya pada menu utama.";
	static STRING_CONTENT_PAYMENT_STEP2_ATMBCA = "Klik Transfer.";
	static STRING_CONTENT_PAYMENT_STEP3_ATMBCA = "Klik BCA Virtual Account.";
	static STRING_CONTENT_PAYMENT_STEP4_ATMBCA = "Masukkan Nomor Virtual Account BCA.";
	static STRING_CONTENT_PAYMENT_STEP5_ATMBCA = "Masukkan jumlah yang harus dibayar lalu konfirmasi pembayaran.";
	static STRING_CONTENT_PAYMENT_STEP6_ATMBCA = "Pembayaran berhasil.";

	// <!--HOW TO PAYMENT KLIK BCA-->
	static STRING_CONTENT_PAYMENT_TITLE_KLIKBCA = "Cara pembayaran Klik BCA";
	static STRING_CONTENT_PAYMENT_STEP1_KLIKBCA = "Pilih menu Transfer.";
	static STRING_CONTENT_PAYMENT_STEP2_KLIKBCA = "Pilih Transfer ke BCA Virtual Account.";
	static STRING_CONTENT_PAYMENT_STEP3_KLIKBCA = "Masukkan Nomor Virtual Account BCA.";
	static STRING_CONTENT_PAYMENT_STEP4_KLIKBCA = "Masukkan jumlah yang harus dibayar.";
	static STRING_CONTENT_PAYMENT_STEP5_KLIKBCA = "Konfirmasi pembayaran.";
	static STRING_CONTENT_PAYMENT_STEP6_KLIKBCA = "Pembayaran berhasil.";

	// <!--HOW TO PAYMENT MOBILE BCA-->
	static STRING_CONTENT_PAYMENT_TITLE_MOBILEBCA = "Cara pembayaran Mobile BCA";
	static STRING_CONTENT_PAYMENT_STEP1_MOBILEBCA = "Pilih m-Transfer.";
	static STRING_CONTENT_PAYMENT_STEP2_MOBILEBCA = "Pilih BCA Virtual Account.";
	static STRING_CONTENT_PAYMENT_STEP3_MOBILEBCA = "Masukkan Nomor Virtual Account BCA.";
	static STRING_CONTENT_PAYMENT_STEP4_MOBILEBCA = "Masukkan jumlah yang harus dibayar.";
	static STRING_CONTENT_PAYMENT_STEP5_MOBILEBCA = "Konfirmasi pembayaran.";
	static STRING_CONTENT_PAYMENT_STEP6_MOBILEBCA = "Pembayaran berhasil.";

	// <!--HOW TO PAYMENT MANDIRI-->
	static STRING_CONTENT_PAYMENT_TITLE_ATMMANDIRI = "Cara pembayaran ATM BCA";
	static STRING_CONTENT_PAYMENT_STEP1_ATMMANDIRI = "Pada menu utama, pilih Bayar/Beli.";
	static STRING_CONTENT_PAYMENT_STEP2_ATMMANDIRI = "Pilih lainnya.";
	static STRING_CONTENT_PAYMENT_STEP3_ATMMANDIRI = "Pilih Multi Payment.";
	static STRING_CONTENT_PAYMENT_STEP4_ATMMANDIRI = "Masukkan 70012 (kode perusahaan midtrans).";
	static STRING_CONTENT_PAYMENT_STEP5_ATMMANDIRI = "Masukkan kode pembayaran, lalu konfirmasi.";
	static STRING_CONTENT_PAYMENT_STEP6_ATMMANDIRI = "Pembayaran berhasil.";

	// <!--HOW TO PAYMENT INTERNET MANDIRI-->
	static STRING_CONTENT_PAYMENT_TITLE_INTERNETMANDIRI = "Cara pembayaran Internet BCA";
	static STRING_CONTENT_PAYMENT_STEP1_INTERNETMANDIRI = "Pada menu utama, pilih Pembayaran.";
	static STRING_CONTENT_PAYMENT_STEP2_INTERNETMANDIRI = "Pilih Multi Payment.";
	static STRING_CONTENT_PAYMENT_STEP3_INTERNETMANDIRI = "Pilih dari akun.";
	static STRING_CONTENT_PAYMENT_STEP4_INTERNETMANDIRI = "Pilih midtrans dalam formulir penyedia layanan.";
	static STRING_CONTENT_PAYMENT_STEP5_INTERNETMANDIRI = "Masukkan kode pembayaran, lalu konfirmasi.";
	static STRING_CONTENT_PAYMENT_STEP6_INTERNETMANDIRI = "Pembayaran berhasil.";

	// <!--HOW TO PAYMENT ATM PERMATA-->
	static STRING_CONTENT_PAYMENT_TITLE_ATMPERMATA = "Cara pembayaran ATM Permata";
	static STRING_CONTENT_PAYMENT_STEP1_ATMPERMATA = "Pilih Transaksi Lain pada menu utama.";
	static STRING_CONTENT_PAYMENT_STEP2_ATMPERMATA = "Klik Pembayaran.";
	static STRING_CONTENT_PAYMENT_STEP3_ATMPERMATA = "Klik Pembayaran Lain.";
	static STRING_CONTENT_PAYMENT_STEP4_ATMPERMATA = "Klik Virtual Account.";
	static STRING_CONTENT_PAYMENT_STEP5_ATMPERMATA = "Masukkan nomor virtual account dan konfirmasikan.";
	static STRING_CONTENT_PAYMENT_STEP6_ATMPERMATA = "Pembayaran Berhasil.";

	// <!--HOW TO PAYMENT GOPAY-->
	static STRING_CONTENT_PAYMENT_STEP1_EWALLET = "Ketuk Bayar Sekarang.";
	static STRING_CONTENT_PAYMENT_STEP2_EWALLET = "Aplikasi %1$s akan terbuka.";
	static STRING_CONTENT_PAYMENT_STEP3_EWALLET = "Pembayaran Konfrimasi di Aplikasi.";
	static STRING_CONTENT_PAYMENT_STEP4_EWALLET = "Pembayaran berhasil.";

	// <!--HOW TO PAYMENT INDOMARET-->
	static STRING_CONTENT_PAYMENT_TITLE_INDOMARET = "Cara pembayaran Indomart";
	static STRING_CONTENT_PAYMENT_STEP1_INDOMARET = "Salin kode pembayaran Anda.";
	static STRING_CONTENT_PAYMENT_STEP2_INDOMARET = "Jika Anda akan membayar di konter, pergilah ke toko Indomaret terdekat dan tunjukkan kode pembayaran Anda kepada kasir.";
	static STRING_CONTENT_PAYMENT_STEP3_INDOMARET = "Kasir akan mengkonfirmasi rincian transaksi Anda, setelah transaksi Anda berhasil.";
	static STRING_CONTENT_PAYMENT_STEP4_INDOMARET = "Harap simpan tanda terima pembayaran Indomaret Anda jika Anda memerlukan bantuan lebih lanjut melalui dukungan.";

	// <!--HOW TO PAYMENT ALFAGROUP-->
	static STRING_CONTENT_PAYMENT_TITLE_ALFAGROUP = "Cara pembayaran Alfa Group";
	static STRING_CONTENT_PAYMENT_STEP1_ALFAGROUP = "Salin kode pembayaran Anda.";
	static STRING_CONTENT_PAYMENT_STEP2_ALFAGROUP = "Kunjungi toko Alfamart/Alfamidi/Dan+Dan terdekat dan tunjukkan kode pembayaran ke kasir.";
	static STRING_CONTENT_PAYMENT_STEP3_ALFAGROUP = "Kasir akan mengkonfirmasi rincian transaksi Anda.";
	static STRING_CONTENT_PAYMENT_STEP4_ALFAGROUP = "Konfirmasi pembayaran Anda dengan kasir.";
	static STRING_CONTENT_PAYMENT_STEP5_ALFAGROUP = "Harap simpan tanda terima pembayaran Alfamart Anda jika Anda memerlukan bantuan lebih lanjut melalui dukungan.";

	// <!--HOW TO PAYMENT CREDIT CARD-->
	static STRING_CONTENT_PAYMENT_STEP1_CREDITCARD = "Input detail kartu kredit / debit kamu serta pilih tenor cicilan yang diinginkan. Lalu, klik bayar.";
	static STRING_CONTENT_PAYMENT_STEP2_CREDITCARD = "Selanjutnya kamu akan diarahkan ke laman OTP dari bank penerbit kartu kamu. Silakan input OTP yang kamu terima dari SMS";

	//#endregion


	//#region TABLE CONTROL

	static STRING_CONTENT_TABLECONTROL_SHOW = "Tampilkan";
	static STRING_CONTENT_TABLECONTROL_ENTRIES = "Baris";
	static STRING_CONTENT_TABLECONTROL_FIRST = "Pertama";
	static STRING_CONTENT_TABLECONTROL_LAST = "Terakhir";
	static STRING_CONTENT_TABLECONTROL_PAGE = "Halaman";
	static STRING_CONTENT_TABLECONTROL_FROM = "dari";
	static STRING_CONTENT_TABLECONTROL_WITH = "dengan";

	//#endregion

}

//#endregion