//#region IMPORT

import { Location } from "@angular/common";
import { Component, ViewChild } from "@angular/core";
import { ThemePalette } from "@angular/material/core";
import { ProgressSpinnerMode } from "@angular/material/progress-spinner";
import { ActivatedRoute, Router } from "@angular/router";
import { ENUM_RESPONSE_STATE, ENUM_PAYMENT_PROVIDER } from "src/app/constants/enum.constant";
import { ResponseCodeConstant } from "src/app/constants/responsecode.constant";
import { StringConstant } from "src/app/constants/string.constant";
import { TableModel } from "src/app/models/bases/table.model";
import { ProductGeneralModel } from "src/app/models/productgeneral.model";
import { ResponseModel } from "src/app/models/response.model";
import { ProductGeneralService } from "src/app/services/productgeneral.service";
import { SessionService } from "src/app/services/session.service";
import { BaseAuthourizeDetailComponent } from "../bases/baseauthourizedetail.component";
import { TableControlIncludeComponent } from "../includes/tablecontrol.include/tablecontrol.include.component";
import { TableControlIncludeMiniComponent } from "../includes/tablecontrolmini.include/tablecontrolmini.include.component";

//#endregion


//#region COMPONENT

@Component
(
	{
		selector: "app-cart",
		templateUrl: "./cart.component.html",
		styleUrls: ["./cart.component.sass"],
	}
)

//#endregion


//#region  CLASS

export class CartComponent extends BaseAuthourizeDetailComponent
{
	//#region DECLARATION

	@ViewChild(TableControlIncludeComponent) private _componentTableControlInclude!: TableControlIncludeComponent;
	@ViewChild(TableControlIncludeMiniComponent) private _componentTableControlMiniInclude!: TableControlIncludeMiniComponent;

	public _stringConstant = StringConstant;
	public _stringImageMainHeader: string;
	public _stringImageMainHeaderMobile: string;
	public _stringPathImageProductOtoYearly: string;
	public _stringPathImageProductOtoMonthly: string;
	public _stringPathImageProductOtoDaily: string;
	public _stringPathImageProductAsriYearly: string;
	public _stringPathImageProductAsriMonthly: string;
	public _stringPathImageProductPersonalAccidentYearly: string;
	public _stringPathImageProductPersonalAccidentMonthly: string;
	public _stringPathImageProductPersonalAccidentDaily: string;
	public _stringPathImageProductBicycleYearly: string;
	public _stringPathImageProductASMIKMyMotorcycle: string;
	public _stringPathImageProductASMIKMyHouse: string;
	public _stringPathImageProductASMIKDamageOfBusinessPlace: string;
	public _stringPathImagePaymentBRIVA: string;
	public _stringPathImagePaymentQRIS: string;
	public _stringPathImagePaymentBNI: string;
	public _stringPathImagePaymentBCA: string;
	public _stringPathImagePaymentMANDIRI: string;
	public _stringPathImagePaymentPERMATA: string;
	public _stringPathImagePaymentAlfaGroup: string;
	public _stringPathImagePaymentIndomart: string;
	public _stringPathImagePaymentCreditCard: string;

	public _modelTable: TableModel;
	public _arrayModelProductGeneral: Array<ProductGeneralModel>;

	public _booleanProductDraft: boolean;
	public _booleanProductPending: boolean;

	public _dateNow: any;
	public _colorProgressDraft: ThemePalette;
	public _colorPrimaryDraft: ThemePalette;
	public _modeProgressSpinner: ProgressSpinnerMode;
	public _numberDiameter: number;
	public _numberPrimaryMaxPercentage: number;

	//#endregion


	//#region CONSTRUCTUR

	constructor(routeActivated: ActivatedRoute, location: Location, serviceSession: SessionService, public router: Router, private _serviceProductGeneral: ProductGeneralService)
	{
		super(routeActivated, location, serviceSession, router);
		this._stringImageMainHeader = "../../../assets/photos/sakina/photo_main_header.jpg";
		this._stringImageMainHeaderMobile = "../../../assets/photos/sakina/mobileheader/photo_cart.jpg";

		this._stringPathImageProductOtoYearly = "../../../assets/icons/sakina/product/icon_oto.svg";
		this._stringPathImageProductOtoMonthly = "../../../assets/icons/sakina/product/icon_oto_custom.svg";
		this._stringPathImageProductOtoDaily = "../../../assets/icons/sakina/product/icon_oto_daily.svg";
		this._stringPathImageProductAsriYearly = "../../../assets/icons/sakina/product/icon_asri.svg";
		this._stringPathImageProductAsriMonthly = "../../../assets/icons/sakina/product/icon_asri_custom.svg";
		this._stringPathImageProductPersonalAccidentYearly = "../../../assets/icons/sakina/product/icon_diri.svg";
		this._stringPathImageProductPersonalAccidentMonthly = "../../../assets/icons/sakina/product/icon_diri_custom.svg";
		this._stringPathImageProductPersonalAccidentDaily = "../../../assets/icons/sakina/product/icon_diri_daily.svg";
		this._stringPathImageProductBicycleYearly = "../../../assets/icons/sakina/product/icon_sepeda.svg";

		this._stringPathImageProductASMIKMyMotorcycle = "../../../assets/icons/sakina/product/icon_asmik_motorku.svg";
		this._stringPathImageProductASMIKMyHouse = "../../../assets/icons/sakina/product/icon_asmik_rumahku.svg";
		this._stringPathImageProductASMIKDamageOfBusinessPlace = "../../../assets/icons/sakina/product/icon_asmik_ktu.svg";

		this._stringPathImagePaymentBRIVA = "../../../assets/logos/sakina/logo_brimo.svg";
		this._stringPathImagePaymentQRIS = "../../../assets/icons/sakina/logo_qris.svg";
		this._stringPathImagePaymentBNI = "../../../assets/icons/sakina/icon_bni.svg";
		this._stringPathImagePaymentBCA = "../../../assets/icons/sakina/icon_bca.svg";
		this._stringPathImagePaymentMANDIRI = "../../../assets/icons/sakina/icon_mandiri.svg";
		this._stringPathImagePaymentPERMATA = "../../../assets/icons/sakina/icon_permata.svg";
		this._stringPathImagePaymentAlfaGroup = "../../../assets/icons/sakina/logo_alfagroupp.svg";
		this._stringPathImagePaymentIndomart = "../../../assets/icons/sakina/logo_indomaret.svg";
		this._stringPathImagePaymentCreditCard = "../../../assets/icons/sakina/logo_indomaret.svg";

		this._modelTable = new TableModel();
		this._arrayModelProductGeneral = [];

		this._booleanProductDraft = true;
		this._booleanProductPending = false;

		this._dateNow = new Date().getTime();
		this._colorProgressDraft = "warn";
		this._colorPrimaryDraft = "primary";
		this._modeProgressSpinner = "determinate";
		this._numberPrimaryMaxPercentage = 100;
		this._numberDiameter = 100;
	}

	//#endregion


	//#region ONINIT

	ngOnInit(): void
	{
		const modelResponse: ResponseModel = this._modelUserSignIn.checkSessionSignin();
		if(modelResponse.State === ENUM_RESPONSE_STATE.Success)
		{
			this.callSelectProductDraft();
		}
		else
		{
			this._functionUserInterface.showDialogFromModelResponse(modelResponse, () => { this.signIn();});
		}
	}

	//#endregion


	//#region AFTERVIEWINIT

	ngAfterViewInit(): void
	{
		this.setViewFromTop();
	}

	//#endregion


	//#region FUNCTION

	getImageMain(): any
	{
		if(this.checkMinimumPixelForPhotoHeader())
		{
			if(this._stringImageMainHeaderMobile === null && this._stringImageMainHeaderMobile === undefined && this._stringImageMainHeaderMobile === "")
			{
				return {};
			}
			else
			{
				return {"background-image": "url(" + this._stringImageMainHeaderMobile + ")"};
			}
		}
		else
		{
			if(this._stringImageMainHeader === null && this._stringImageMainHeader === undefined && this._stringImageMainHeader === "")
			{
				return {};
			}
			else
			{
				return {"background-image": "url(" + this._stringImageMainHeader + ")"};
			}
		}
	}

	public callSelectProductDraft(): void
	{
		this._arrayModelProductGeneral = [];
		this._booleanProductDraft = true;
		this._booleanProductPending = false;
		this.callSelectProductDraftByCustomer(this);
	};

	public callSelectProductPending(): void
	{
		this._arrayModelProductGeneral = [];
		this._booleanProductDraft = false;
		this._booleanProductPending = true;
		this.callSelectProductPendingByCustomer(this);
	};

	public checkPaymentExpired(modelProductGeneral: ProductGeneralModel): boolean
	{
		let numberDateTimeDistance: number;
		let numberVATimeExpired: number;

		if(modelProductGeneral != null || modelProductGeneral !== undefined)
		{
			if((modelProductGeneral.modelVirtualAccount != null || modelProductGeneral.modelVirtualAccount !== undefined) && modelProductGeneral.modelVirtualAccount.ExpiredDate !== undefined)
			{
				numberVATimeExpired = new Date(modelProductGeneral.modelVirtualAccount.ExpiredDate).initiateWithoutUTC().getTime();
				numberDateTimeDistance = numberVATimeExpired - this._dateNow;

				if(numberDateTimeDistance <= 0)
				{
					return false;
				}
				else
				{
					return true;
				}
			}
			else if((modelProductGeneral.modelThirdPartyPayment != null || modelProductGeneral.modelThirdPartyPayment !== undefined) && modelProductGeneral.modelThirdPartyPayment.ExpiredDate !== undefined)
			{
				numberVATimeExpired = new Date(modelProductGeneral.modelThirdPartyPayment.ExpiredDate).initiateWithoutUTC().getTime();
				numberDateTimeDistance = numberVATimeExpired - this._dateNow;
				if(numberDateTimeDistance <= 0)
				{
					return false;
				}
				else
				{
					return true;
				}
			}
			else
			{
				return false;
			}
		}
		else
		{
			return false;
		}
	}

	public getImageProduct(modelProductGeneral: ProductGeneralModel): string
	{
		if(modelProductGeneral == null || modelProductGeneral === undefined)
		{
			return this._stringConstant.STRING_CHARACTER_EMPTY;
		}
		else
		{
			if(modelProductGeneral.ProductCode === this._stringConstant.STRING_VALUE_PRODUCT_ASRIYEARLY)
			{
				return this._stringPathImageProductAsriYearly;
			}
			else if(modelProductGeneral.ProductCode === this._stringConstant.STRING_VALUE_PRODUCT_ASRIYMONTHLY)
			{
				return this._stringPathImageProductAsriMonthly;
			}
			else if(modelProductGeneral.ProductCode === this._stringConstant.STRING_VALUE_PRODUCT_OTO)
			{
				return this._stringPathImageProductOtoYearly;
			}
			else if(modelProductGeneral.ProductCode === this._stringConstant.STRING_VALUE_PRODUCT_OTOMONTHLYDAILY)
			{
				if(modelProductGeneral.modelProductOto != null || modelProductGeneral.modelProductOto !== undefined)
				{
					if(modelProductGeneral.modelProductOto.CoverageCode === this._stringConstant.STRING_VALUE_PACKAGE_MONTHLY_OTOCUSTOM_COMPREHENSIVE)
					{
						return this._stringPathImageProductOtoMonthly;
					}
					else if(modelProductGeneral.modelProductOto.CoverageCode === this._stringConstant.STRING_VALUE_PACKAGE_DAILY_OTO)
					{
						return this._stringPathImageProductOtoDaily;
					}
					else
					{
						return this._stringConstant.STRING_CHARACTER_EMPTY;
					}
				}
				else
				{
					return this._stringConstant.STRING_CHARACTER_EMPTY;
				}
			}
			else if(modelProductGeneral.ProductCode === this._stringConstant.STRING_VALUE_PRODUCT_PERSONALACCIDENT)
			{
				if(modelProductGeneral.modelProductPersonalAccident != null || modelProductGeneral.modelProductPersonalAccident !== undefined)
				{
					if(modelProductGeneral.modelProductPersonalAccident.CoverageCode === this._stringConstant.STRING_VALUE_PACKAGE_YEARLY_PERSONALACCIDENT)
					{
						return this._stringPathImageProductPersonalAccidentYearly;
					}
					else if(modelProductGeneral.modelProductPersonalAccident.CoverageCode === this._stringConstant.STRING_VALUE_PACKAGE_MONTHLY_PERSONALACCIDENT)
					{
						return this._stringPathImageProductPersonalAccidentMonthly;
					}
					else if(modelProductGeneral.modelProductPersonalAccident.CoverageCode === this._stringConstant.STRING_VALUE_PACKAGE_PERSONALACCIDENTDAILY)
					{
						return this._stringPathImageProductPersonalAccidentDaily;
					}
					else
					{
						return this._stringConstant.STRING_CHARACTER_EMPTY;
					}
				}
				else
				{
					return this._stringConstant.STRING_CHARACTER_EMPTY;
				}
			}
			else if(modelProductGeneral.ProductCode === this._stringConstant.STRING_VALUE_PRODUCT_BICYCLE)
			{
				return this._stringPathImageProductBicycleYearly;
			}
			else if(modelProductGeneral.ProductCode === this._stringConstant.STRING_VALUE_PRODUCT_DAMAGEOFBUSINESSPLACE)
			{
				return this._stringPathImageProductASMIKDamageOfBusinessPlace;
			}
			else if(modelProductGeneral.ProductCode === this._stringConstant.STRING_VALUE_PRODUCT_MYHOUSE)
			{
				return this._stringPathImageProductASMIKMyHouse;
			}
			else if(modelProductGeneral.ProductCode === this._stringConstant.STRING_VALUE_PRODUCT_MYMOTORCYCLE)
			{
				return this._stringPathImageProductASMIKMyMotorcycle;
			}
			else
			{
				return this._stringConstant.STRING_CHARACTER_EMPTY;
			}
		}
	}

	public getImagePayment(modelProductGeneral): string
	{
		if(modelProductGeneral != null || modelProductGeneral !== undefined)
		{
			if(modelProductGeneral.modelVirtualAccount != null || modelProductGeneral.modelVirtualAccount !== undefined)
			{
				return this._stringPathImagePaymentBRIVA;
			}
			else if(modelProductGeneral.modelThirdPartyPayment != null || modelProductGeneral.modelThirdPartyPayment !== undefined)
			{
				if(modelProductGeneral.modelThirdPartyPayment.PaymentProvider === ENUM_PAYMENT_PROVIDER.BNI)
				{
					return this._stringPathImagePaymentBNI;
				}
				else if(modelProductGeneral.modelThirdPartyPayment.PaymentProvider === ENUM_PAYMENT_PROVIDER.BCA)
				{
					return this._stringPathImagePaymentBCA;
				}
				else if(modelProductGeneral.modelThirdPartyPayment.PaymentProvider === ENUM_PAYMENT_PROVIDER.MANDIRI)
				{
					return this._stringPathImagePaymentMANDIRI;
				}
				else if(modelProductGeneral.modelThirdPartyPayment.PaymentProvider === ENUM_PAYMENT_PROVIDER.PERMATA)
				{
					return this._stringPathImagePaymentPERMATA;
				}
				else if(modelProductGeneral.modelThirdPartyPayment.PaymentProvider === ENUM_PAYMENT_PROVIDER.GoPay)
				{
					return this._stringConstant.STRING_CHARACTER_EMPTY;
				}
				else if(modelProductGeneral.modelThirdPartyPayment.PaymentProvider === ENUM_PAYMENT_PROVIDER.AirpayShopeePay)
				{
					return this._stringConstant.STRING_CHARACTER_EMPTY;
				}
				else if(modelProductGeneral.modelThirdPartyPayment.PaymentProvider === ENUM_PAYMENT_PROVIDER.Indomaret)
				{
					return this._stringPathImagePaymentIndomart;
				}
				else if(modelProductGeneral.modelThirdPartyPayment.PaymentProvider === ENUM_PAYMENT_PROVIDER.AlfaGroup)
				{
					return this._stringPathImagePaymentAlfaGroup;
				}
				else
				{
					return this._stringConstant.STRING_CHARACTER_EMPTY;
				}
			}
			else
			{
				return this._stringConstant.STRING_CHARACTER_EMPTY;
			}
		}
		else
		{
			return this._stringConstant.STRING_CHARACTER_EMPTY;
		}
	}

	public setEventEmitterSelect(modelTableUpdate: TableModel): void
	{
		this._functionUserInterface.setLoadingProgress(1);
		this._modelTable = modelTableUpdate;
		alert(this._modelTable.RowPerPage);

		if (this._booleanProductDraft)
		{
			this.callSelectProductDraftByCustomer(this);
		}
		else if (this._booleanProductPending)
		{
			this.callSelectProductPendingByCustomer(this);
		}
		else
		{

		}

		this.setViewFromTop();
	}

	//#endregion


	//#region WEB SERVICES

	private callSelectProductDraftByCustomer(componentCurrent: this): void
	{
		this._functionUserInterface.setLoadingProgress(1);
		componentCurrent._arrayModelProductGeneral = [];

		componentCurrent._serviceProductGeneral.selectProductDraftByCustomer
		({
			success(modelResponse: ResponseModel): void
			{
				if (modelResponse.ServiceResponseCode === ResponseCodeConstant.STRING_RESPONSECODE_SERVICE_SUCCESS)
				{
					if (modelResponse.Data !== undefined)
					{
						componentCurrent._modelTable.setModelFromString(modelResponse.Data);

						if (componentCurrent._modelTable.Result !== undefined)
						{
							let arrayModelProductGeneral: Array<ProductGeneralModel> = JSON.parse(componentCurrent._modelTable.Result);

							if(arrayModelProductGeneral == null || arrayModelProductGeneral === undefined)
							{

							}
							else
							{
								for (const modelProductGeneral of arrayModelProductGeneral)
								{
									const stringProductGeneral: string = JSON.stringify(modelProductGeneral);
									const modelProductGeneralTemp: ProductGeneralModel = new ProductGeneralModel();
									modelProductGeneralTemp.setModelFromString(stringProductGeneral);
									modelProductGeneralTemp.setProgressforProductDraft();

									componentCurrent._arrayModelProductGeneral.push(modelProductGeneralTemp);
								}
							}

							arrayModelProductGeneral = [];

							componentCurrent._componentTableControlInclude.setButtonState();
							componentCurrent._functionUserInterface.updateLoadingProgress();
							componentCurrent._componentTableControlMiniInclude.setButtonState();
							componentCurrent._modelTable.Result = undefined;
						}
						else
						{
							componentCurrent._functionUserInterface.showDialogFromModelResponse(modelResponse, () => { });
						}
					}
					else
					{
						componentCurrent._functionUserInterface.showDialogFromModelResponse(modelResponse, () => { });
					}
				}
				else
				{
					componentCurrent._functionUserInterface.updateLoadingProgress();
					// componentCurrent._functionUserInterface.showDialogFromModelResponse(modelResponse, () => { });
				}
			},
			fail(modelResponse: ResponseModel): void
			{
				componentCurrent._functionUserInterface.showDialogFromModelResponseWithRetry(modelResponse, () => { componentCurrent.callSelectProductDraftByCustomer(componentCurrent); });
			},
			signOut(modelResponse: ResponseModel): void
			{
				componentCurrent._functionUserInterface.showDialogFromModelResponse(modelResponse, () => { componentCurrent.signOut(); });
			}
		}, componentCurrent._modelTable);
	}

	private callSelectProductPendingByCustomer(componentCurrent: this): void
	{
		componentCurrent._functionUserInterface.setLoadingProgress(1);
		componentCurrent._arrayModelProductGeneral = [];

		const modelResponseValidation: ResponseModel = componentCurrent._modelUserSignIn.checkSessionSignin();

		if(modelResponseValidation.State === ENUM_RESPONSE_STATE.Success)
		{
			componentCurrent._serviceProductGeneral.selectProductPendingByCustomer
			({
				success(modelResponse: ResponseModel): void
				{
					if (modelResponse.ServiceResponseCode === ResponseCodeConstant.STRING_RESPONSECODE_SERVICE_SUCCESS)
					{
						if (modelResponse.Data !== undefined)
						{
							componentCurrent._modelTable.setModelFromString(modelResponse.Data);

							if (componentCurrent._modelTable.Result !== undefined)
							{
								let arrayModelProductGeneral: Array<ProductGeneralModel> = JSON.parse(componentCurrent._modelTable.Result);
								if(arrayModelProductGeneral == null || arrayModelProductGeneral === undefined)
								{

								}
								else
								{
									for (const modelProductGeneral of arrayModelProductGeneral)
									{
										const stringProductGeneral: string = JSON.stringify(modelProductGeneral);
										const modelProductGeneralTemp: ProductGeneralModel = new ProductGeneralModel();
										modelProductGeneralTemp.setModelFromString(stringProductGeneral);

										componentCurrent._arrayModelProductGeneral.push(modelProductGeneralTemp);
									}
								}

								arrayModelProductGeneral = [];

								componentCurrent._componentTableControlInclude.setButtonState();
								componentCurrent._functionUserInterface.updateLoadingProgress();
								componentCurrent._componentTableControlMiniInclude.setButtonState();
								componentCurrent._modelTable.Result = undefined;
							}
							else
							{
								componentCurrent._functionUserInterface.showDialogFromModelResponse(modelResponse, () => { });
							}
						}
						else
						{
							componentCurrent._functionUserInterface.showDialogFromModelResponse(modelResponse, () => { });
						}
					}
					else
					{
						componentCurrent._functionUserInterface.updateLoadingProgress();
					}
				},
				fail(modelResponse: ResponseModel): void
				{
					componentCurrent._functionUserInterface.showDialogFromModelResponseWithRetry(modelResponse, () => { componentCurrent.callSelectProductPendingByCustomer(componentCurrent); });
				},
				signOut(modelResponse: ResponseModel): void
				{
					componentCurrent._functionUserInterface.showDialogFromModelResponse(modelResponse, () => { componentCurrent.signOut(); });
				}
			}, componentCurrent._modelTable);
		}
		else
		{
			componentCurrent._functionUserInterface.showDialogFromModelResponse(modelResponseValidation, () => { componentCurrent.signOut(); });
		}
	}

	//#endregion


	//#region NAVIGATION

	goToDetail(modelProductGeneral: ProductGeneralModel)
	{
		if(this.checkPaymentExpired(modelProductGeneral))
		{
			this.goToPayment(modelProductGeneral);
		}
		else
		{
			this.goToCheckout(modelProductGeneral);
		}
	}

	goToCheckout(modelProductGeneral: ProductGeneralModel): void
	{
		this._router.navigate(["home", "product", "checkout", modelProductGeneral.Token]);
	}

	goToPayment(modelProductGeneral: ProductGeneralModel): void
	{
		this._router.navigate(["home", "product", "payment", modelProductGeneral.Token]);
	}

	goToProduct(modelProductGeneral: ProductGeneralModel): void
	{
		if(modelProductGeneral)
		{
			if(modelProductGeneral.ProductCode === StringConstant.STRING_VALUE_PRODUCT_ASRIYEARLY)
			{
				this._router.navigate(["home", "product", "asri", "yearly", modelProductGeneral.modelProductAsri?.Token ]);
			}
			else if(modelProductGeneral.ProductCode === StringConstant.STRING_VALUE_PRODUCT_ASRIYMONTHLY)
			{
				this._router.navigate(["home", "product", "asri", "monthly", modelProductGeneral.modelProductAsri?.Token]);
			}
			else if(modelProductGeneral.ProductCode === StringConstant.STRING_VALUE_PRODUCT_OTO)
			{
				this._router.navigate(["home", "product", "oto", "yearly", modelProductGeneral.modelProductOto?.Token]);
			}
			else if(modelProductGeneral.ProductCode === StringConstant.STRING_VALUE_PRODUCT_OTOMONTHLYDAILY)
			{
				if(modelProductGeneral.modelProductOto?.CoverageCode === StringConstant.STRING_VALUE_PACKAGE_MONTHLY_OTOCUSTOM_COMPREHENSIVE )
				{
					this._router.navigate(["home", "product", "oto", "monthly", modelProductGeneral.modelProductOto?.Token]);
				}
				else if(modelProductGeneral.modelProductOto?.CoverageCode === StringConstant.STRING_VALUE_PACKAGE_MONTHLY_OTODAILY)
				{
					this._router.navigate(["home", "product", "oto", "daily", modelProductGeneral.modelProductOto?.Token]);
				}
			}
			else if(modelProductGeneral.ProductCode === StringConstant.STRING_VALUE_PRODUCT_PERSONALACCIDENT)
			{
				if(modelProductGeneral.modelProductPersonalAccident?.CoverageCode === StringConstant.STRING_VALUE_PACKAGE_YEARLY_PERSONALACCIDENT)
				{
					this._router.navigate(["home", "product", "personalaccident", "yearly", modelProductGeneral.modelProductPersonalAccident?.Token]);
				}
				else if(modelProductGeneral.modelProductPersonalAccident?.CoverageCode === StringConstant.STRING_VALUE_PACKAGE_MONTHLY_PERSONALACCIDENT)
				{
					this._router.navigate(["home", "product", "personalaccident", "monthly", modelProductGeneral.modelProductPersonalAccident?.Token]);
				}
				else if(modelProductGeneral.modelProductPersonalAccident?.CoverageCode === StringConstant.STRING_VALUE_PACKAGE_PERSONALACCIDENTDAILY)
				{
					this._router.navigate(["home", "product", "personalaccident", "daily", modelProductGeneral.modelProductPersonalAccident?.Token]);
				}
			}
			else if(modelProductGeneral.ProductCode === StringConstant.STRING_VALUE_PRODUCT_BICYCLE)
			{
				this._router.navigate(["home", "product", "bicycle", "yearly", modelProductGeneral.modelProductBicycle?.Token]);
			}
			else if(modelProductGeneral.ProductCode === StringConstant.STRING_VALUE_PRODUCT_DAMAGEOFBUSINESSPLACE)
			{
				this._router.navigate(["home", "productasmik", "damageofbusinessplace", "yearly", modelProductGeneral.modelProductDamageOfBusinessPlace?.Token]);
			}
			else if(modelProductGeneral.ProductCode === StringConstant.STRING_VALUE_PRODUCT_MYHOUSE )
			{
				this._router.navigate(["home", "productasmik", "myhouse", "yearly", modelProductGeneral.modelProductMyHouse?.Token]);
			}
			else if(modelProductGeneral.ProductCode === StringConstant.STRING_VALUE_PRODUCT_MYMOTORCYCLE)
			{
				this._router.navigate(["home", "productasmik", "mymotorcycle", "yearly", modelProductGeneral.modelProductMyMotorcycle?.Token]);
			}
		}
	}

	//#endregion

}
