<div id="divMainSectionContainer">
	<section class="SectionSecondary" id="sectionHeaderProduct" [ngStyle]="getImageMain()">
		<img class="ImgShapeMain1" src="../../../assets/gifs/transparent.gif">
        <img class="ImgShapeMain2" src="../../../assets/gifs/transparent.gif">
        <div class="DivFormVerticalContainer DivSiteContainer">
            <div class="DivProduct">
                <h2 class="FontSoftColor"> {{ this._stringConstant.STRING_INFORMATION_TITTLE_PRODUCT }} </h2>
            </div>
        </div>
	</section>

	<section class="SectionSecondary" id="sectionProductListContainer">
        <div class="DivSiteContainer">
            <h1>{{ _stringConstant.STRING_CONTENT_TITLE_SEEALLPRODUCT }}</h1>
            <h3>{{ _stringConstant.STRING_CONTENT_SUBTITTLE_YEARLY }}</h3>
            <div class="DivFormHorizontalContainer" id="divSiteProductList">
    
                <div class="DivContentBox DivRelativeContainer" id="divContentProductList">
                    <div class="DivTitleContentHeader">
                        <img src="../../../assets/icons/sakina/product/icon_oto.svg">
                        <label>{{ _stringConstant.STRING_TITLE_OTO_CAPITAL }}</label>
                    </div>
                    <p>{{ _stringConstant.STRING_INFORMATION_PARAGRAPH_OTO }}</p>
                    <button class="ButtonSubmit" (click)="goToProductOtoYearly()"><label class="FontSoftColor">{{ _stringConstant.STRING_INFORMATION_BUTTON_MORE }} &#x2192;</label></button>
                </div>
    
                <div class="DivContentBox DivRelativeContainer" id="divContentProductList">
                    <div class="DivTitleContentHeader">
                        <img src="../../../assets/icons/sakina/product/icon_asri.svg">
                        <label>{{ _stringConstant.STRING_TITLE_ASRI_CAPITAL }}</label>
                    </div>
                    <p>{{ _stringConstant.STRING_INFORMATION_PARAGRAPH_ASRI }}</p>
                    <button class="ButtonSubmit" (click)="goToProductAsriYearly()"><label class="FontSoftColor">{{ _stringConstant.STRING_INFORMATION_BUTTON_MORE }} &#x2192;</label></button>
                </div>
    
                <div class="DivContentBox DivRelativeContainer" id="divContentProductList">
                    <div class="DivTitleContentHeader">
                        <img src="../../../assets/icons/sakina/product/icon_diri.svg">
                        <label>{{ _stringConstant.STRING_TITLE_PERSONALACCIDENT_CAPITAL }}</label>
                    </div>
                    <p>{{ _stringConstant.STRING_INFORMATION_PARAGRAPH_PERSONALACCIDENT }}</p>
                    <!-- <p>{{ _stringConstant.STRING_INFORMATION_VALUE_CRASH }}</p> -->
                    <button class="ButtonSubmit" (click)="goToProductPersonalAccidentYearly()"><label class="FontSoftColor">{{ _stringConstant.STRING_INFORMATION_BUTTON_MORE }} &#x2192;</label></button>
                </div>
    
                <div class="DivContentBox DivRelativeContainer" id="divContentProductList">
                    <div class="DivTitleContentHeader">
                        <img src="../../../assets/icons/sakina/product/icon_sepeda.svg">
                        <label>{{ _stringConstant.STRING_TITLE_BICYCLE_CAPITAL }}</label>
                    </div>
                    <p>{{ _stringConstant.STRING_INFORMATION_PARAGRAPH_BICYCLE }}</p>
                    <button class="ButtonSubmit" (click)="goToProductBicycleYearly()"><label class="FontSoftColor">{{ _stringConstant.STRING_INFORMATION_BUTTON_MORE }} &#x2192;</label></button>
                </div>
    
            </div>
            <div class="DivFormHorizontalContainer" id="divSiteProductList">
    
                <div class="DivContentBox DivRelativeContainer" id="divContentProductList">
                    <div class="DivTitleContentHeader">
                        <img src="../../../assets/icons/sakina/product/icon_asmik_rumahku.svg">
                        <label>{{ _stringConstant.STRING_TITLE_MYHOUSE_CAPITAL }}</label>
                    </div>
                    <p>{{ _stringConstant.STRING_INFORMATION_PARAGRAPH_MYHOUSE }}</p>
                    <button class="ButtonSubmit" (click)="goToProductASMIKMyHouse()"><label class="FontSoftColor">{{ _stringConstant.STRING_INFORMATION_BUTTON_MORE }} &#x2192;</label></button>
                </div>
    
                <div class="DivContentBox DivRelativeContainer" id="divContentProductList">
                    <div class="DivTitleContentHeader">
                        <img src="../../../assets/icons/sakina/product/icon_asmik_ktu.svg">
                        <label>{{ _stringConstant.STRING_TITLE_DAMAGEOFBUSINESSPLACE_CAPITAL }}</label>
                    </div>
                    <p>{{ _stringConstant.STRING_INFORMATION_PARAGRAPH_DAMAGEOFBUSINESSPLACE }}</p>
                    <button class="ButtonSubmit" (click)="goToProductASMIKDamageOfBusinessPlace()"><label class="FontSoftColor">{{ _stringConstant.STRING_INFORMATION_BUTTON_MORE }} &#x2192;</label></button>
                </div>
    
                <div class="DivContentBox DivRelativeContainer" id="divContentProductList">
                    <div class="DivTitleContentHeader">
                        <img src="../../../assets/icons/sakina/product/icon_asmik_motorku.svg">
                        <label>{{ _stringConstant.STRING_TITLE_MYMOTORCYCLE_CAPITAL }}</label>
                    </div>
                    <p>{{ _stringConstant.STRING_INFORMATION_PARAGRAPH_MYMOTORCYCLE }}</p>
                    <button class="ButtonSubmit" (click)="goToProductASMIKMyMotorcycle()"><label class="FontSoftColor">{{ _stringConstant.STRING_INFORMATION_BUTTON_MORE }} &#x2192;</label></button>
                </div>

            </div>
        </div>
        <div class="DivSiteContainer">
            <h3>{{ _stringConstant.STRING_CONTENT_SUBTITTLE_MONTHLY }}</h3>
            <div class="DivFormHorizontalContainer" id="divSiteProductList">
    
                <div class="DivContentBox DivRelativeContainer" id="divContentProductList">
                    <div class="DivTitleContentHeader">
                        <img src="../../../assets/icons/sakina/product/icon_oto_custom.svg">
                        <label>{{ _stringConstant.STRING_TITLE_OTOMONTHLY }}</label>
                    </div>
                    <p>{{ _stringConstant.STRING_INFORMATION_PARAGRAPH_OTOMONTHLY }}</p>
                    <button class="ButtonSubmit" (click)="goToProductOtoMonthly()"><label class="FontSoftColor">{{ _stringConstant.STRING_INFORMATION_BUTTON_MORE }} &#x2192;</label></button>
                </div>
    
                <div class="DivContentBox DivRelativeContainer" id="divContentProductList">
                    <div class="DivTitleContentHeader">
                        <img src="../../../assets/icons/sakina/product/icon_asri_custom.svg">
                        <label>{{ _stringConstant.STRING_TITLE_ASRIMONTHLY }}</label>
                    </div>
                    <p>{{ _stringConstant.STRING_INFORMATION_PARAGRAPH_ASRIMONTHLY }}</p>
                    <button class="ButtonSubmit" (click)="goToProductAsriMonthly()"><label class="FontSoftColor">{{ _stringConstant.STRING_INFORMATION_BUTTON_MORE }} &#x2192;</label></button>
                </div>
    
                <div class="DivContentBox DivRelativeContainer" id="divContentProductList">
                    <div class="DivTitleContentHeader">
                        <img src="../../../assets/icons/sakina/product/icon_diri_custom.svg">
                        <label>{{ _stringConstant.STRING_TITLE_PERSONALACCIDENTMONTHLY }}</label>
                    </div>
                    <p>{{ _stringConstant.STRING_INFORMATION_PARAGRAPH_PERSONALACCIDENTMONTHLY }}</p>
                    <!-- <p>{{ _stringConstant.STRING_INFORMATION_VALUE_CRASH }}</p> -->
                    <button class="ButtonSubmit" (click)="goToProductPersonalAccidentMonthly()"><label class="FontSoftColor">{{ _stringConstant.STRING_INFORMATION_BUTTON_MORE }} &#x2192;</label></button>
                </div>
    
            </div>
        </div>
        <div class="DivSiteContainer">
            <h3>{{ _stringConstant.STRING_CONTENT_SUBTITTLE_DAILY }}</h3>
            <div class="DivFormHorizontalContainer" id="divSiteProductList">
    
                <div class="DivContentBox DivRelativeContainer" id="divContentProductList">
                    <div class="DivTitleContentHeader">
                        <img src="../../../assets/icons/sakina/product/icon_oto_daily.svg">
                        <label>{{ _stringConstant.STRING_TITLE_OTODAILY }}</label>
                    </div>
                    <p>{{ _stringConstant.STRING_INFORMATION_PARAGRAPH_OTODAILY }}</p>
                    <img class="ImageLabel" src="../../../assets/logos/sakina/logo_greensurance_new.svg">
                    <button class="ButtonSubmit" (click)="goToProductOtoDaily()"><label class="FontSoftColor">{{ _stringConstant.STRING_INFORMATION_BUTTON_MORE }} &#x2192;</label></button>
                </div>
    
                <div class="DivContentBox DivRelativeContainer" id="divContentProductList">
                    <div class="DivTitleContentHeader">
                        <img src="../../../assets/icons/sakina/product/icon_diri_daily.svg">
                        <label>{{ _stringConstant.STRING_TITLE_PERSONALACCIDENTDAILY }}</label>
                    </div>
                    <p>{{ _stringConstant.STRING_INFORMATION_PARAGRAPH_PERSONALACCIDENTDAILY }}</p>
                    <!-- <p>{{ _stringConstant.STRING_INFORMATION_VALUE_CRASH }}</p> -->
                    <img class="ImageLabel" src="../../../assets/logos/sakina/logo_greensurance_new.svg">
                    <button class="ButtonSubmit" (click)="goToProductPersonalAccidentDaily()"><label class="FontSoftColor">{{ _stringConstant.STRING_INFORMATION_BUTTON_MORE }} &#x2192;</label></button>
                </div>
    
            </div>
        </div>
    </section>
</div>
