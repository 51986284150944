<div id="divBodyFooterContainer">
    <div class="DivFooterInfoPage">
        <div class="DivFooterVerticalContainer" id="divSectionHQContactUs">
			<div class="DivFooterHQLocation">
				<label for="labelHeaderTitle">Kantor Pusat</label>
				<p class="DivSubHeaderFooter">PT. BRI Asuransi Indonesia</p>
				<div class="DivFooterHorizontalContainer DivFooterContentContainer">
					<div class="DivFooterSVGContainer">
						<a href="https://goo.gl/maps/zj2BkgGyZW6oMspg7">
							<img src="../assets/icons/sakina/footer/icon_address.svg">
						</a>
					</div>
	
					<div class="DivFooterSVGContentContainer">
						<p>Jl. Mampang Prapatan Raya No. 18,</p>
						<p>Jakarta Selatan, DKI Jakarta, 12790</p>
						<p>Indonesia</p>
					</div>
				</div>
			</div>

			<div class="DivFooterContactUs">
				<label for="labelHeaderTitle">Hubungi Kami</label>

				<!-- <a href="tel:14081" class="AFooterSVGContainer"> -->
				<a class="AFooterSVGContainer">
					<img src="../../assets/icons/sakina/footer/icon_call_center.svg" alt="Logo Contact Center" style="width: 180px; margin: 5px 0 15px 0;">
				</a>

				<!-- <a href="https://wa.me/628118014081"> -->
				<a>
					<div class="DivFooterHorizontalContainer">
						<div class="DivFooterSVGContainer">
							<img src="../assets/icons/sakina/footer/icon_call.svg">
						</div>
	
						<p class="PFooterContact">+62 81180 14081</p>
					</div>
				</a>

				<!-- <a href="mailto:info@brins.co.id"> -->
				<a>
					<div class="DivFooterHorizontalContainer">
						<div class="DivFooterSVGContainer">
							<img src="../assets/icons/sakina/footer/icon_mail.svg">
						</div>
	
						<p class="PFooterContact">info@brins.co.id</p>
					</div>
				</a>
			</div>

            <div class="DivFooterVerticalContainer">
                <div class="DivFooterCopyright">
                    <p class="PLabelCopyright">Hak cipta <span>&copy;</span> 2022</p>
                    <p class="PTextCopyright">PT. BRI Asuransi Indonesia</p>
                    <hr>
                    <p class="PTextCopyright">Seluruh hak cipta.</p>
                </div>
            </div>
        </div>
        <div class="DivFooterPageHorizontalContainer">
            <div class="DivFooterSectionPage">
                <div class="DivFooterVerticalContainer DivFooterLink">
                    <label for="labelHeaderTitle">Tautan</label>
                    <div class="DivFooterHorizontalContainer DivFooterContentContainer">
                        <div class="DivParagraphLink">
                            <p><a (click)="goToTermsAndCondition()">Syarat dan Ketentuan</a></p>
                            <p><a (click)="goToFAQ()">FAQ</a></p>
                            <p><a (click)="goToPrivacyPolicy()">Kebijakan Privasi</a></p>
                            <!-- <p><a href="">Agency Management System (AMS)</a></p> -->
                            <!-- <p><a href="">BRINS API</a></p> -->
                            <p><a (click)="goToContactandLocation()">Kontak dan Lokasi</a></p>
                            <!-- <p><a href="">Karir</a></p> -->
                            <p><a (click)="goToPartner()">Mitra</a></p>
                        </div>
                    </div>
                </div>
                <div class="DivFooterVerticalContainer Div">
                    <label for="labelHeaderTitle">Media Sosial</label>
                    <div class="DivFooterSocialMedia">
                        <div class="DivFooterHorizontalContainer">
                            <div class="DivFooterSVGContainer">
                                <a (click)="goToLink('https://www.instagram.com/bri.insurance/')">
                                    <img src="../../assets/icons/sakina/footer/icon_instagram.svg" alt="Logo - Instagram">
                                </a>
                            </div>

                            <div class="DivFooterSVGContainer">
                                <a (click)="goToLink('https://www.facebook.com/bri.insurance')">
                                    <img  src="../../assets/icons/sakina/footer/icon_facebook.svg" alt="Logo Facebook">
                                </a>
                            </div>

                            <div class="DivFooterSVGContainer">
                                <a (click)="goToLink('https://www.youtube.com/channel/UCdwtS_j2DKMiVSDaD2SCLwQ/featured')">
                                    <img src="../../assets/icons/sakina/footer/icon_youtube.svg" alt="Logo - Youtube">
                                </a>
                            </div>

                            <div class="DivFooterSVGContainer">
                                <a (click)="goToLink('https://twitter.com/brins_insurance')">
                                    <img src="../../assets/icons/sakina/footer/icon_twitter.svg" alt="Logo - Twitter">
                                </a>
                            </div>

                            <div class="DivFooterSVGContainer">
                                <a (click)="goToLink('https://www.tiktok.com/@brinsurtech')">
                                    <img src="../../assets/icons/sakina/icon_tiktok.svg" alt="Logo - TikTok">
                                </a>
                            </div>

                            <div class="DivFooterSVGContainer">
                                <a (click)="goToLink('https://id.linkedin.com/company/pt-bri-asuransi-indonesia')">
                                    <img src="../../assets/icons/sakina/footer/icon_linkedin.svg" alt="Logo - Linked In">
                                </a>
                            </div>
                        </div>
                    </div>
        
                    <label for="labelHeaderTitle">Terdaftar & Diawasi Oleh</label>
                    <div class="DivFooterHorizontalContainer">
                        <div class="DivFooterSVGLargeContainer">
                            <a href="https://www.ojk.go.id/">
                                <img src="../../assets/icons/sakina/footer/icon_ojk.svg" alt="Logo - OJK">
                            </a>
                        </div>
        
                        <div class="DivFooterSVGLargeContainer">
                            <img src="../../assets/icons/sakina/footer/icon_mari.svg" alt="Logo - Mari Berasuransi">
                        </div>
                    </div>
        
                    <div class="DivFooterHorizontalContainer">
                        <div class="DivFooterSVGMediumContainer">
                            <img src="../../assets/icons/sakina/footer/icon_pefindo.svg" alt="Logo - Pefindo">
                        </div>
        
                        <div class="DivFooterSVGMediumContainer">
                            <img src="../../assets/icons/sakina/footer/icon_aaui.svg" alt="Logo - AAUI">
                        </div>
                    </div>
                </div>
            </div>

            <div class="DivFooterSectionPage">
                <div id="divFooterSubscribeContainer" class="DivFooterVerticalContainer DivFooterLink">
                    <label>Berlangganan Berita Kami</label>
                    <div class="DivFooterVerticalContainer">
                        <p>Tetap mendapatkan berita dan produk terbaru kami</p>

                        <div class="DivFooterHorizontalContainer DivFormSubscribeEmail">
                            <input type="text" class="InputFormSubscribeEmail" [(ngModel)]="this._modelEmailSubscription.Email" placeholder="Masukkan alamat email Anda">
                            <button class="ButtonSlideDetail" type="submit" (click)="checkValidationEmailSubscription()"><img src="../assets/icons/sakina/footer/icon_ceklis.svg"></button>
                        </div>
                    </div>
					<!-- <div class="DivFooterCopyrightResponsive">
						<p class="PLabelCopyright">Hak cipta <span>&copy;</span> 2022</p>
						<p class="PTextCopyright">PT. BRI Asuransi Indonesia</p>
						<hr>
						<p class="PTextCopyright">Seluruh hak cipta.</p>
					</div> -->
                </div>
            </div>
        </div>
    </div>
</div>