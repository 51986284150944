//#region IMPORT

import { environment } from "src/environments/environment";

//#endregion


//#region CLASS

export class WebAddressConstant
{
	//#region IPADDRESS

	static STRING_WEBADDRESS_WEBSITE_IPADRESS_BRINS: string = "www.brins.co.id";

	//#endregion


	//#region URL API

	static STRING_URL_USER_API: string = environment.URL_USER_API;
	static STRING_URL_PRODUCT_API: string = environment.URL_PRODUCT_API;
	static STRING_URL_POLICY_API: string = environment.URL_POLICY_API;
	static STRING_URL_BROADCAST_API: string = environment.URL_BROADCAST_API;
	static STRING_URL_CLAIM_API: string = environment.URL_CLAIM_API;
	static STRING_URL_BRINS_WEB: string = environment.URL_BRINS_WEB;

	//#endregion


	//#region URL WEBSITE SOCKET

	/*
	URL_USER_WEBSITESOCKET: String = BuildConfig.SCHEMA_WEBSITESOCKET + BuildConfig.HOST_USER_API + StringConstant.STRING_CHARACTER_SEPARATOR_TIME_VIEW + BuildConfig.PORT_USER;
	URLT_PRODUCT_WEBSITESOCKET: String = BuildConfig.SCHEMA_WEBSITESOCKET + BuildConfig.HOST_PRODUCT_API + StringConstant.STRING_CHARACTER_SEPARATOR_TIME_VIEW + BuildConfig.PORT_PRODUCT;
	URL_POLICY_WEBSITESOCKET: String = BuildConfig.SCHEMA_WEBSITESOCKET + BuildConfig.HOST_POLICY_API + StringConstant.STRING_CHARACTER_SEPARATOR_TIME_VIEW + BuildConfig.PORT_POLICY;
	*/

	//#endregion


	//#region HANDSHAKE SERVICE

	static STRING_PATH_CONTROLLER_HANDSHAKE: string = "/Handshake";

	static STRING_URL_HANDSHAKE_GENERATETOKEN: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_HANDSHAKE}/generateToken`;
	static STRING_URL_HANDSHAKE_REFRESHTOKEN: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_HANDSHAKE}/refreshToken`;
	static STRING_URL_HANDSHAKE_SELECTUSERBYTOKEN: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_HANDSHAKE}/selectUserByToken`;
	static STRING_URL_HANDSHAKE_REFRESHTOKENFORCUSTOMER: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_HANDSHAKE}/refreshTokenForCustomer`;

	//#endregion


	//#region CONTROLLER DASHBOARD

	static STRING_PATH_CONTROLLER_DASHBOARD: string = "/Dashboard";

	static STRING_URL_DASHBOARD_SELECTPRODUCTFOROPERATIONANDSUPPORT: string = `${WebAddressConstant.STRING_URL_PRODUCT_API}${WebAddressConstant.STRING_PATH_CONTROLLER_DASHBOARD}/selectProductForOperationAndSupport`;
	static STRING_URL_DASHBOARD_SELECTPRODUCTFORBRANCHHEAD: string = `${WebAddressConstant.STRING_URL_PRODUCT_API}${WebAddressConstant.STRING_PATH_CONTROLLER_DASHBOARD}/selectProductForBranchHead`;
	static STRING_URL_DASHBOARD_SELECTPRODUCTFORACCOUNTINGANDFINANCE: string = `${WebAddressConstant.STRING_URL_PRODUCT_API}${WebAddressConstant.STRING_PATH_CONTROLLER_DASHBOARD}/selectProductForAccountingAndFinance`;
	static STRING_URL_DASHBOARD_SELECTPRODUCTFORRELATIONSHIPMANAGER: string = `${WebAddressConstant.STRING_URL_PRODUCT_API}${WebAddressConstant.STRING_PATH_CONTROLLER_DASHBOARD}/selectProductForRelationshipManager`;
	static STRING_URL_DASHBOARD_SELECTACTIVITYFOROPERATIONANDSUPPORT: string = `${WebAddressConstant.STRING_URL_PRODUCT_API}${WebAddressConstant.STRING_PATH_CONTROLLER_DASHBOARD}/selectActivityForOperationAndSupport`;
	static STRING_URL_DASHBOARD_SELECTACTIVITYFORBRANCHHEAD: string = `${WebAddressConstant.STRING_URL_PRODUCT_API}${WebAddressConstant.STRING_PATH_CONTROLLER_DASHBOARD}/selectActivityForBranchHead`;
	static STRING_URL_DASHBOARD_SELECTACTIVITYFORRELATIONSHIPMANAGER: string = `${WebAddressConstant.STRING_URL_PRODUCT_API}${WebAddressConstant.STRING_PATH_CONTROLLER_DASHBOARD}/selectActivityForRelationshipManager`;
	static STRING_URL_DASHBOARD_SELECTRELATIONSHIPMANAGERFORBRANCHHEAD: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_DASHBOARD}/selectRelationshipManagerForBranchHead`;
	static STRING_URL_DASHBOARD_SELECTRELATIONSHIPMANAGERFOROPERATIONANDSUPPORT: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_DASHBOARD}/selectRelationshipManagerForOperationAndSupport`;
	static STRING_URL_DASHBOARD_SELECTRELATIONSHIPMANAGERFORRELATIONSHIPMANAGER: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_DASHBOARD}/selectRelationshipManagerForRelationshipManager`;
	static STRING_URL_DASHBOARD_SELECTBRANCHPREMIUMREVENUE: string = `${WebAddressConstant.STRING_URL_POLICY_API}${WebAddressConstant.STRING_PATH_CONTROLLER_DASHBOARD}/selectBranchPremiumRevenue`;
	static STRING_URL_DASHBOARD_SELECTREVENUEANDPROPORTIONALTARGET: string = `${WebAddressConstant.STRING_URL_POLICY_API}${WebAddressConstant.STRING_PATH_CONTROLLER_DASHBOARD}/selectRevenueAndProportionalTarget`;
	static STRING_URL_DASHBOARD_SELECTMONEYNONMONEY: string = `${WebAddressConstant.STRING_URL_POLICY_API}${WebAddressConstant.STRING_PATH_CONTROLLER_DASHBOARD}/selectMoneyNonMoney`;
	static STRING_URL_DASHBOARD_SELECTMICROPOLICYAMOUNT: string = `${WebAddressConstant.STRING_URL_POLICY_API}${WebAddressConstant.STRING_PATH_CONTROLLER_DASHBOARD}/selectMicroPolicyAmount`;
	static STRING_URL_DASHBOARD_SELECTFIVEPILLARRELATIONSHIPMANAGERREVENUE: string = `${WebAddressConstant.STRING_URL_POLICY_API}${WebAddressConstant.STRING_PATH_CONTROLLER_DASHBOARD}/selectFivePillarRelationshipManagerRevenue`;
	static STRING_URL_DASHBOARD_SELECTGENERALPOSTREVENUE: string = `${WebAddressConstant.STRING_URL_POLICY_API}${WebAddressConstant.STRING_PATH_CONTROLLER_DASHBOARD}/selectGeneralPostRevenue`;
	static STRING_URL_DASHBOARD_SELECTDAILYBRANCHPOSEMMOVEMENT: string = `${WebAddressConstant.STRING_URL_POLICY_API}${WebAddressConstant.STRING_PATH_CONTROLLER_DASHBOARD}/selectDailyBranchPosemMovement`;
	static STRING_URL_DASHBOARD_SELECTPAIDPOSEM: string = `${WebAddressConstant.STRING_URL_POLICY_API}${WebAddressConstant.STRING_PATH_CONTROLLER_DASHBOARD}/selectPaidPosem`;
	static STRING_URL_DASHBOARD_SELECTPOLICYRENEWAL: string = `${WebAddressConstant.STRING_URL_POLICY_API}${WebAddressConstant.STRING_PATH_CONTROLLER_DASHBOARD}/selectPolicyRenewal`;
	static STRING_URL_DASHBOARD_SELECTUNDERWRITINGACHIEVEMENTRESULT: string = `${WebAddressConstant.STRING_URL_POLICY_API}${WebAddressConstant.STRING_PATH_CONTROLLER_DASHBOARD}/selectUnderwritingAchievementResult`;
	static STRING_URL_DASHBOARD_SELECTFIVEPILLARREVENUE: string = `${WebAddressConstant.STRING_URL_POLICY_API}${WebAddressConstant.STRING_PATH_CONTROLLER_DASHBOARD}/selectFivePillarRevenue`;
	static STRING_URL_DASHBOARD_SELECTFIVEPILLARREVENUEBYSEGMENT: string = `${WebAddressConstant.STRING_URL_POLICY_API}${WebAddressConstant.STRING_PATH_CONTROLLER_DASHBOARD}/selectFivePillarRevenueBySegment`;
	static STRING_URL_DASHBOARD_SELECTPOSEMDETAILBYBRANCH: string = `${WebAddressConstant.STRING_URL_POLICY_API}${WebAddressConstant.STRING_PATH_CONTROLLER_DASHBOARD}/selectPosemDetailByBranch`;
	static STRING_URL_DASHBOARD_SELECTPOSEMDETAILBYDAY: string = `${WebAddressConstant.STRING_URL_POLICY_API}${WebAddressConstant.STRING_PATH_CONTROLLER_DASHBOARD}/selectPosemDetailByDay`;

	static STRING_URL_DASHBOAD_SELECTDASHBOARDDAILYFOROPERATIONANDSUPPORTBYATTRIBUTES: string = `${WebAddressConstant.STRING_URL_PRODUCT_API}${WebAddressConstant.STRING_PATH_CONTROLLER_DASHBOARD}/selectDashboardDailyForOperationAndSupportByAttributes`;
	static STRING_URL_DASHBOAD_SELECTDASHBOARDDAILYFOROPERATIONANDSUPPORTBYTOKEN: string = `${WebAddressConstant.STRING_URL_PRODUCT_API}${WebAddressConstant.STRING_PATH_CONTROLLER_DASHBOARD}/selectDashboardDailyForOperationAndSupportByToken`;

	//#endregions


	//#region OPERATION AND SUPPORT CONTROLLER

	static STRING_PATH_CONTROLLER_OPERATIONANDSUPPORT: string = "/OperationAndSupport";

	static STRING_URL_OPERATIONANDSUPPORT_SELECTOPERATIONANDSUPPORTBYEMAIL: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_OPERATIONANDSUPPORT}/selectOperationAndSupportByEmail`;
	static STRING_URL_OPERATIONANDSUPPORT_VERIFYOPERATIONANDSUPPORTBYEMAIL: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_OPERATIONANDSUPPORT}/verifyOperationAndSupportByEmail`;
	static STRING_URL_OPERATIONANDSUPPORT_SIGNOUT: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_OPERATIONANDSUPPORT}/signOut`;

	//#endregion


	//#region BRANCH CONTROLLER

	static STRING_PATH_CONTROLLER_BRANCH: string = "/Branch";

	static STRING_URL_BRANCH_SELECTBRANCH: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_BRANCH}/selectBranch`;
	static STRING_URL_BRANCH_SELECTBRANCHBYLOCATION: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_BRANCH}/selectBranchByLocation`;
	static STRING_URL_BRANCH_SELECTBRANCHFORCUSTOMER: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_BRANCH}/selectBranchForCustomer`;
	static STRING_URL_BRANCH_SELECTPROVINCE: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_BRANCH}/selectProvince`;
	static STRING_URL_BRANCH_SELECTBRANCHBYPROVINCE: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_BRANCH}/selectBranchByProvince`;
	static STRING_URL_BRANCH_SELECTBRANCHBYATTRIBUTES: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_BRANCH}/selectBranchByAttributes`;

	//#endregion


	//#region BRANCH COVERAGE CONTROLLER

	static STRING_PATH_CONTROLLER_BRANCHCOVERAGE: string = "/BranchCoverage";

	static STRING_URL_BRANCHCOVERAGE_SELECTBRANCHCOVERAGE: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_BRANCHCOVERAGE}/selectBranchCoverage`;

	//#endregion


	//#region BRI POLICY CONTROLLER

	static STRING_PATH_CONTROLLER_BRIPOLICY: string = "/BRIPolicy";

	static STRING_URL_BRIPOLICY_CHECKBRIPOLICYBYATTRIBUTES: string = `${WebAddressConstant.STRING_URL_POLICY_API}${WebAddressConstant.STRING_PATH_CONTROLLER_BRIPOLICY}/checkBRIPolicyByAttributes`;

	//#endregion


	//#region BRI ENDORSEMENT POLICY CONTROLLER

	static STRING_PATH_CONTROLLER_BRIENDORSEMENTPOLICY: string = "/BRIEndorsementPolicy";

	static STRING_URL_BRIENDORSEMENTPOLICY_INSERTENDORSEMENT: string = `${WebAddressConstant.STRING_URL_POLICY_API}${WebAddressConstant.STRING_PATH_CONTROLLER_BRIENDORSEMENTPOLICY}/insertEndorsement`;
	static STRING_URL_BRIENDORSEMENTPOLICY_UPLOADENDORSEMENT: string = `${WebAddressConstant.STRING_URL_POLICY_API}${WebAddressConstant.STRING_PATH_CONTROLLER_BRIENDORSEMENTPOLICY}/uploadEndorsement`;
	static STRING_URL_BRIENDORSEMENTPOLICY_SELECTENDORSEMENTBYATTRIBUTES: string = `${WebAddressConstant.STRING_URL_POLICY_API}${WebAddressConstant.STRING_PATH_CONTROLLER_BRIENDORSEMENTPOLICY}/selectEndorsementByAttributes`;
	static STRING_URL_BRIENDORSEMENTPOLICY_SELECTENDORSEMENTBYTOKEN: string = `${WebAddressConstant.STRING_URL_POLICY_API}${WebAddressConstant.STRING_PATH_CONTROLLER_BRIENDORSEMENTPOLICY}/selectEndorsementByToken`;
	static STRING_URL_BRIENDORSEMENTPOLICY_SELECTENDORSEMENTAPPROVAL: string = `${WebAddressConstant.STRING_URL_POLICY_API}${WebAddressConstant.STRING_PATH_CONTROLLER_BRIENDORSEMENTPOLICY}/selectEndorsementApproval`;
	static STRING_URL_BRIENDORSEMENTPOLICY_APPROVEENDORSEMENTBYTOKEN: string = `${WebAddressConstant.STRING_URL_POLICY_API}${WebAddressConstant.STRING_PATH_CONTROLLER_BRIENDORSEMENTPOLICY}/approveEndorsementByToken`;
	static STRING_URL_BRIENDORSEMENTPOLICY_REJECTENDORSEMENTBYTOKEN: string = `${WebAddressConstant.STRING_URL_POLICY_API}${WebAddressConstant.STRING_PATH_CONTROLLER_BRIENDORSEMENTPOLICY}/rejectEndorsementByToken`;

	//#endregion


	//#region PRODUCT CONTROLLER

	static STRING_PATH_CONTROLLER_PRODUCT: string = "/Product";

	static STRING_URL_PRODUCT_SELECTPRODUCTBYATTRIBUTES: string = `${WebAddressConstant.STRING_URL_PRODUCT_API}${WebAddressConstant.STRING_PATH_CONTROLLER_PRODUCT}/selectProductByAttributes`;
	static STRING_URL_PRODUCT_SELECTPRODUCTBYTOKEN: string = `${WebAddressConstant.STRING_URL_PRODUCT_API}${WebAddressConstant.STRING_PATH_CONTROLLER_PRODUCT}/selectProductByToken`;

	//#endregion


	//#region PRODUCT CONTROLLER

	static STRING_PATH_CONTROLLER_PRODUCTGENERAL: string = "/ProductGeneral";

	static STRING_URL_PRODUCTGENERAL_SELECTPRODUCTDRAFTBYCUSTOMER: string = `${WebAddressConstant.STRING_URL_PRODUCT_API}${WebAddressConstant.STRING_PATH_CONTROLLER_PRODUCTGENERAL}/selectProductDraftByCustomer`;
	static STRING_URL_PRODUCTGENERAL_SELECTPRODUCTPENDINGBYCUSTOMER: string = `${WebAddressConstant.STRING_URL_PRODUCT_API}${WebAddressConstant.STRING_PATH_CONTROLLER_PRODUCTGENERAL}/selectProductPendingByCustomer`;
	static STRING_URL_PRODUCTGENERAL_SELECTPRODUCTGENERALBYCUSTOMER: string = `${WebAddressConstant.STRING_URL_PRODUCT_API}${WebAddressConstant.STRING_PATH_CONTROLLER_PRODUCTGENERAL}/selectProductGeneralByCustomer`;
	static STRING_URL_PRODUCTGENERAL_SELECTPRODUCTGENERALBYATTRIBUTEFORCUSTOMER: string = `${WebAddressConstant.STRING_URL_PRODUCT_API}${WebAddressConstant.STRING_PATH_CONTROLLER_PRODUCTGENERAL}/selectProductGeneralByAttributeForCustomer`;

	//#endregion


	//#region PRODUCT ASRI CONTROLLER

	static STRING_PATH_CONTROLLER_PRODUCTASRI: string = "/ProductAsri";

	static STRING_URL_PRODUCT_CALCULATEPREMIUMASRIFORCUSTOMER: string = `${WebAddressConstant.STRING_URL_PRODUCT_API}${WebAddressConstant.STRING_PATH_CONTROLLER_PRODUCTASRI}/calculatePremiumForCustomer`;
	static STRING_URL_PRODUCT_INSERTPRODUCTFORCUSTOMER: string = `${WebAddressConstant.STRING_URL_PRODUCT_API}${WebAddressConstant.STRING_PATH_CONTROLLER_PRODUCTASRI}/insertProductForCustomer`;
	static STRING_URL_PRODUCT_UPDATEINSUREDFORCUSTOMER: string = `${WebAddressConstant.STRING_URL_PRODUCT_API}${WebAddressConstant.STRING_PATH_CONTROLLER_PRODUCTASRI}/updateInsuredForCustomer`;
	static STRING_URL_PRODUCT_UPLOADPRODUCTFORCUSTOMER: string = `${WebAddressConstant.STRING_URL_PRODUCT_API}${WebAddressConstant.STRING_PATH_CONTROLLER_PRODUCTASRI}/uploadProductForWebsiteCustomer`;
	static STRING_URL_PRODUCT_SELECRPRODUCTBYTOKENFORCUSTOMER: string = `${WebAddressConstant.STRING_URL_PRODUCT_API}${WebAddressConstant.STRING_PATH_CONTROLLER_PRODUCTASRI}/selectProductByTokenForCustomer`;
	static STRING_URL_PRODUCT_SAVEPRODUCTFORCUSTOMER: string = `${WebAddressConstant.STRING_URL_PRODUCT_API}${WebAddressConstant.STRING_PATH_CONTROLLER_PRODUCTASRI}/saveProductForWebsiteCustomer`;
	static STRING_URL_PRODUCT_SENDSPPABYEMAILFORCUSTOMER: string = `${WebAddressConstant.STRING_URL_PRODUCT_API}${WebAddressConstant.STRING_PATH_CONTROLLER_PRODUCTASRI}/sendSPPAByEmailForCustomer`;
	static STRING_URL_PRODUCT_ASRICONFIRMPAYMENT: string = `${WebAddressConstant.STRING_URL_PRODUCT_API}${WebAddressConstant.STRING_PATH_CONTROLLER_PRODUCTASRI}/confirmPayment`;
	static STRING_URL_PRODUCT_ASRISHAREPAYMENTFORCUSTOMER: string = `${WebAddressConstant.STRING_URL_PRODUCT_API}${WebAddressConstant.STRING_PATH_CONTROLLER_PRODUCTASRI}/sharePaymentForCustomer`;
	static STRING_URL_PRODUCT_ASRIGENERATEPAYMENTBRIVA: string = `${WebAddressConstant.STRING_URL_PRODUCT_API}${WebAddressConstant.STRING_PATH_CONTROLLER_PRODUCTASRI}/generatePaymentBRIVA`;
	static STRING_URL_PRODUCT_ASRIGENERATEPAYMENTBNI: string = `${WebAddressConstant.STRING_URL_PRODUCT_API}${WebAddressConstant.STRING_PATH_CONTROLLER_PRODUCTASRI}/generatePaymentBankTransferBNIWithMidtrans`;
	static STRING_URL_PRODUCT_ASRIGENERATEPAYMENTBCA: string = `${WebAddressConstant.STRING_URL_PRODUCT_API}${WebAddressConstant.STRING_PATH_CONTROLLER_PRODUCTASRI}/generatePaymentBankTransferBCAWithMidtrans`;
	static STRING_URL_PRODUCT_ASRIGENERATEPAYMENTMANDIRI: string = `${WebAddressConstant.STRING_URL_PRODUCT_API}${WebAddressConstant.STRING_PATH_CONTROLLER_PRODUCTASRI}/generatePaymentBankTransferMandiriWithMidtrans`;
	static STRING_URL_PRODUCT_ASRIGENERATEPAYMENTPERMATA: string = `${WebAddressConstant.STRING_URL_PRODUCT_API}${WebAddressConstant.STRING_PATH_CONTROLLER_PRODUCTASRI}/generatePaymentBankTransferPermataWithMidtrans`;
	static STRING_URL_PRODUCT_ASRIGENERATEPAYMENTQRIS: string = `${WebAddressConstant.STRING_URL_PRODUCT_API}${WebAddressConstant.STRING_PATH_CONTROLLER_PRODUCTASRI}/generatePaymentQRISWithMidtrans`;
	static STRING_URL_PRODUCTASRI_GENERATEPAYMENTINDOMARET: string = `${WebAddressConstant.STRING_URL_PRODUCT_API}${WebAddressConstant.STRING_PATH_CONTROLLER_PRODUCTASRI}/generatePaymentRetailStoreIndomaretWithMidtrans`;
	static STRING_URL_PRODUCTASRI_GENERATEPAYMENTALFAMART: string = `${WebAddressConstant.STRING_URL_PRODUCT_API}${WebAddressConstant.STRING_PATH_CONTROLLER_PRODUCTASRI}/generatePaymentRetailStoreAlfaGroupWithMidtrans`;
	static STRING_URL_PRODUCTASRI_GENERATEPAYMENTSHOPEEPAY: string = `${WebAddressConstant.STRING_URL_PRODUCT_API}${WebAddressConstant.STRING_PATH_CONTROLLER_PRODUCTASRI}/generatePaymentDeeplinkShopeePayWithMidtrans`;
	static STRING_URL_PRODUCTASRI_GENERATEPAYMENTCREDITCARD: string = `${WebAddressConstant.STRING_URL_PRODUCT_API}${WebAddressConstant.STRING_PATH_CONTROLLER_PRODUCTASRI}/generatePaymentCreditCardWithMidtrans`;

	//#endregion


	//#region PRODUCT ASRI SYARIAH CONTROLLER

	static STRING_PATH_CONTROLLER_PRODUCTASRISYARIAH: string = "/ProductAsriSyariah";

	static STRING_URL_PRODUCT_SELECTCALCULATEPREMIUMASRISYARIAHFOROPERATIONANDSUPPORT: string = `${WebAddressConstant.STRING_URL_PRODUCT_API}${WebAddressConstant.STRING_PATH_CONTROLLER_PRODUCTASRISYARIAH}/calculatePremiumForOperationAndSupport`;
	static STRING_URL_PRODUCT_SENDCALCULATEPREMIUMASRISYARIAHBYEMAILFOROPERATIONANDSUPPORT: string = `${WebAddressConstant.STRING_URL_PRODUCT_API}${WebAddressConstant.STRING_PATH_CONTROLLER_PRODUCTASRISYARIAH}/sendCalculatePremiumByEmailForOperationAndSupport`;

	//#endregion


	//#region PRODUCT OTO CONTROLLER

	static STRING_PATH_CONTROLLER_PRODUCTOTO: string = "/ProductOto";

	static STRING_URL_PRODUCT_SELECTCALCULATEPREMIUMOTO: string = `${WebAddressConstant.STRING_URL_PRODUCT_API}${WebAddressConstant.STRING_PATH_CONTROLLER_PRODUCTOTO}/calculatePremium`;
	static STRING_URL_PRODUCT_SENDCALCULATEPREMIUMOTOBYEMAILFOROPERATIONANDSUPPORT: string = `${WebAddressConstant.STRING_URL_PRODUCT_API}${WebAddressConstant.STRING_PATH_CONTROLLER_PRODUCTOTO}/sendCalculatePremiumByEmailForOperationAndSupport`;
	static STRING_URL_PRODUCTOTO_SENDSPPABYEMAILFORCUSTOMER: string = `${WebAddressConstant.STRING_URL_PRODUCT_API}${WebAddressConstant.STRING_PATH_CONTROLLER_PRODUCTOTO}/sendSPPAByEmailForCustomer`;
	static STRING_URL_PRODUCTOTO_CALCULATEPREMIUMFORCUSTOMER: string = `${WebAddressConstant.STRING_URL_PRODUCT_API}${WebAddressConstant.STRING_PATH_CONTROLLER_PRODUCTOTO}/calculatePremiumForCustomer`;
	static STRING_URL_PRODUCTOTO_INSERTPRODUCTFORCUSTOMER: string = `${WebAddressConstant.STRING_URL_PRODUCT_API}${WebAddressConstant.STRING_PATH_CONTROLLER_PRODUCTOTO}/insertProductForCustomer`;
	static STRING_URL_PRODUCTOTO_UPLOADPRODUCTFORCUSTOMER: string = `${WebAddressConstant.STRING_URL_PRODUCT_API}${WebAddressConstant.STRING_PATH_CONTROLLER_PRODUCTOTO}/uploadProductForCustomer`;
	static STRING_URL_PRODUCTOTO_UPDATEINSUREDFORCUSTOMER: string = `${WebAddressConstant.STRING_URL_PRODUCT_API}${WebAddressConstant.STRING_PATH_CONTROLLER_PRODUCTOTO}/updateInsuredForCustomer`;
	static STRING_URL_PRODUCTOTO_UPLOADPRODUCTFORWEBSITECUSTOMER: string = `${WebAddressConstant.STRING_URL_PRODUCT_API}${WebAddressConstant.STRING_PATH_CONTROLLER_PRODUCTOTO}/uploadProductForWebsiteCustomer`;
	static STRING_URL_PRODUCTOTO_SAVEPRODUCTFORWEBSITECUSTOMER: string = `${WebAddressConstant.STRING_URL_PRODUCT_API}${WebAddressConstant.STRING_PATH_CONTROLLER_PRODUCTOTO}/saveProductForWebsiteCustomer`;
	static STRING_URL_PRODUCTOTO_SELECTPRODUCTBYTOKENFORCUSTOMER: string = `${WebAddressConstant.STRING_URL_PRODUCT_API}${WebAddressConstant.STRING_PATH_CONTROLLER_PRODUCTOTO}/selectProductByTokenForCustomer`;
	static STRING_URL_PRODUCT_OTOCONFIRMPAYMENT: string = `${WebAddressConstant.STRING_URL_PRODUCT_API}${WebAddressConstant.STRING_PATH_CONTROLLER_PRODUCTOTO}/confirmPayment`;
	static STRING_URL_PRODUCT_OTOSHAREPAYMENTFORCUSTOMER: string = `${WebAddressConstant.STRING_URL_PRODUCT_API}${WebAddressConstant.STRING_PATH_CONTROLLER_PRODUCTOTO}/sharePaymentForCustomer`;
	static STRING_URL_PRODUCT_OTOGENERATEPAYMENTBRIVA: string = `${WebAddressConstant.STRING_URL_PRODUCT_API}${WebAddressConstant.STRING_PATH_CONTROLLER_PRODUCTOTO}/generatePaymentBRIVA`;
	static STRING_URL_PRODUCT_OTOGENERATEPAYMENTBNI: string = `${WebAddressConstant.STRING_URL_PRODUCT_API}${WebAddressConstant.STRING_PATH_CONTROLLER_PRODUCTOTO}/generatePaymentBankTransferBNIWithMidtrans`;
	static STRING_URL_PRODUCT_OTOGENERATEPAYMENTBCA: string = `${WebAddressConstant.STRING_URL_PRODUCT_API}${WebAddressConstant.STRING_PATH_CONTROLLER_PRODUCTOTO}/generatePaymentBankTransferBCAWithMidtrans`;
	static STRING_URL_PRODUCT_OTOGENERATEPAYMENTMANDIRI: string = `${WebAddressConstant.STRING_URL_PRODUCT_API}${WebAddressConstant.STRING_PATH_CONTROLLER_PRODUCTOTO}/generatePaymentBankTransferMandiriWithMidtrans`;
	static STRING_URL_PRODUCT_OTOGENERATEPAYMENTPERMATA: string = `${WebAddressConstant.STRING_URL_PRODUCT_API}${WebAddressConstant.STRING_PATH_CONTROLLER_PRODUCTOTO}/generatePaymentBankTransferPermataWithMidtrans`;
	static STRING_URL_PRODUCT_OTOGENERATEPAYMENTQRIS: string = `${WebAddressConstant.STRING_URL_PRODUCT_API}${WebAddressConstant.STRING_PATH_CONTROLLER_PRODUCTOTO}/generatePaymentQRISWithMidtrans`;
	static STRING_URL_PRODUCTOTO_GENERATEPAYMENTINDOMARET: string = `${WebAddressConstant.STRING_URL_PRODUCT_API}${WebAddressConstant.STRING_PATH_CONTROLLER_PRODUCTOTO}/generatePaymentRetailStoreIndomaretWithMidtrans`;
	static STRING_URL_PRODUCTOTO_GENERATEPAYMENTALFAMART: string = `${WebAddressConstant.STRING_URL_PRODUCT_API}${WebAddressConstant.STRING_PATH_CONTROLLER_PRODUCTOTO}/generatePaymentRetailStoreAlfaGroupWithMidtrans`;
	static STRING_URL_PRODUCTOTO_GENERATEPAYMENTSHOPEEPAY: string = `${WebAddressConstant.STRING_URL_PRODUCT_API}${WebAddressConstant.STRING_PATH_CONTROLLER_PRODUCTOTO}/generatePaymentDeeplinkShopeePayWithMidtrans`;
	static STRING_URL_PRODUCTOTO_GENERATEPAYMENTCREDITCARD: string = `${WebAddressConstant.STRING_URL_PRODUCT_API}${WebAddressConstant.STRING_PATH_CONTROLLER_PRODUCTOTO}/generatePaymentCreditCardWithMidtrans`;

	//#endregion


	//#region PRODUCT OTO SYARIAH CONTROLLER

	static STRING_PATH_CONTROLLER_PRODUCTOTOSYARIAH: string = "/ProductOtoSyariah";

	static STRING_URL_PRODUCT_SELECTCALCULATEPREMIUMOTOSYARIAHFOROPERATIONANDSUPPORT: string = `${WebAddressConstant.STRING_URL_PRODUCT_API}${WebAddressConstant.STRING_PATH_CONTROLLER_PRODUCTOTOSYARIAH}/calculatePremiumForOperationAndSupport`;
	static STRING_URL_PRODUCT_SENDCALCULATEPREMIUMOTOSYARIAHBYEMAILFOROPERATIONANDSUPPORT: string = `${WebAddressConstant.STRING_URL_PRODUCT_API}${WebAddressConstant.STRING_PATH_CONTROLLER_PRODUCTOTOSYARIAH}/sendCalculatePremiumByEmailForOperationAndSupport`;

	//#endregion


	//#region PRODUCT PERSONAL ACCIDENT CONTROLLER

	static STRING_PATH_CONTROLLER_PRODUCTPERSONALACCIDENT: string = "/ProductPersonalAccident";

	static STRING_URL_PRODUCT_SELECTCALCULATEPREMIUMPERSONALACCIDENT: string = `${WebAddressConstant.STRING_URL_PRODUCT_API}${WebAddressConstant.STRING_PATH_CONTROLLER_PRODUCTPERSONALACCIDENT}/calculatePremium`;
	static STRING_URL_PRODUCT_SENDCALCULATEPREMIUMPERSONALACCIDENTBYEMAILFOROPERATIONANDSUPPORT: string = `${WebAddressConstant.STRING_URL_PRODUCT_API}${WebAddressConstant.STRING_PATH_CONTROLLER_PRODUCTPERSONALACCIDENT}/sendCalculatePremiumByEmailForOperationAndSupport`;
	static STRING_URL_PRODUCTPERSONALACCIDENT_SENDSPPABYEMAILFORCUSTOMER: string = `${WebAddressConstant.STRING_URL_PRODUCT_API}${WebAddressConstant.STRING_PATH_CONTROLLER_PRODUCTPERSONALACCIDENT}/sendSPPAByEmailForCustomer`;
	static STRING_URL_PRODUCTPERSONALACCIDENT_CALCULATEPREMIUMFORCUSTOMER: string = `${WebAddressConstant.STRING_URL_PRODUCT_API}${WebAddressConstant.STRING_PATH_CONTROLLER_PRODUCTPERSONALACCIDENT}/calculatePremiumForCustomer`;
	static STRING_URL_PRODUCTPERSONALACCIDENT_INSERTPRODUCTFORCUSTOMER: string = `${WebAddressConstant.STRING_URL_PRODUCT_API}${WebAddressConstant.STRING_PATH_CONTROLLER_PRODUCTPERSONALACCIDENT}/insertProductForCustomer`;
	static STRING_URL_PRODUCTPERSONALACCIDENT_UPLOADPRODUCTFORCUSTOMER: string = `${WebAddressConstant.STRING_URL_PRODUCT_API}${WebAddressConstant.STRING_PATH_CONTROLLER_PRODUCTPERSONALACCIDENT}/uploadProductForCustomer`;
	static STRING_URL_PRODUCTPERSONALACCIDENT_UPDATEINSUREDFORCUSTOMER: string = `${WebAddressConstant.STRING_URL_PRODUCT_API}${WebAddressConstant.STRING_PATH_CONTROLLER_PRODUCTPERSONALACCIDENT}/updateInsuredForCustomer`;
	static STRING_URL_PRODUCTPERSONALACCIDENT_UPDATEBENEFICIARIESPERSONALACCIDENT: string = `${WebAddressConstant.STRING_URL_PRODUCT_API}${WebAddressConstant.STRING_PATH_CONTROLLER_PRODUCTPERSONALACCIDENT}/updateBeneficiariesForCustomer`;
	static STRING_URL_PRODUCTPERSONALACCIDENT_UPDATEQUESTIONNAIREPERSONALACCIDENT: string = `${WebAddressConstant.STRING_URL_PRODUCT_API}${WebAddressConstant.STRING_PATH_CONTROLLER_PRODUCTPERSONALACCIDENT}/updateQuestionnaireForCustomer`;
	static STRING_URL_PRODUCTPERSONALACCIDENT_UPLOADPRODUCTFORWEBSITECUSTOMER: string = `${WebAddressConstant.STRING_URL_PRODUCT_API}${WebAddressConstant.STRING_PATH_CONTROLLER_PRODUCTPERSONALACCIDENT}/uploadProductForWebsiteCustomer`;
	static STRING_URL_PRODUCTPERSONALACCIDENT_SAVEPRODUCTFORWEBSITECUSTOMER: string = `${WebAddressConstant.STRING_URL_PRODUCT_API}${WebAddressConstant.STRING_PATH_CONTROLLER_PRODUCTPERSONALACCIDENT}/saveProductForWebsiteCustomer`;
	static STRING_URL_PRODUCTPERSONALACCIDENT_SELECTPRODUCTBYTOKENFORCUSTOMER: string = `${WebAddressConstant.STRING_URL_PRODUCT_API}${WebAddressConstant.STRING_PATH_CONTROLLER_PRODUCTPERSONALACCIDENT}/selectProductByTokenForCustomer`;
	static STRING_URL_PRODUCT_PERSONALACCIDENTCONFIRMPAYMENT: string = `${WebAddressConstant.STRING_URL_PRODUCT_API}${WebAddressConstant.STRING_PATH_CONTROLLER_PRODUCTPERSONALACCIDENT}/confirmPayment`;
	static STRING_URL_PRODUCT_PERSONALACCIDENTSHAREPAYMENTFORCUSTOMER: string = `${WebAddressConstant.STRING_URL_PRODUCT_API}${WebAddressConstant.STRING_PATH_CONTROLLER_PRODUCTPERSONALACCIDENT}/sharePaymentForCustomer`;
	static STRING_URL_PRODUCT_PERSONALACCIDENTGENERATEPAYMENTBRIVA: string = `${WebAddressConstant.STRING_URL_PRODUCT_API}${WebAddressConstant.STRING_PATH_CONTROLLER_PRODUCTPERSONALACCIDENT}/generatePaymentBRIVA`;
	static STRING_URL_PRODUCT_PERSONALACCIDENTGENERATEPAYMENTBNI: string = `${WebAddressConstant.STRING_URL_PRODUCT_API}${WebAddressConstant.STRING_PATH_CONTROLLER_PRODUCTPERSONALACCIDENT}/generatePaymentBankTransferBNIWithMidtrans`;
	static STRING_URL_PRODUCT_PERSONALACCIDENTGENERATEPAYMENTBCA: string = `${WebAddressConstant.STRING_URL_PRODUCT_API}${WebAddressConstant.STRING_PATH_CONTROLLER_PRODUCTPERSONALACCIDENT}/generatePaymentBankTransferBCAWithMidtrans`;
	static STRING_URL_PRODUCT_PERSONALACCIDENTGENERATEPAYMENTMANDIRI: string = `${WebAddressConstant.STRING_URL_PRODUCT_API}${WebAddressConstant.STRING_PATH_CONTROLLER_PRODUCTPERSONALACCIDENT}/generatePaymentBankTransferMandiriWithMidtrans`;
	static STRING_URL_PRODUCT_PERSONALACCIDENTGENERATEPAYMENTPERMATA: string = `${WebAddressConstant.STRING_URL_PRODUCT_API}${WebAddressConstant.STRING_PATH_CONTROLLER_PRODUCTPERSONALACCIDENT}/generatePaymentBankTransferPermataWithMidtrans`;
	static STRING_URL_PRODUCT_PERSONALACCIDENTGENERATEPAYMENTQRIS: string = `${WebAddressConstant.STRING_URL_PRODUCT_API}${WebAddressConstant.STRING_PATH_CONTROLLER_PRODUCTPERSONALACCIDENT}/generatePaymentQRISWithMidtrans`;
	static STRING_URL_PRODUCT_PERSONALACCIDENTGENERATEPAYMENTINDOMARET: string = `${WebAddressConstant.STRING_URL_PRODUCT_API}${WebAddressConstant.STRING_PATH_CONTROLLER_PRODUCTPERSONALACCIDENT}/generatePaymentRetailStoreIndomaretWithMidtrans`;
	static STRING_URL_PRODUCT_PERSONALACCIDENTGENERATEPAYMENTALFAMART: string = `${WebAddressConstant.STRING_URL_PRODUCT_API}${WebAddressConstant.STRING_PATH_CONTROLLER_PRODUCTPERSONALACCIDENT}/generatePaymentRetailStoreAlfaGroupWithMidtrans`;
	static STRING_URL_PRODUCT_PERSONALACCIDENTGENERATEPAYMENTSHOPEEPAY: string = `${WebAddressConstant.STRING_URL_PRODUCT_API}${WebAddressConstant.STRING_PATH_CONTROLLER_PRODUCTPERSONALACCIDENT}/generatePaymentDeeplinkShopeePayWithMidtrans`;
	static STRING_URL_PRODUCTPERSONALACCIDENT_GENERATEPAYMENTCREDITCARD: string = `${WebAddressConstant.STRING_URL_PRODUCT_API}${WebAddressConstant.STRING_PATH_CONTROLLER_PRODUCTPERSONALACCIDENT}/generatePaymentCreditCardWithMidtrans`;

	//#endregion


	//#region PRODUCT BICYCLE CONTROLLER

	static STRING_PATH_CONTROLLER_PRODUCTBICYCLE: string = "/ProductBicycle";

	static STRING_URL_PRODUCT_SELECTCALCULATEPREMIUMBICYCLE: string = `${WebAddressConstant.STRING_URL_PRODUCT_API}${WebAddressConstant.STRING_PATH_CONTROLLER_PRODUCTBICYCLE}/calculatePremium`;
	static STRING_URL_PRODUCT_SENDCALCULATEPREMIUMBICYCLEBYEMAILFOROPERATIONANDSUPPORT: string = `${WebAddressConstant.STRING_URL_PRODUCT_API}${WebAddressConstant.STRING_PATH_CONTROLLER_PRODUCTBICYCLE}/sendCalculatePremiumByEmailForOperationAndSupport`;
	static STRING_URL_PRODUCTBICYCLE_SENDSPPABYEMAILFORCUSTOMER: string = `${WebAddressConstant.STRING_URL_PRODUCT_API}${WebAddressConstant.STRING_PATH_CONTROLLER_PRODUCTBICYCLE}/sendSPPAByEmailForCustomer`;
	static STRING_URL_PRODUCTBICYCLE_CALCULATEPREMIUMFORCUSTOMER: string = `${WebAddressConstant.STRING_URL_PRODUCT_API}${WebAddressConstant.STRING_PATH_CONTROLLER_PRODUCTBICYCLE}/calculatePremiumForCustomer`;
	static STRING_URL_PRODUCTBICYCLE_INSERTPRODUCTFORCUSTOMER: string = `${WebAddressConstant.STRING_URL_PRODUCT_API}${WebAddressConstant.STRING_PATH_CONTROLLER_PRODUCTBICYCLE}/insertProductForCustomer`;
	static STRING_URL_PRODUCTBICYCLE_UPLOADPRODUCTFORCUSTOMER: string = `${WebAddressConstant.STRING_URL_PRODUCT_API}${WebAddressConstant.STRING_PATH_CONTROLLER_PRODUCTBICYCLE}/uploadProductForCustomer`;
	static STRING_URL_PRODUCTBICYCLE_UPDATEINSUREDFORCUSTOMER: string = `${WebAddressConstant.STRING_URL_PRODUCT_API}${WebAddressConstant.STRING_PATH_CONTROLLER_PRODUCTBICYCLE}/updateInsuredForCustomer`;
	static STRING_URL_PRODUCTBICYCLE_UPDATEBENEFICIARIESBICYCLE: string = `${WebAddressConstant.STRING_URL_PRODUCT_API}${WebAddressConstant.STRING_PATH_CONTROLLER_PRODUCTBICYCLE}/updateBeneficiariesForCustomer`;
	static STRING_URL_PRODUCTBICYCLE_UPDATEQUESTIONNAIREBICYCLE: string = `${WebAddressConstant.STRING_URL_PRODUCT_API}${WebAddressConstant.STRING_PATH_CONTROLLER_PRODUCTBICYCLE}/updateQuestionnaireForCustomer`;
	static STRING_URL_PRODUCTBICYCLE_UPLOADPRODUCTFORWEBSITECUSTOMER: string = `${WebAddressConstant.STRING_URL_PRODUCT_API}${WebAddressConstant.STRING_PATH_CONTROLLER_PRODUCTBICYCLE}/uploadProductForWebsiteCustomer`;
	static STRING_URL_PRODUCTBICYCLE_SAVEPRODUCTFORWEBSITECUSTOMER: string = `${WebAddressConstant.STRING_URL_PRODUCT_API}${WebAddressConstant.STRING_PATH_CONTROLLER_PRODUCTBICYCLE}/saveProductForWebsiteCustomer`;
	static STRING_URL_PRODUCTBICYCLE_SELECTPRODUCTBYTOKENFORCUSTOMER: string = `${WebAddressConstant.STRING_URL_PRODUCT_API}${WebAddressConstant.STRING_PATH_CONTROLLER_PRODUCTBICYCLE}/selectProductByTokenForCustomer`;
	static STRING_URL_PRODUCT_BICYCLECONFIRMPAYMENT: string = `${WebAddressConstant.STRING_URL_PRODUCT_API}${WebAddressConstant.STRING_PATH_CONTROLLER_PRODUCTBICYCLE}/confirmPayment`;
	static STRING_URL_PRODUCT_BICYCLESHAREPAYMENTFORCUSTOMER: string = `${WebAddressConstant.STRING_URL_PRODUCT_API}${WebAddressConstant.STRING_PATH_CONTROLLER_PRODUCTBICYCLE}/sharePaymentForCustomer`;
	static STRING_URL_PRODUCT_BICYCLEGENERATEPAYMENTBRIVA: string = `${WebAddressConstant.STRING_URL_PRODUCT_API}${WebAddressConstant.STRING_PATH_CONTROLLER_PRODUCTBICYCLE}/generatePaymentBRIVA`;
	static STRING_URL_PRODUCT_BICYCLEGENERATEPAYMENTBNI: string = `${WebAddressConstant.STRING_URL_PRODUCT_API}${WebAddressConstant.STRING_PATH_CONTROLLER_PRODUCTBICYCLE}/generatePaymentBankTransferBNIWithMidtrans`;
	static STRING_URL_PRODUCT_BICYCLEGENERATEPAYMENTBCA: string = `${WebAddressConstant.STRING_URL_PRODUCT_API}${WebAddressConstant.STRING_PATH_CONTROLLER_PRODUCTBICYCLE}/generatePaymentBankTransferBCAWithMidtrans`;
	static STRING_URL_PRODUCT_BICYCLEGENERATEPAYMENTMANDIRI: string = `${WebAddressConstant.STRING_URL_PRODUCT_API}${WebAddressConstant.STRING_PATH_CONTROLLER_PRODUCTBICYCLE}/generatePaymentBankTransferMandiriWithMidtrans`;
	static STRING_URL_PRODUCT_BICYCLEGENERATEPAYMENTPERMATA: string = `${WebAddressConstant.STRING_URL_PRODUCT_API}${WebAddressConstant.STRING_PATH_CONTROLLER_PRODUCTBICYCLE}/generatePaymentBankTransferPermataWithMidtrans`;
	static STRING_URL_PRODUCT_BICYCLEGENERATEPAYMENTQRIS: string = `${WebAddressConstant.STRING_URL_PRODUCT_API}${WebAddressConstant.STRING_PATH_CONTROLLER_PRODUCTBICYCLE}/generatePaymentQRISWithMidtrans`;
	static STRING_URL_PRODUCT_BICYCLEGENERATEPAYMENTINDOMARET: string = `${WebAddressConstant.STRING_URL_PRODUCT_API}${WebAddressConstant.STRING_PATH_CONTROLLER_PRODUCTBICYCLE}/generatePaymentRetailStoreIndomaretWithMidtrans`;
	static STRING_URL_PRODUCT_BICYCLEGENERATEPAYMENTALFAMART: string = `${WebAddressConstant.STRING_URL_PRODUCT_API}${WebAddressConstant.STRING_PATH_CONTROLLER_PRODUCTBICYCLE}/generatePaymentRetailStoreAlfaGroupWithMidtrans`;
	static STRING_URL_PRODUCT_BICYCLEGENERATEPAYMENTSHOPEEPAY: string = `${WebAddressConstant.STRING_URL_PRODUCT_API}${WebAddressConstant.STRING_PATH_CONTROLLER_PRODUCTBICYCLE}/generatePaymentDeeplinkShopeePayWithMidtrans`;
	static STRING_URL_PRODUCTBICYCLE_GENERATEPAYMENTCREDITCARD: string = `${WebAddressConstant.STRING_URL_PRODUCT_API}${WebAddressConstant.STRING_PATH_CONTROLLER_PRODUCTBICYCLE}/generatePaymentCreditCardWithMidtrans`;

	//#endregion


	//#region PRODUCT ASMIK KTU CONTROLLER

	static STRING_PATH_CONTROLLER_PRODUCTDAMAGEOFBUSINESSPLACE: string = "/ProductDamageOfBusinessPlace";

	static STRING_URL_PRODUCTDAMAGEOFBUSINESSPLACE_SELECTCALCULATEPREMIUM: string = `${WebAddressConstant.STRING_URL_PRODUCT_API}${WebAddressConstant.STRING_PATH_CONTROLLER_PRODUCTDAMAGEOFBUSINESSPLACE}/calculatePremium`;
	static STRING_URL_PRODUCTDAMAGEOFBUSINESSPLACE_SENDSPPABYEMAILFORCUSTOMER: string = `${WebAddressConstant.STRING_URL_PRODUCT_API}${WebAddressConstant.STRING_PATH_CONTROLLER_PRODUCTDAMAGEOFBUSINESSPLACE}/sendSPPAByEmailForCustomer`;
	static STRING_URL_PRODUCTDAMAGEOFBUSINESSPLACE_CALCULATEPREMIUMFORCUSTOMER: string = `${WebAddressConstant.STRING_URL_PRODUCT_API}${WebAddressConstant.STRING_PATH_CONTROLLER_PRODUCTDAMAGEOFBUSINESSPLACE}/calculatePremiumForCustomer`;
	static STRING_URL_PRODUCTDAMAGEOFBUSINESSPLACE_INSERTPRODUCTFORCUSTOMER: string = `${WebAddressConstant.STRING_URL_PRODUCT_API}${WebAddressConstant.STRING_PATH_CONTROLLER_PRODUCTDAMAGEOFBUSINESSPLACE}/insertProductForCustomer`;
	static STRING_URL_PRODUCTDAMAGEOFBUSINESSPLACE_UPLOADPRODUCTFORCUSTOMER: string = `${WebAddressConstant.STRING_URL_PRODUCT_API}${WebAddressConstant.STRING_PATH_CONTROLLER_PRODUCTDAMAGEOFBUSINESSPLACE}/uploadProductForCustomer`;
	static STRING_URL_PRODUCTDAMAGEOFBUSINESSPLACE_UPDATEINSUREDFORWEBSITECUSTOMER: string = `${WebAddressConstant.STRING_URL_PRODUCT_API}${WebAddressConstant.STRING_PATH_CONTROLLER_PRODUCTDAMAGEOFBUSINESSPLACE}/updateInsuredForWebsiteCustomer`;
	static STRING_URL_PRODUCTDAMAGEOFBUSINESSPLACE_UPDATEBENEFICIARIESFORWEBSITECUSTOMER: string = `${WebAddressConstant.STRING_URL_PRODUCT_API}${WebAddressConstant.STRING_PATH_CONTROLLER_PRODUCTDAMAGEOFBUSINESSPLACE}/updateBeneficiariesForWebsiteCustomer`;
	static STRING_URL_PRODUCTDAMAGEOFBUSINESSPLACE_UPLOADPRODUCTFORWEBSITECUSTOMER: string = `${WebAddressConstant.STRING_URL_PRODUCT_API}${WebAddressConstant.STRING_PATH_CONTROLLER_PRODUCTDAMAGEOFBUSINESSPLACE}/uploadProductForWebsiteCustomer`;
	static STRING_URL_PRODUCTDAMAGEOFBUSINESSPLACE_SAVEPRODUCTFORWEBSITECUSTOMER: string = `${WebAddressConstant.STRING_URL_PRODUCT_API}${WebAddressConstant.STRING_PATH_CONTROLLER_PRODUCTDAMAGEOFBUSINESSPLACE}/saveProductForWebsiteCustomer`;
	static STRING_URL_PRODUCTDAMAGEOFBUSINESSPLACE_SELECTPRODUCTBYTOKENFORCUSTOMER: string = `${WebAddressConstant.STRING_URL_PRODUCT_API}${WebAddressConstant.STRING_PATH_CONTROLLER_PRODUCTDAMAGEOFBUSINESSPLACE}/selectProductByTokenForCustomer`;
	static STRING_URL_PRODUCTDAMAGEOFBUSINESSPLACE_CONFIRMPAYMENT: string = `${WebAddressConstant.STRING_URL_PRODUCT_API}${WebAddressConstant.STRING_PATH_CONTROLLER_PRODUCTDAMAGEOFBUSINESSPLACE}/confirmPayment`;
	static STRING_URL_PRODUCTDAMAGEOFBUSINESSPLACE_SHAREPAYMENTFORCUSTOMER: string = `${WebAddressConstant.STRING_URL_PRODUCT_API}${WebAddressConstant.STRING_PATH_CONTROLLER_PRODUCTDAMAGEOFBUSINESSPLACE}/sharePaymentForCustomer`;
	static STRING_URL_PRODUCTDAMAGEOFBUSINESSPLACE_GENERATEPAYMENTBRIVA: string = `${WebAddressConstant.STRING_URL_PRODUCT_API}${WebAddressConstant.STRING_PATH_CONTROLLER_PRODUCTDAMAGEOFBUSINESSPLACE}/generatePaymentBRIVA`;
	static STRING_URL_PRODUCTDAMAGEOFBUSINESSPLACE_GENERATEPAYMENTBNI: string = `${WebAddressConstant.STRING_URL_PRODUCT_API}${WebAddressConstant.STRING_PATH_CONTROLLER_PRODUCTDAMAGEOFBUSINESSPLACE}/generatePaymentBankTransferBNIWithMidtrans`;
	static STRING_URL_PRODUCTDAMAGEOFBUSINESSPLACE_GENERATEPAYMENTBCA: string = `${WebAddressConstant.STRING_URL_PRODUCT_API}${WebAddressConstant.STRING_PATH_CONTROLLER_PRODUCTDAMAGEOFBUSINESSPLACE}/generatePaymentBankTransferBCAWithMidtrans`;
	static STRING_URL_PRODUCTDAMAGEOFBUSINESSPLACE_GENERATEPAYMENTMANDIRI: string = `${WebAddressConstant.STRING_URL_PRODUCT_API}${WebAddressConstant.STRING_PATH_CONTROLLER_PRODUCTDAMAGEOFBUSINESSPLACE}/generatePaymentBankTransferMandiriWithMidtrans`;
	static STRING_URL_PRODUCTDAMAGEOFBUSINESSPLACE_GENERATEPAYMENTPERMATA: string = `${WebAddressConstant.STRING_URL_PRODUCT_API}${WebAddressConstant.STRING_PATH_CONTROLLER_PRODUCTDAMAGEOFBUSINESSPLACE}/generatePaymentBankTransferPermataWithMidtrans`;
	static STRING_URL_PRODUCTDAMAGEOFBUSINESSPLACE_GENERATEPAYMENTQRIS: string = `${WebAddressConstant.STRING_URL_PRODUCT_API}${WebAddressConstant.STRING_PATH_CONTROLLER_PRODUCTDAMAGEOFBUSINESSPLACE}/generatePaymentQRISWithMidtrans`;
	static STRING_URL_PRODUCTDAMAGEOFBUSINESSPLACE_GENERATEPAYMENTINDOMARET: string = `${WebAddressConstant.STRING_URL_PRODUCT_API}${WebAddressConstant.STRING_PATH_CONTROLLER_PRODUCTDAMAGEOFBUSINESSPLACE}/generatePaymentRetailStoreIndomaretWithMidtrans`;
	static STRING_URL_PRODUCTDAMAGEOFBUSINESSPLACE_GENERATEPAYMENTALFAMART: string = `${WebAddressConstant.STRING_URL_PRODUCT_API}${WebAddressConstant.STRING_PATH_CONTROLLER_PRODUCTDAMAGEOFBUSINESSPLACE}/generatePaymentRetailStoreAlfaGroupWithMidtrans`;
	static STRING_URL_PRODUCTDAMAGEOFBUSINESSPLACE_GENERATEPAYMENTSHOPEEPAY: string = `${WebAddressConstant.STRING_URL_PRODUCT_API}${WebAddressConstant.STRING_PATH_CONTROLLER_PRODUCTDAMAGEOFBUSINESSPLACE}/generatePaymentDeeplinkShopeePayWithMidtrans`;
	static STRING_URL_PRODUCTDAMAGEOFBUSINESSPLACE_GENERATEPAYMENTCREDITCARD: string = `${WebAddressConstant.STRING_URL_PRODUCT_API}${WebAddressConstant.STRING_PATH_CONTROLLER_PRODUCTDAMAGEOFBUSINESSPLACE}/generatePaymentCreditCardWithMidtrans`;

	//#endregion


	//#region PRODUCT ASMIK MY HOUSE CONTROLLER

	static STRING_PATH_CONTROLLER_PRODUCTMYHOUSE: string = "/ProductMyHouse";

	static STRING_URL_PRODUCTMYHOUSE_SELECTCALCULATEPREMIUM: string = `${WebAddressConstant.STRING_URL_PRODUCT_API}${WebAddressConstant.STRING_PATH_CONTROLLER_PRODUCTMYHOUSE}/calculatePremium`;
	static STRING_URL_PRODUCTMYHOUSE_SENDSPPABYEMAILFORCUSTOMER: string = `${WebAddressConstant.STRING_URL_PRODUCT_API}${WebAddressConstant.STRING_PATH_CONTROLLER_PRODUCTMYHOUSE}/sendSPPAByEmailForCustomer`;
	static STRING_URL_PRODUCTMYHOUSE_CALCULATEPREMIUMFORCUSTOMER: string = `${WebAddressConstant.STRING_URL_PRODUCT_API}${WebAddressConstant.STRING_PATH_CONTROLLER_PRODUCTMYHOUSE}/calculatePremiumForCustomer`;
	static STRING_URL_PRODUCTMYHOUSE_INSERTPRODUCTFORCUSTOMER: string = `${WebAddressConstant.STRING_URL_PRODUCT_API}${WebAddressConstant.STRING_PATH_CONTROLLER_PRODUCTMYHOUSE}/insertProductForCustomer`;
	static STRING_URL_PRODUCTMYHOUSE_UPLOADPRODUCTFORCUSTOMER: string = `${WebAddressConstant.STRING_URL_PRODUCT_API}${WebAddressConstant.STRING_PATH_CONTROLLER_PRODUCTMYHOUSE}/uploadProductForCustomer`;
	static STRING_URL_PRODUCTMYHOUSE_UPDATEINSUREDFORWEBSITECUSTOMER: string = `${WebAddressConstant.STRING_URL_PRODUCT_API}${WebAddressConstant.STRING_PATH_CONTROLLER_PRODUCTMYHOUSE}/updateInsuredForWebsiteCustomer`;
	static STRING_URL_PRODUCTMYHOUSE_UPDATEBENEFICIARIESFORWEBSITECUSTOMER: string = `${WebAddressConstant.STRING_URL_PRODUCT_API}${WebAddressConstant.STRING_PATH_CONTROLLER_PRODUCTMYHOUSE}/updateBeneficiariesForWebsiteCustomer`;
	static STRING_URL_PRODUCTMYHOUSE_UPLOADPRODUCTFORWEBSITECUSTOMER: string = `${WebAddressConstant.STRING_URL_PRODUCT_API}${WebAddressConstant.STRING_PATH_CONTROLLER_PRODUCTMYHOUSE}/uploadProductForWebsiteCustomer`;
	static STRING_URL_PRODUCTMYHOUSE_SAVEPRODUCTFORWEBSITECUSTOMER: string = `${WebAddressConstant.STRING_URL_PRODUCT_API}${WebAddressConstant.STRING_PATH_CONTROLLER_PRODUCTMYHOUSE}/saveProductForWebsiteCustomer`;
	static STRING_URL_PRODUCTMYHOUSE_SELECTPRODUCTBYTOKENFORCUSTOMER: string = `${WebAddressConstant.STRING_URL_PRODUCT_API}${WebAddressConstant.STRING_PATH_CONTROLLER_PRODUCTMYHOUSE}/selectProductByTokenForCustomer`;
	static STRING_URL_PRODUCTMYHOUSE_CONFIRMPAYMENT: string = `${WebAddressConstant.STRING_URL_PRODUCT_API}${WebAddressConstant.STRING_PATH_CONTROLLER_PRODUCTMYHOUSE}/confirmPayment`;
	static STRING_URL_PRODUCTMYHOUSE_SHAREPAYMENTFORCUSTOMER: string = `${WebAddressConstant.STRING_URL_PRODUCT_API}${WebAddressConstant.STRING_PATH_CONTROLLER_PRODUCTMYHOUSE}/sharePaymentForCustomer`;
	static STRING_URL_PRODUCTMYHOUSE_GENERATEPAYMENTBRIVA: string = `${WebAddressConstant.STRING_URL_PRODUCT_API}${WebAddressConstant.STRING_PATH_CONTROLLER_PRODUCTMYHOUSE}/generatePaymentBRIVA`;
	static STRING_URL_PRODUCTMYHOUSE_GENERATEPAYMENTBNI: string = `${WebAddressConstant.STRING_URL_PRODUCT_API}${WebAddressConstant.STRING_PATH_CONTROLLER_PRODUCTMYHOUSE}/generatePaymentBankTransferBNIWithMidtrans`;
	static STRING_URL_PRODUCTMYHOUSE_GENERATEPAYMENTBCA: string = `${WebAddressConstant.STRING_URL_PRODUCT_API}${WebAddressConstant.STRING_PATH_CONTROLLER_PRODUCTMYHOUSE}/generatePaymentBankTransferBCAWithMidtrans`;
	static STRING_URL_PRODUCTMYHOUSE_GENERATEPAYMENTMANDIRI: string = `${WebAddressConstant.STRING_URL_PRODUCT_API}${WebAddressConstant.STRING_PATH_CONTROLLER_PRODUCTMYHOUSE}/generatePaymentBankTransferMandiriWithMidtrans`;
	static STRING_URL_PRODUCTMYHOUSE_GENERATEPAYMENTPERMATA: string = `${WebAddressConstant.STRING_URL_PRODUCT_API}${WebAddressConstant.STRING_PATH_CONTROLLER_PRODUCTMYHOUSE}/generatePaymentBankTransferPermataWithMidtrans`;
	static STRING_URL_PRODUCTMYHOUSE_GENERATEPAYMENTQRIS: string = `${WebAddressConstant.STRING_URL_PRODUCT_API}${WebAddressConstant.STRING_PATH_CONTROLLER_PRODUCTMYHOUSE}/generatePaymentQRISWithMidtrans`;
	static STRING_URL_PRODUCTMYHOUSE_GENERATEPAYMENTINDOMARET: string = `${WebAddressConstant.STRING_URL_PRODUCT_API}${WebAddressConstant.STRING_PATH_CONTROLLER_PRODUCTMYHOUSE}/generatePaymentRetailStoreIndomaretWithMidtrans`;
	static STRING_URL_PRODUCTMYHOUSE_GENERATEPAYMENTALFAMART: string = `${WebAddressConstant.STRING_URL_PRODUCT_API}${WebAddressConstant.STRING_PATH_CONTROLLER_PRODUCTMYHOUSE}/generatePaymentRetailStoreAlfaGroupWithMidtrans`;
	static STRING_URL_PRODUCTMYHOUSE_GENERATEPAYMENTSHOPEEPAY: string = `${WebAddressConstant.STRING_URL_PRODUCT_API}${WebAddressConstant.STRING_PATH_CONTROLLER_PRODUCTMYHOUSE}/generatePaymentDeeplinkShopeePayWithMidtrans`;
	static STRING_URL_PRODUCTMYHOUSE_GENERATEPAYMENTCREDITCARD: string = `${WebAddressConstant.STRING_URL_PRODUCT_API}${WebAddressConstant.STRING_PATH_CONTROLLER_PRODUCTMYHOUSE}/generatePaymentCreditCardWithMidtrans`;

	//#endregion


	//#region PRODUCT ASMIK MY MOTORCYCLE CONTROLLER

	static STRING_PATH_CONTROLLER_PRODUCTMYMOTORCYCLE: string = "/ProductMyMotorcycle";

	static STRING_URL_PRODUCTMYMOTORCYCLE_SELECTCALCULATEPREMIUM: string = `${WebAddressConstant.STRING_URL_PRODUCT_API}${WebAddressConstant.STRING_PATH_CONTROLLER_PRODUCTMYMOTORCYCLE}/calculatePremium`;
	static STRING_URL_PRODUCTMYMOTORCYCLE_SENDSPPABYEMAILFORCUSTOMER: string = `${WebAddressConstant.STRING_URL_PRODUCT_API}${WebAddressConstant.STRING_PATH_CONTROLLER_PRODUCTMYMOTORCYCLE}/sendSPPAByEmailForCustomer`;
	static STRING_URL_PRODUCTMYMOTORCYCLE_CALCULATEPREMIUMFORCUSTOMER: string = `${WebAddressConstant.STRING_URL_PRODUCT_API}${WebAddressConstant.STRING_PATH_CONTROLLER_PRODUCTMYMOTORCYCLE}/calculatePremiumForCustomer`;
	static STRING_URL_PRODUCTMYMOTORCYCLE_INSERTPRODUCTFORCUSTOMER: string = `${WebAddressConstant.STRING_URL_PRODUCT_API}${WebAddressConstant.STRING_PATH_CONTROLLER_PRODUCTMYMOTORCYCLE}/insertProductForCustomer`;
	static STRING_URL_PRODUCTMYMOTORCYCLE_UPLOADPRODUCTFORCUSTOMER: string = `${WebAddressConstant.STRING_URL_PRODUCT_API}${WebAddressConstant.STRING_PATH_CONTROLLER_PRODUCTMYMOTORCYCLE}/uploadProductForCustomer`;
	static STRING_URL_PRODUCTMYMOTORCYCLE_UPDATEINSUREDFORWEBSITECUSTOMER: string = `${WebAddressConstant.STRING_URL_PRODUCT_API}${WebAddressConstant.STRING_PATH_CONTROLLER_PRODUCTMYMOTORCYCLE}/updateInsuredForWebsiteCustomer`;
	static STRING_URL_PRODUCTMYMOTORCYCLE_UPDATEBENEFICIARIESFORWEBSITECUSTOMER: string = `${WebAddressConstant.STRING_URL_PRODUCT_API}${WebAddressConstant.STRING_PATH_CONTROLLER_PRODUCTMYMOTORCYCLE}/updateBeneficiariesForWebsiteCustomer`;
	static STRING_URL_PRODUCTMYMOTORCYCLE_UPLOADPRODUCTFORWEBSITECUSTOMER: string = `${WebAddressConstant.STRING_URL_PRODUCT_API}${WebAddressConstant.STRING_PATH_CONTROLLER_PRODUCTMYMOTORCYCLE}/uploadProductForWebsiteCustomer`;
	static STRING_URL_PRODUCTMYMOTORCYCLE_SAVEPRODUCTFORWEBSITECUSTOMER: string = `${WebAddressConstant.STRING_URL_PRODUCT_API}${WebAddressConstant.STRING_PATH_CONTROLLER_PRODUCTMYMOTORCYCLE}/saveProductForWebsiteCustomer`;
	static STRING_URL_PRODUCTMYMOTORCYCLE_SELECTPRODUCTBYTOKENFORCUSTOMER: string = `${WebAddressConstant.STRING_URL_PRODUCT_API}${WebAddressConstant.STRING_PATH_CONTROLLER_PRODUCTMYMOTORCYCLE}/selectProductByTokenForCustomer`;
	static STRING_URL_PRODUCTMYMOTORCYCLE_CONFIRMPAYMENT: string = `${WebAddressConstant.STRING_URL_PRODUCT_API}${WebAddressConstant.STRING_PATH_CONTROLLER_PRODUCTMYMOTORCYCLE}/confirmPayment`;
	static STRING_URL_PRODUCTMYMOTORCYCLE_SHAREPAYMENTFORCUSTOMER: string = `${WebAddressConstant.STRING_URL_PRODUCT_API}${WebAddressConstant.STRING_PATH_CONTROLLER_PRODUCTMYMOTORCYCLE}/sharePaymentForCustomer`;
	static STRING_URL_PRODUCTMYMOTORCYCLE_GENERATEPAYMENTBRIVA: string = `${WebAddressConstant.STRING_URL_PRODUCT_API}${WebAddressConstant.STRING_PATH_CONTROLLER_PRODUCTMYMOTORCYCLE}/generatePaymentBRIVA`;
	static STRING_URL_PRODUCTMYMOTORCYCLE_GENERATEPAYMENTBNI: string = `${WebAddressConstant.STRING_URL_PRODUCT_API}${WebAddressConstant.STRING_PATH_CONTROLLER_PRODUCTMYMOTORCYCLE}/generatePaymentBankTransferBNIWithMidtrans`;
	static STRING_URL_PRODUCTMYMOTORCYCLE_GENERATEPAYMENTBCA: string = `${WebAddressConstant.STRING_URL_PRODUCT_API}${WebAddressConstant.STRING_PATH_CONTROLLER_PRODUCTMYMOTORCYCLE}/generatePaymentBankTransferBCAWithMidtrans`;
	static STRING_URL_PRODUCTMYMOTORCYCLE_GENERATEPAYMENTMANDIRI: string = `${WebAddressConstant.STRING_URL_PRODUCT_API}${WebAddressConstant.STRING_PATH_CONTROLLER_PRODUCTMYMOTORCYCLE}/generatePaymentBankTransferMandiriWithMidtrans`;
	static STRING_URL_PRODUCTMYMOTORCYCLE_GENERATEPAYMENTPERMATA: string = `${WebAddressConstant.STRING_URL_PRODUCT_API}${WebAddressConstant.STRING_PATH_CONTROLLER_PRODUCTMYMOTORCYCLE}/generatePaymentBankTransferPermataWithMidtrans`;
	static STRING_URL_PRODUCTMYMOTORCYCLE_GENERATEPAYMENTQRIS: string = `${WebAddressConstant.STRING_URL_PRODUCT_API}${WebAddressConstant.STRING_PATH_CONTROLLER_PRODUCTMYMOTORCYCLE}/generatePaymentQRISWithMidtrans`;
	static STRING_URL_PRODUCTMYMOTORCYCLE_GENERATEPAYMENTINDOMARET: string = `${WebAddressConstant.STRING_URL_PRODUCT_API}${WebAddressConstant.STRING_PATH_CONTROLLER_PRODUCTMYMOTORCYCLE}/generatePaymentRetailStoreIndomaretWithMidtrans`;
	static STRING_URL_PRODUCTMYMOTORCYCLE_GENERATEPAYMENTALFAMART: string = `${WebAddressConstant.STRING_URL_PRODUCT_API}${WebAddressConstant.STRING_PATH_CONTROLLER_PRODUCTMYMOTORCYCLE}/generatePaymentRetailStoreAlfaGroupWithMidtrans`;
	static STRING_URL_PRODUCTMYMOTORCYCLE_GENERATEPAYMENTSHOPEEPAY: string = `${WebAddressConstant.STRING_URL_PRODUCT_API}${WebAddressConstant.STRING_PATH_CONTROLLER_PRODUCTMYMOTORCYCLE}/generatePaymentDeeplinkShopeePayWithMidtrans`;
	static STRING_URL_PRODUCTMYMOTORCYCLE_GENERATEPAYMENTCREDITCARD: string = `${WebAddressConstant.STRING_URL_PRODUCT_API}${WebAddressConstant.STRING_PATH_CONTROLLER_PRODUCTMYMOTORCYCLE}/generatePaymentCreditCardWithMidtrans`;

	//#endregion


	//#region PRODUCT PERSONAL ACCIDENT SYARIAH CONTROLLER

	static STRING_PATH_CONTROLLER_PRODUCTPERSONALACCIDENTSYARIAH: string = "/ProductPersonalAccidentSyariah";

	static STRING_URL_PRODUCT_SELECTCALCULATEPREMIUMPERSONALACCIDENTSYARIAHFOROPERATIONANDSUPPORT: string = `${WebAddressConstant.STRING_URL_PRODUCT_API}${WebAddressConstant.STRING_PATH_CONTROLLER_PRODUCTPERSONALACCIDENTSYARIAH}/calculatePremiumForOperationAndSupport`;
	static STRING_URL_PRODUCT_SENDCALCULATEPREMIUMPERSONALACCIDENTSYARIAHBYEMAILFOROPERATIONANDSUPPORT: string = `${WebAddressConstant.STRING_URL_PRODUCT_API}${WebAddressConstant.STRING_PATH_CONTROLLER_PRODUCTPERSONALACCIDENTSYARIAH}/sendCalculatePremiumByEmailForOperationAndSupport`;

	//#endregion


	//#region POLICY GENERAL CONTROLLER

	static STRING_PATH_CONTROLLER_POLICYGENERAL: string = "/PolicyGeneral";

	static STRING_URL_POLICYGENERAL_SELECTPOLICYGENERALBYPOLICYNUMBER: string = `${WebAddressConstant.STRING_URL_POLICY_API}${WebAddressConstant.STRING_PATH_CONTROLLER_POLICYGENERAL}/selectPolicyGeneralByPolicyNumber`;
	static STRING_URL_POLICYGENERAL_SELECTPOLICYGENERALBYATTRIBUTESFORCUSTOMER: string = `${WebAddressConstant.STRING_URL_POLICY_API}${WebAddressConstant.STRING_PATH_CONTROLLER_POLICYGENERAL}/selectPolicyGeneralByAttributesForCustomer`;
	static STRING_URL_POLICYGENERAL_SELECTPOLICYGENERALBYLICENSEPLATE: string = `${WebAddressConstant.STRING_URL_POLICY_API}${WebAddressConstant.STRING_PATH_CONTROLLER_POLICYGENERAL}/selectPolicyGeneralByLicensePlate`;
	static STRING_URL_POLICYGENERAL_SELECTPOLICYGENERALBYPOLICYHOLDERATTRIBUTES: string = `${WebAddressConstant.STRING_URL_POLICY_API}${WebAddressConstant.STRING_PATH_CONTROLLER_POLICYGENERAL}/selectPolicyGeneralByPolicyholderAttributes`;
	static STRING_URL_POLICYGENERAL_SELECTPOLICYGENERALBYPROFILEID: string = `${WebAddressConstant.STRING_URL_POLICY_API}${WebAddressConstant.STRING_PATH_CONTROLLER_POLICYGENERAL}/selectPolicyGeneralByProfileID`;
	static STRING_URL_POLICYGENERAL_SELECTPOLICYGENERALBYATTRIBUTES: string = `${WebAddressConstant.STRING_URL_POLICY_API}${WebAddressConstant.STRING_PATH_CONTROLLER_POLICYGENERAL}/selectPolicyGeneralByAttributes`;
	static STRING_URL_POLICYGENERAL_SELECTPOLICYGENERALBYACCEPTANCENUMBER: string = `${WebAddressConstant.STRING_URL_POLICY_API}${WebAddressConstant.STRING_PATH_CONTROLLER_POLICYGENERAL}/selectPolicyGeneralByAcceptanceNumber`;
	static STRING_URL_POLICYGENERAL_SELECTPOLICYGENERALACTIVEBYATTRIBUTESFORCUSTOMER: string = `${WebAddressConstant.STRING_URL_POLICY_API}${WebAddressConstant.STRING_PATH_CONTROLLER_POLICYGENERAL}/selectPolicyGeneralActiveByAttributesForCustomer`;
	static STRING_URL_POLICYGENERAL_SELECTPOLICYGENERALINACTIVEBYATTRIBUTESFORCUSTOMER: string = `${WebAddressConstant.STRING_URL_POLICY_API}${WebAddressConstant.STRING_PATH_CONTROLLER_POLICYGENERAL}/selectPolicyGeneralInactiveByAttributesForCustomer`;
	static STRING_URL_POLICYGENERAL_SELECTPOLICYGENERALEXPIRINGBYATTRIBUTESFORCUSTOMER: string = `${WebAddressConstant.STRING_URL_POLICY_API}${WebAddressConstant.STRING_PATH_CONTROLLER_POLICYGENERAL}/selectPolicyGeneralExpiringByAttributesForCustomer`;
	static STRING_URL_POLICYGENERAL_SELECTPOLICYGENERALBYACCEPTANCENUMBERFORCUSTOMER: string = `${WebAddressConstant.STRING_URL_POLICY_API}${WebAddressConstant.STRING_PATH_CONTROLLER_POLICYGENERAL}/selectPolicyGeneralByAcceptanceNumberForCustomer`;
	static STRING_URL_POLICYGENERAL_SELECTPOLICYGENERALBYTOKENFORCUSTOMER: string = `${WebAddressConstant.STRING_URL_POLICY_API}${WebAddressConstant.STRING_PATH_CONTROLLER_POLICYGENERAL}/selectPolicyGeneralByTokenForCustomer`;

	//#endregion


	//#region CUSTOMER CONTROLLER

	static STRING_PATH_CONTROLLER_CUSTOMER: string = "/Customer";

	static STRING_URL_CUSTOMER_SELECTCUSTOMERBYPROFILEID: string = `${WebAddressConstant.STRING_URL_POLICY_API}${WebAddressConstant.STRING_PATH_CONTROLLER_CUSTOMER}/selectCustomerByProfileID`;
	static STRING_URL_CUSTOMER_SELECTCUSTOMERBYPOLICYNUMBER: string = `${WebAddressConstant.STRING_URL_POLICY_API}${WebAddressConstant.STRING_PATH_CONTROLLER_CUSTOMER}/selectCustomerByPolicyNumber`;
	static STRING_URL_CUSTOMER_SELECTCUSTOMERBYLICENSEPLATE: string = `${WebAddressConstant.STRING_URL_POLICY_API}${WebAddressConstant.STRING_PATH_CONTROLLER_CUSTOMER}/selectCustomerByLicensePlate`;
	static STRING_URL_CUSTOMER_SELECTCUSTOMERBYATTRIBUTES: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_CUSTOMER}/selectCustomerByAttributes`;
	static STRING_URL_CUSTOMER_SELECTCUSTOMERTICKETBYEMAIL: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_CUSTOMER}/selectCustomerTicketByEmail`;
	static STRING_URL_CUSTOMER_VERIFYCUSTOMERTICKETBYEMAIL: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_CUSTOMER}/verifyCustomerTicketByEmail`;
	static STRING_URL_CUSTOMER_SELECTCUSTOMERBYEMAIL: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_CUSTOMER}/selectCustomerByEmail`;
	static STRING_URL_CUSTOMER_REGISTERCUSTOMERBYEMAIL: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_CUSTOMER}/registerCustomerByEmail`;
	static STRING_URL_CUSTOMER_VERIFYCUSTOMERBYEMAIL: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_CUSTOMER}/verifyCustomerByEmail`;
	static STRING_URL_CUSTOMER_SIGNOUT: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_CUSTOMER}/signOut`;

	//#endregion


	//#region CLAIM CONTROLLER

	static STRING_PATH_CONTROLLER_CLAIM: string = "/Claim";

	static STRING_URL_CLAIM_SELECTCLAIMBYPROFILEID: string = `${WebAddressConstant.STRING_URL_POLICY_API}${WebAddressConstant.STRING_PATH_CONTROLLER_CLAIM}/selectClaimByProfileID`;

	static STRING_URL_CLAIM_SELECTCLAIMBYCLAIMNUMBER: string = `${WebAddressConstant.STRING_URL_POLICY_API}${WebAddressConstant.STRING_PATH_CONTROLLER_CLAIM}/selectClaimByClaimNumber`;


	//#endregion


	//#region CLAIM CONTROLLER

	static STRING_PATH_CONTROLLER_PAYMENT: string = "/Payment";

	static STRING_URL_CLAIM_SELECTPAYMENTBYPROFILEID: string = `${WebAddressConstant.STRING_URL_POLICY_API}${WebAddressConstant.STRING_PATH_CONTROLLER_PAYMENT}/selectPaymentByProfileID`;

	static STRING_URL_CLAIM_SELECTPAYMENTDETAILBYANO: string = `${WebAddressConstant.STRING_URL_POLICY_API}${WebAddressConstant.STRING_PATH_CONTROLLER_PAYMENT}/selectPaymentDetailByANO`;


	//#endregion



	//#region CLAIM PROPOSAL CONTROLLER

	static STRING_PATH_CONTROLLER_CLAIM_PROPOSAL: string = "/ClaimProposal";

	static STRING_URL_CLAIM_PROPOSAL_SELECTCLAIMPROPOSALVERIFIEDBYATTRIBUTES: string = `${WebAddressConstant.STRING_URL_CLAIM_API}${WebAddressConstant.STRING_PATH_CONTROLLER_CLAIM_PROPOSAL}/selectClaimProposalVerifiedByAttributes`;
	static STRING_URL_CLAIM_PROPOSAL_SELECTCLAIMPROPOSALNOTVERIFIEDBYATTRIBUTES: string = `${WebAddressConstant.STRING_URL_CLAIM_API}${WebAddressConstant.STRING_PATH_CONTROLLER_CLAIM_PROPOSAL}/selectClaimProposalNotVerifiedByAttributes`;
	static STRING_URL_CLAIM_PROPOSAL_INSERTCLAIMPROPOSAL: string = `${WebAddressConstant.STRING_URL_CLAIM_API}${WebAddressConstant.STRING_PATH_CONTROLLER_CLAIM_PROPOSAL}/insertClaimProposal`;
	static STRING_URL_CLAIM_PROPOSAL_INSERTCLAIMPROPOSALFORCUSTOMER: string = `${WebAddressConstant.STRING_URL_CLAIM_API}${WebAddressConstant.STRING_PATH_CONTROLLER_CLAIM_PROPOSAL}/insertClaimProposalForCustomer`;
	static STRING_URL_CLAIM_PROPOSAL_INSERTCLAIMPROPOSALNONMOTORVEHICLE: string = `${WebAddressConstant.STRING_URL_CLAIM_API}${WebAddressConstant.STRING_PATH_CONTROLLER_CLAIM_PROPOSAL}/insertClaimProposalNonMotorVehicle`;
	static STRING_URL_CLAIM_PROPOSAL_INSERTCLAIMPROPOSALNONMOTORVEHICLEFORCUSTOMER: string = `${WebAddressConstant.STRING_URL_CLAIM_API}${WebAddressConstant.STRING_PATH_CONTROLLER_CLAIM_PROPOSAL}/insertClaimProposalNonMotorVehicleForCustomer`;
	static STRING_URL_CLAIM_PROPOSAL_UPLOADCLAIMPROPOSAL: string = `${WebAddressConstant.STRING_URL_CLAIM_API}${WebAddressConstant.STRING_PATH_CONTROLLER_CLAIM_PROPOSAL}/uploadClaimProposal`;
	static STRING_URL_CLAIM_PROPOSAL_UPLOADCLAIMPROPOSALFORCUSTOMER: string = `${WebAddressConstant.STRING_URL_CLAIM_API}${WebAddressConstant.STRING_PATH_CONTROLLER_CLAIM_PROPOSAL}/uploadClaimProposalForCustomer`;
	static STRING_URL_CLAIM_PROPOSAL_UPLOADCLAIMPROPOSALFORCUSTOMERPUBLIC: string = `${WebAddressConstant.STRING_URL_CLAIM_API}${WebAddressConstant.STRING_PATH_CONTROLLER_CLAIM_PROPOSAL}/uploadClaimProposalForCustomerPublic`;
	static STRING_URL_CLAIM_PROPOSAL_UPLOADCLAIMPROPOSALSUPPORTINGGALLERY: string = `${WebAddressConstant.STRING_URL_CLAIM_API}${WebAddressConstant.STRING_PATH_CONTROLLER_CLAIM_PROPOSAL}/uploadClaimProposalSupportingGallery`;
	static STRING_URL_CLAIM_PROPOSAL_SELECTCLAIMPROPOSALBYTOKEN: string = `${WebAddressConstant.STRING_URL_CLAIM_API}${WebAddressConstant.STRING_PATH_CONTROLLER_CLAIM_PROPOSAL}/selectClaimProposalByToken`;
	static STRING_URL_CLAIM_PROPOSAL_SELECTCLAIMBYATTRIBUTES: string = `${WebAddressConstant.STRING_URL_CLAIM_API}${WebAddressConstant.STRING_PATH_CONTROLLER_CLAIM_PROPOSAL}/selectClaimByAttributes`;
	static STRING_URL_CLAIM_PROPOSAL_APPROVECLAIMPROPOSALBYBRANCHWITHTOKEN: string = `${WebAddressConstant.STRING_URL_CLAIM_API}${WebAddressConstant.STRING_PATH_CONTROLLER_CLAIM_PROPOSAL}/approveClaimProposalByBranchWithToken`;
	static STRING_URL_CLAIM_PROPOSAL_APPROVECLAIMPROPOSALBYHEADOFFICEWITHTOKEN: string = `${WebAddressConstant.STRING_URL_CLAIM_API}${WebAddressConstant.STRING_PATH_CONTROLLER_CLAIM_PROPOSAL}/approveClaimProposalByHeadOfficeWithToken`;
	static STRING_URL_CLAIM_PROPOSAL_REJECTCLAIMPROPOSALBYBRANCHWITHTOKEN: string = `${WebAddressConstant.STRING_URL_CLAIM_API}${WebAddressConstant.STRING_PATH_CONTROLLER_CLAIM_PROPOSAL}/rejectClaimProposalByBranchWithToken`;
	static STRING_URL_CLAIM_PROPOSAL_REJECTCLAIMPROPOSALBYHEADOFFICEWITHTOKEN: string = `${WebAddressConstant.STRING_URL_CLAIM_API}${WebAddressConstant.STRING_PATH_CONTROLLER_CLAIM_PROPOSAL}/rejectClaimProposalByHeadOfficeWithToken`;
	static STRING_URL_CLAIM_PROPOSAL_SELECTCLAIMPROPOSALSUBMITTEDBYATTRIBUTES: string = `${WebAddressConstant.STRING_URL_CLAIM_API}${WebAddressConstant.STRING_PATH_CONTROLLER_CLAIM_PROPOSAL}/selectClaimProposalSubmittedByAttributes`;
	static STRING_URL_CLAIM_PROPOSAL_SELECTCLAIMPROPOSALCONFIRMEDBYATTRIBUTES: string = `${WebAddressConstant.STRING_URL_CLAIM_API}${WebAddressConstant.STRING_PATH_CONTROLLER_CLAIM_PROPOSAL}/selectClaimProposalConfirmedByAttributes`;
	static STRING_URL_CLAIM_PROPOSAL_SELECTCLAIMPROPOSALNOTVERIFIEDCONFIRMEDBYATTRIBUTES: string = `${WebAddressConstant.STRING_URL_CLAIM_API}${WebAddressConstant.STRING_PATH_CONTROLLER_CLAIM_PROPOSAL}/selectClaimProposalNotVerifiedConfirmedByAttributes`;
	static STRING_URL_CLAIM_PROPOSAL_SELECTCLAIMPROPOSALAPPROVALBYATTRIBUTES: string = `${WebAddressConstant.STRING_URL_CLAIM_API}${WebAddressConstant.STRING_PATH_CONTROLLER_CLAIM_PROPOSAL}/selectClaimProposalApprovalByAttributes`;
	static STRING_URL_CLAIM_PROPOSAL_SELECTCLAIMPROPOSALAPPROVEDBYATTRIBUTES: string = `${WebAddressConstant.STRING_URL_CLAIM_API}${WebAddressConstant.STRING_PATH_CONTROLLER_CLAIM_PROPOSAL}/selectClaimProposalApprovedByAttributes`;
	static STRING_URL_CLAIM_PROPOSAL_SELECTCLAIMPROPOSALCONFIRMEDBYATTRIBUTESFORHEADOFFICE: string = `${WebAddressConstant.STRING_URL_CLAIM_API}${WebAddressConstant.STRING_PATH_CONTROLLER_CLAIM_PROPOSAL}/selectClaimProposalConfirmedByAttributesForHeadOffice`;
	static STRING_URL_CLAIM_PROPOSAL_SELECTCLAIMPROPOSALFORHEADOFFICE: string = `${WebAddressConstant.STRING_URL_CLAIM_API}${WebAddressConstant.STRING_PATH_CONTROLLER_CLAIM_PROPOSAL}/selectClaimProposalForHeadOffice`;
	static STRING_URL_CLAIM_PROPOSAL_SELECTCLAIMPROPOSALFOROPERATIONANDSUPPORT: string = `${WebAddressConstant.STRING_URL_CLAIM_API}${WebAddressConstant.STRING_PATH_CONTROLLER_CLAIM_PROPOSAL}/selectClaimProposalForOperationAndSupport`;
	static STRING_URL_CLAIM_PROPOSAL_INSERTCLAIMPROPOSALFOROPERATIONANDSUPPORT: string = `${WebAddressConstant.STRING_URL_CLAIM_API}${WebAddressConstant.STRING_PATH_CONTROLLER_CLAIM_PROPOSAL}/insertClaimProposalForOperationAndSupport`;
	static STRING_URL_CLAIM_PROPOSAL_SELECTCLAIMPROPOSALSUBMITTEDBYATTRIBUTESFOROPERATIONANDSUPPORT: string = `${WebAddressConstant.STRING_URL_CLAIM_API}${WebAddressConstant.STRING_PATH_CONTROLLER_CLAIM_PROPOSAL}/selectClaimProposalSubmittedByAttributesForOperationAndSupport`;
	static STRING_URL_CLAIM_PROPOSAL_SELECTCLAIMPROPOSALBYPOLICYNUMBER: string = `${WebAddressConstant.STRING_URL_CLAIM_API}${WebAddressConstant.STRING_PATH_CONTROLLER_CLAIM_PROPOSAL}/selectClaimProposalByPolicyNumber`;



	//#endregion


	//#region RELATIONSHIP DAMAGE TYPE

	static STRING_PATH_CONTROLLER_DAMAGETYPE: string = "/DamageType";
	static STRING_URL_DAMAGETYPE_SELECTDAMAGETYPE: string = `${WebAddressConstant.STRING_URL_CLAIM_API}${WebAddressConstant.STRING_PATH_CONTROLLER_DAMAGETYPE}/selectDamageType`;

	//#endregion


	//#region LICENSE PLATE PREFIX

	static STRING_PATH_CONTROLLER_LICENSEPLATEPREFIX: string = "/LicensePlatePrefix";
	static STRING_URL_LICENSEPLATEPREFIX_SELECTLICENSEPLATEPREFIX: string = `${WebAddressConstant.STRING_URL_PRODUCT_API}${WebAddressConstant.STRING_PATH_CONTROLLER_LICENSEPLATEPREFIX}/selectLicensePlatePrefix`;

	//#endregion


	//#region PARTNERSHIP WORSKSHOP CONTROLLER

	static STRING_PATH_CONTROLLER_PARTNERSHIPWORKSHOP: string = "/PartnershipWorkshop";

	static STRING_URL_PARTNERSHIPWORKSHOP_SELECTPARTNERSHIPWORKSHOP: string = `${WebAddressConstant.STRING_URL_CLAIM_API}${WebAddressConstant.STRING_PATH_CONTROLLER_PARTNERSHIPWORKSHOP}/selectPartnershipWorkshop`;
	static STRING_URL_PARTNERSHIPWORKSHOP_SELECTPARTNERSHIPWORKSHOPPUBLIC: string = `${WebAddressConstant.STRING_URL_CLAIM_API}${WebAddressConstant.STRING_PATH_CONTROLLER_PARTNERSHIPWORKSHOP}/selectPartnershipWorkshopPublic`;
	static STRING_URL_PARTNERSHIPWORKSHOP_SELECTPARTNERSHIPWORKSHOPBYATTRIBUTES: string = `${WebAddressConstant.STRING_URL_CLAIM_API}${WebAddressConstant.STRING_PATH_CONTROLLER_PARTNERSHIPWORKSHOP}/selectPartnershipWorkshopByAttributes`;
	static STRING_URL_PARTNERSHIPWORKSHOP_SELECTPARTNERSHIPWORKSHOPBYATTRIBUTESPUBLIC: string = `${WebAddressConstant.STRING_URL_CLAIM_API}${WebAddressConstant.STRING_PATH_CONTROLLER_PARTNERSHIPWORKSHOP}/selectPartnershipWorkshopByAttributesPublic`;

	//#endregion


	//#region PARTNERSHIP WORSKSHOP CONTROLLER

	static STRING_PATH_CONTROLLER_PARTNERSHIPWORKSHOPCITY: string = "/PartnershipWorkshopCity";

	static STRING_URL_PARTNERSHIPWORKSHOPCITY_SELECTPARTNERSHIPWORKSHOPCITY: string = `${WebAddressConstant.STRING_URL_CLAIM_API}${WebAddressConstant.STRING_PATH_CONTROLLER_PARTNERSHIPWORKSHOPCITY}/selectPartnershipWorkshopCity`;

	//#endregion


	//#region RELATIONSHIP MANAGER CONTROLLER

	static STRING_PATH_CONTROLLER_RELATIONSHIPMANAGER: string = "/RelationshipManager";

	static STRING_URL_RELATIONSHIPMANAGER_SELECTRELATIONSHIPMANAGERBYATTRIBUTESFOROPERATIONANDSUPPORT: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_RELATIONSHIPMANAGER}/selectRelationshipManagerByAttributesForOperationAndSupport`;
	static STRING_URL_RELATIONSHIPMANAGER_SELECTRELATIONSHIPMANAGERBYTOKEN: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_RELATIONSHIPMANAGER}/selectRelationshipManagerByToken`;
	static STRING_URL_RELATIONSHIPMANAGER_SELECTRELATIONSHIPMANAGERBYBRANCH: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_RELATIONSHIPMANAGER}/selectRelationshipManagerByBranch`;


	//#endregion


	//#region DIVISION

	static STRING_PATH_CONTROLLER_DIVISION: string = "/Division";

	static STRING_URL_DIVISION_SELECTDIVISION: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_DIVISION}/selectDivision`;

	//#endregion


	//#region TICKET CONTROLLER

	static STRING_PATH_CONTROLLER_TICKET: string = "/Ticket";

	static STRING_URL_TICKET_SELECTTICKETBYATTRIBUTES: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_TICKET}/selectTicketByAttributes`;
	static STRING_URL_TICKET_SELECTTICKETBYTOKEN: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_TICKET}/selectTicketByToken`;
	static STRING_URL_TICKET_SELECTTICKETPUBLICBYTOKEN: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_TICKET}/selectTicketPublicByToken`;
	static STRING_URL_TICKET_SELECTTICKETTOKENPUBLICBYTICKETNUMBER: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_TICKET}/selectTicketTokenPublicByTicketNumber`;
	static STRING_URL_TICKET_SELECTTICKETINBOXBYATTRIBUTES: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_TICKET}/selectTicketReceivedByAttributes`;
	static STRING_URL_TICKET_SELECTTICKETOUTBOXBYATTRIBUTES: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_TICKET}/selectTicketSentByAttributes`;
	static STRING_URL_TICKET_INSERTTICKET: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_TICKET}/insertTicket`;
	static STRING_URL_TICKET_INSERTTICKETFORDIVISION: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_TICKET}/insertTicketForDivision`;
	static STRING_URL_TICKET_INSERTTICKETFORCUSTOMER: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_TICKET}/insertTicketForCustomer`;
	static STRING_URL_TICKET_INSERTTICKETFORCUSTOMERPUBLIC: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_TICKET}/insertTicketForCustomerPublic`;
	static STRING_URL_TICKET_UPDATETICKETBYTOKEN: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_TICKET}/updateTicketByToken`;

	//#endregion


	//#region TICKET CONVERSATION CONTROLLER

	static STRING_PATH_CONTROLLER_TICKETCONVERSATION: string = "/TicketConversation";

	static STRING_URL_TICKET_INSERTTICKETCONVERSATIONFORDIVISION: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_TICKETCONVERSATION}/insertTicketConversationForDivision`;
	static STRING_URL_TICKET_INSERTTICKETCONVERSATIONFORCUSTOMER: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_TICKETCONVERSATION}/insertTicketConversationForCustomer`;
	static STRING_URL_TICKET_SELECTTICKETCONVERSATIONBYATTRIBUTES: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_TICKETCONVERSATION}/selectTicketConversationByAttributes`;
	static STRING_URL_TICKET_SELECTTICKETCONVERSATIONPUBLICBYATTRIBUTES: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_TICKETCONVERSATION}/selectTicketConversationPublicByAttributes`;
	static STRING_URL_TICKET_UPDATETICKETCONVERSATIONBYTOKEN: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_TICKETCONVERSATION}/updateTicketConversationByToken`;
	static STRING_URL_TICKET_UPLOADCONVERSATION: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_TICKETCONVERSATION}/uploadConversation`;

	//#endregion


	//#region TICKET DOCUMENT CONTROLLER

	static STRING_PATH_CONTROLLER_TICKETDOCUMENT: string = "/TicketDocument";

	static STRING_URL_TICKET_DOWNLOADCONVERSATION: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_TICKETDOCUMENT}/downloadConversationByDocumentToken`;

	//#endregion



	//#region VEHICLE BRAND CONTROLLER

	static STRING_PATH_CONTROLLER_VEHICLEBRAND: string = "/VehicleBrand";

	static STRING_URL_VEHICLEBRAND_SELECTVEHICLEBRAND: string = `${WebAddressConstant.STRING_URL_PRODUCT_API}${WebAddressConstant.STRING_PATH_CONTROLLER_VEHICLEBRAND}/selectVehicleBrand`;
	static STRING_URL_VEHICLEBRAND_SELECTVEHICLEBRANDFORCUSTOMER: string = `${WebAddressConstant.STRING_URL_PRODUCT_API}${WebAddressConstant.STRING_PATH_CONTROLLER_VEHICLEBRAND}/selectVehicleBrandForCustomer`;
	static STRING_URL_VEHICLEBRAND_SELECTVEHICLEBRANDMOTORCYCLEFORCUSTOMER: string = `${WebAddressConstant.STRING_URL_PRODUCT_API}${WebAddressConstant.STRING_PATH_CONTROLLER_VEHICLEBRAND}/selectVehicleBrandMotorcycleForCustomer`;

	//#endregion


	//#region VEHICLE MODEL CONTROLLER

	static STRING_PATH_CONTROLLER_VEHICLEMODEL: string = "/VehicleModel";

	static STRING_URL_VEHICLEBRAND_SELECTVEHICLEMODELBYVEHICLEBRAND: string = `${WebAddressConstant.STRING_URL_PRODUCT_API}${WebAddressConstant.STRING_PATH_CONTROLLER_VEHICLEMODEL}/selectVehicleModelByVehicleBrand`;
	static STRING_URL_VEHICLEBRAND_SELECTVEHICLEMODELBYVEHICLEBRANDFORCUSTOMER: string = `${WebAddressConstant.STRING_URL_PRODUCT_API}${WebAddressConstant.STRING_PATH_CONTROLLER_VEHICLEMODEL}/selectVehicleModelByVehicleBrandForCustomer`;
	static STRING_URL_VEHICLEBRAND_SELECTVEHICLEMODELMOTORCYCLEBYVEHICLEBRANDFORCUSTOMER: string = `${WebAddressConstant.STRING_URL_PRODUCT_API}${WebAddressConstant.STRING_PATH_CONTROLLER_VEHICLEMODEL}/selectVehicleModelMotorcycleByVehicleBrandForCustomer`;

	//#endregion


	//#region VEHICLE LOCATION CONTROLLER

	static STRING_PATH_CONTROLLER_VEHICLELOCATION: string = "/VehicleLocation";

	static STRING_URL_VEHICLEBRAND_SELECTVEHICLELOCATION: string = `${WebAddressConstant.STRING_URL_PRODUCT_API}${WebAddressConstant.STRING_PATH_CONTROLLER_VEHICLELOCATION}/selectVehicleLocation`;
	static STRING_URL_VEHICLEBRAND_SELECTVEHICLELOCATIONFORCUSTOMER: string = `${WebAddressConstant.STRING_URL_PRODUCT_API}${WebAddressConstant.STRING_PATH_CONTROLLER_VEHICLELOCATION}/selectVehicleLocationForCustomer`;

	//#endregion


	//#region VEHICLE COLOR CONTROLLER

	static STRING_PATH_CONTROLLER_VEHICLECOLOR: string = "/VehicleColor";

	static STRING_URL_VEHICLECOLOR_SELECTVEHICLECOLORFORCUSTOMER: string = `${WebAddressConstant.STRING_URL_PRODUCT_API}${WebAddressConstant.STRING_PATH_CONTROLLER_VEHICLECOLOR}/selectVehicleColorForCustomer`;

	//#endregion


	//#region VEHICLE FUNCTION CONTROLLER

	static STRING_PATH_CONTROLLER_VEHICLEFUNCTION: string = "/VehicleFunction";

	static STRING_URL_VEHICLEFUNCTION_SELECTVEHICLEFUNCTION: string = `${WebAddressConstant.STRING_URL_PRODUCT_API}${WebAddressConstant.STRING_PATH_CONTROLLER_VEHICLEFUNCTION}/selectVehicleFunction`;

	//#endregion


	//#region VEHICLE SUB MODEL CONTROLLER

	static STRING_PATH_CONTROLLER_VEHICLESUBMODEL: string = "/VehicleSubModel";

	static STRING_URL_VEHICLESUBMODEL_SELECTVEHICLESUBMODELBYVEHICLEMODELFORCUSTOMER: string = `${WebAddressConstant.STRING_URL_PRODUCT_API}${WebAddressConstant.STRING_PATH_CONTROLLER_VEHICLESUBMODEL}/selectVehicleSubModelByVehicleModelForCustomer`;
	static STRING_URL_VEHICLESUBMODEL_SELECTVEHICLESUBMODELMOTORCYCLEBYVEHICLEMODELFORCUSTOMER: string = `${WebAddressConstant.STRING_URL_PRODUCT_API}${WebAddressConstant.STRING_PATH_CONTROLLER_VEHICLESUBMODEL}/selectVehicleSubModelMotorcycleByVehicleModelForCustomer`;

	//#endregion


	//#region RENEWAL

	static STRING_PATH_CONTROLLER_POLICYRENEWAL: string = "/PolicyRenewal";

	static STRING_URL_POLICYRENEWAL_SELECTRENEWALFIRSTAGING: string = `${WebAddressConstant.STRING_URL_POLICY_API}${WebAddressConstant.STRING_PATH_CONTROLLER_POLICYRENEWAL}/selectRenewalFirstAging`;
	static STRING_URL_POLICYRENEWAL_SELECTRENEWALSECONDAGING: string = `${WebAddressConstant.STRING_URL_POLICY_API}${WebAddressConstant.STRING_PATH_CONTROLLER_POLICYRENEWAL}/selectRenewalSecondAging`;
	static STRING_URL_POLICYRENEWAL_SELECTRENEWALTHIRDAGING: string = `${WebAddressConstant.STRING_URL_POLICY_API}${WebAddressConstant.STRING_PATH_CONTROLLER_POLICYRENEWAL}/selectRenewalThirdAging`;
	static STRING_URL_POLICYRENEWAL_SELECTRENEWALFOURTHAGING: string = `${WebAddressConstant.STRING_URL_POLICY_API}${WebAddressConstant.STRING_PATH_CONTROLLER_POLICYRENEWAL}/selectRenewalFourthAging`;

	static STRING_URL_POLICYRENEWAL_SELECTRENEWALHEADERFIRSTAGING: string = `${WebAddressConstant.STRING_URL_POLICY_API}${WebAddressConstant.STRING_PATH_CONTROLLER_POLICYRENEWAL}/selectRenewalHeaderFirstAging`;
	static STRING_URL_POLICYRENEWAL_SELECTRENEWALHEADERSECONDAGING: string = `${WebAddressConstant.STRING_URL_POLICY_API}${WebAddressConstant.STRING_PATH_CONTROLLER_POLICYRENEWAL}/selectRenewalHeaderSecondAging`;
	static STRING_URL_POLICYRENEWAL_SELECTRENEWALHEADERTHIRDAGING: string = `${WebAddressConstant.STRING_URL_POLICY_API}${WebAddressConstant.STRING_PATH_CONTROLLER_POLICYRENEWAL}/selectRenewalHeaderThirdAging`;
	static STRING_URL_POLICYRENEWAL_SELECTRENEWALHEADERFOURTHAGING: string = `${WebAddressConstant.STRING_URL_POLICY_API}${WebAddressConstant.STRING_PATH_CONTROLLER_POLICYRENEWAL}/selectRenewalHeaderFourthAging`;

	//#endregion


	//#endregion


	//#region TOC

	static STRING_PATH_CONTROLLER_TOC: string = "/TOC";

	static STRING_URL_TOC_SELECTTOC: string = `${WebAddressConstant.STRING_URL_POLICY_API}${WebAddressConstant.STRING_PATH_CONTROLLER_TOC}/selectTOC`;

	//#endregion


	//#region BRINS WEBSITE

	//#region BRINS UPDATE

	static STRING_PATH_CONTROLLER_BRINSUPDATE: string = "/BRINSUpdate";
	static STRING_URL_BRINSUPDATE_SELECTBRINSUPDATEBYATTRIBUTES: string = `${WebAddressConstant.STRING_URL_PRODUCT_API}${WebAddressConstant.STRING_PATH_CONTROLLER_BRINSUPDATE}/selectBRINSUpdateByAttributes`;
	static STRING_URL_BRINSUPDATE_SELECTBRINSUPDATEBYTOKEN: string = `${WebAddressConstant.STRING_URL_PRODUCT_API}${WebAddressConstant.STRING_PATH_CONTROLLER_BRINSUPDATE}/selectBRINSUpdateByToken`;

	//#endregion

	//#region AWARD

	static STRING_PATH_CONTROLLER_AWARD: string = "/Award";
	static STRING_URL_AWARD_SELECTAWARDBYATTRIBUTES: string = `${WebAddressConstant.STRING_URL_PRODUCT_API}${WebAddressConstant.STRING_PATH_CONTROLLER_AWARD}/selectAwardByAttributes`;
	static STRING_URL_AWARD_SELECTAWARDBYTOKEN: string = `${WebAddressConstant.STRING_URL_PRODUCT_API}${WebAddressConstant.STRING_PATH_CONTROLLER_AWARD}/selectAwardByToken`;

	//#endregion

	//#region COMMUNITY

	static STRING_PATH_CONTROLLER_COMMUNITY: string = "/Community";
	static STRING_URL_COMMUNITY_SELECTCOMMUNITYBYATTRIBUTES: string = `${WebAddressConstant.STRING_URL_PRODUCT_API}${WebAddressConstant.STRING_PATH_CONTROLLER_COMMUNITY}/selectCommunityByAttributes`;
	static STRING_URL_COMMUNITY_SELECTCOMMUNITYBYTOKEN: string = `${WebAddressConstant.STRING_URL_PRODUCT_API}${WebAddressConstant.STRING_PATH_CONTROLLER_COMMUNITY}/selectCommunityByToken`;

	//#endregion

	//#region DISCOUNT

	static STRING_PATH_CONTROLLER_DISCOUNT: string = "/Discount";
	static STRING_URL_DISCOUNT_VERIFYDISCOUNTCODE: string = `${WebAddressConstant.STRING_URL_PRODUCT_API}${WebAddressConstant.STRING_PATH_CONTROLLER_DISCOUNT}/verifyDiscountCode`;
	static STRING_URL_DISCOUNT_SELECTDISCOUNTBYATTRIBUTES: string = `${WebAddressConstant.STRING_URL_PRODUCT_API}${WebAddressConstant.STRING_PATH_CONTROLLER_DISCOUNT}/selectDiscountByAttributes`;
	static STRING_URL_DISCOUNT_SELECTDISCOUNTACTIVEBYATTRIBUTES: string = `${WebAddressConstant.STRING_URL_PRODUCT_API}${WebAddressConstant.STRING_PATH_CONTROLLER_DISCOUNT}/selectDiscountActiveByAttributes`;
	static STRING_URL_DISCOUNT_SELECTDISCOUNTINACTIVEBYATTRIBUTES: string = `${WebAddressConstant.STRING_URL_PRODUCT_API}${WebAddressConstant.STRING_PATH_CONTROLLER_DISCOUNT}/selectDiscountInactiveByAttributes`;
	static STRING_URL_DISCOUNT_SELECTDISCOUNTBYTOKEN: string = `${WebAddressConstant.STRING_URL_PRODUCT_API}${WebAddressConstant.STRING_PATH_CONTROLLER_DISCOUNT}/selectDiscountByToken`;

	//#endregion

	//#region POSTAL

	static STRING_PATH_CONTROLLER_POSTAL: string = "/Postal";
	static STRING_URL_POSTAL_SELECTLOCATIONBYPOSTALCODE: string = `${WebAddressConstant.STRING_URL_PRODUCT_API}${WebAddressConstant.STRING_PATH_CONTROLLER_POSTAL}/selectLocationByPostalCode`;
	static STRING_URL_POSTAL_SELECTLOCATIONBYPOSTALCODEFORCUSTOMER: string = `${WebAddressConstant.STRING_URL_PRODUCT_API}${WebAddressConstant.STRING_PATH_CONTROLLER_POSTAL}/selectLocationByPostalCodeForCustomer`;

	//#endregion

	//#region FINANCIAL LITERACY

	static STRING_PATH_CONTROLLER_FINANCIALLITERACY: string = "/FinancialLiteracy";
	static STRING_URL_FINANCIALLITERACY_SELECTFINANCIALLITERACYBYATTRIBUTES: string = `${WebAddressConstant.STRING_URL_PRODUCT_API}${WebAddressConstant.STRING_PATH_CONTROLLER_FINANCIALLITERACY}/selectFinancialLiteracyByAttributes`;
	static STRING_URL_FINANCIALLITERACY_SELECTFINANCIALLITERACYBYTOKEN: string = `${WebAddressConstant.STRING_URL_PRODUCT_API}${WebAddressConstant.STRING_PATH_CONTROLLER_FINANCIALLITERACY}/selectFinancialLiteracyByToken`;

	//#endregion

	//#region NEWS FEED

	static STRING_PATH_CONTROLLER_NEWSFEED: string = "/NewsFeed";
	static STRING_URL_NEWSFEED_SELECTNEWSFEEDBYATTRIBUTES: string = `${WebAddressConstant.STRING_URL_PRODUCT_API}${WebAddressConstant.STRING_PATH_CONTROLLER_NEWSFEED}/selectNewsFeedByAttributes`;
	static STRING_URL_NEWSFEED_SELECTNEWSFEEDBYTOKEN: string = `${WebAddressConstant.STRING_URL_PRODUCT_API}${WebAddressConstant.STRING_PATH_CONTROLLER_NEWSFEED}/selectNewsFeedByToken`;

	//#endregion

	//#region TESTIMONY

	static STRING_PATH_CONTROLLER_TESTIMONY: string = "/Testimony";
	static STRING_URL_TESTIMONY_SELECTTESTIMONYBYATTRIBUTES: string = `${WebAddressConstant.STRING_URL_PRODUCT_API}${WebAddressConstant.STRING_PATH_CONTROLLER_TESTIMONY}/selectTestimonyByAttributes`;
	static STRING_URL_TESTIMONY_SELECTTESTIMONYBYTOKEN: string = `${WebAddressConstant.STRING_URL_PRODUCT_API}${WebAddressConstant.STRING_PATH_CONTROLLER_TESTIMONY}/selectTestimonyByToken`;

	//#endregion


	//#region FAQ TYPE

	static STRING_PATH_CONTROLLER_FAQTYPE: string = "/FrequentlyAskedQuestionsQuestionType";
	static STRING_URL_FAQTYPE_SELECTFAQQUESTIONTYPE: string = `${WebAddressConstant.STRING_URL_PRODUCT_API}${WebAddressConstant.STRING_PATH_CONTROLLER_FAQTYPE}/selectFrequentlyAskedQuestionsQuestionType`;
	static STRING_URL_FAQTYPE_SELECTFAQQUESTIONTYPEBYTOKEN: string = `${WebAddressConstant.STRING_URL_PRODUCT_API}${WebAddressConstant.STRING_PATH_CONTROLLER_FAQTYPE}/selectFrequentlyAskedQuestionsQuestionTypeByToken`;

	//#endregion


	//#region FAQ SUBTYPE

	static STRING_PATH_CONTROLLER_FAQSUBTYPE: string = "/FrequentlyAskedQuestionsQuestionSubtype";
	static STRING_URL_FAQTYPE_SELECTFAQQUESTIONSUBTYPEBYTOKEN: string = `${WebAddressConstant.STRING_URL_PRODUCT_API}${WebAddressConstant.STRING_PATH_CONTROLLER_FAQSUBTYPE}/selectFrequentlyAskedQuestionsQuestionSubtypeByToken`;

	//#endregion


	//#region TICKET CONVERSATION CONTROLLER

	static STRING_PATH_CONTROLLER_EMAILSUBSCRIPTION: string = "/EmailSubscription";

	static STRING_URL_EMAILSUBSCRIPTION_INSERTEMAILSUBSCRIPTION: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_EMAILSUBSCRIPTION}/insertEmailSubscription`;
	static STRING_URL_EMAILSUBSCRIPTION_UPDATEEMAILSUBSCRIPTIONBYEMAIL: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_EMAILSUBSCRIPTION}/updateEmailSubscriptionByEmail`;

	//#endregion


	//#region OCCUPATION CONTROLLER

	static STRING_PATH_CONTROLLER_OCCUPATION: string = "/Occupation";

	static STRING_URL_OCCUPATION_SELECTOCCUPATION: string = `${WebAddressConstant.STRING_URL_PRODUCT_API}${WebAddressConstant.STRING_PATH_CONTROLLER_OCCUPATION}/selectOccupation`;

	//#endregion


	//#region RELATIONSHIP CONTROLLER

	static STRING_PATH_CONTROLLER_RELATIONSHIP: string = "/Relationship";

	static STRING_URL_RELATIONSHIP_SELECTRELATIONSHIPFORCUSTOMER: string = `${WebAddressConstant.STRING_URL_PRODUCT_API}${WebAddressConstant.STRING_PATH_CONTROLLER_RELATIONSHIP}/selectRelationshipForCustomer`;


	//#endregion
}

//#endregion